<template>
  <div class="get-token-container">
    <template>
      <div v-show="!userTokenInfo">
      <div class="header">Доступ к аккаунту</div>
      <div class="dialog-box">
        <img src="@/assets/img/icons/message.png" class="icon-message">
        <div class="header__secondary">Тут ничего нет
        </div>
        <div class="body">Создайте новый токен, для интеграции сторонних систем с нашим сервисом по API</div>
      </div>
        <div class="buttons-container">
          <CamsButton
            class="create-token-button"
            priority="primary"
            type="button"
            @click="createToken"
          >
            Создать токен
          </CamsButton>
        </div>
      </div>
      <div v-show="userTokenInfo">
        <div class="header">Доступ к аккаунту</div>
        <div class="account-access">
          <div class="access-info">
            <div class="info-item">
              <div class="item">
                <div class="item-label">Логин</div>
                <div class="item-content">{{ this.userTokenInfo.username }}</div>
              </div>
              <button
                class="copy-token-button"
                type="button"
                @click="copyURL(userTokenInfo.username)"
                icon-type="only"
              >
                <svg class="icon item-icon">
                  <title>Скопировать</title>
                  <use xlink:href="#icon-copy-to-clipboard" />
                </svg>
              </button>
            </div>
            <div class="info-item">
              <div class="item">

                <div class="item-label">Ссылка</div>
                <div class="item-content">{{ this.hostname }}</div>
              </div>
              <button
                class="copy-token-button"
                type="button"
                @click="copyURL(hostname)"
                icon-type="only"
              >
                <svg class="icon item-icon">
                  <title>Скопировать</title>
                  <use xlink:href="#icon-copy-to-clipboard" />
                </svg>
              </button>
            </div>
            <div class="info-item">
              <div class="item">
                <div class="item-label">Токен</div>
                <div class="item-content">{{ this.userTokenInfo.body }}</div>
              </div>
              <button
                class="copy-token-button"
                type="button"
                @click="copyURL(userTokenInfo.body)"
                icon-type="only"
              >
                <svg class="icon item-icon">
                  <title>Скопировать</title>
                  <use xlink:href="#icon-copy-to-clipboard" />
                </svg>
              </button>

            </div>
          </div>
          </div>
        <div class="buttons-container">
          <CamsButton
            priority="danger"
            type="button"
            @click="deleteToken"
          >
            Удалить токен
          </CamsButton>
        </div>
      </div>
    </template>
    <SpinnerLoadingModal v-if="isLoading" />
  </div>
</template>

<script>
import {entityTableMixin} from "@/components/pacs/mixins.js";
import {ACTION_CREATE_USER_TOKEN, ACTION_DELETE_USER_TOKEN, ACTION_LOAD_USER_TOKENS} from "@/store/users/index.js";
/**
 * Меню по созданию и удалению токенов для доступа сторонних апи.
 * на текущий момент доступно только создание и удаление токена
 *
 */
export default {
  mixins: [
    entityTableMixin
  ],
  data() {
    return {
      showForgotPassword: false,
      isLoading: false,
      password: "",
      inputType: "password",
      userTokenInfo: "",
      hostname: ""
    };
  },
  created() {
    this.hostname = location.origin;
  },
  methods: {
    async deleteToken() {
      this.isLoading = true;
      try {
        await this.$store.dispatch(`users/${ACTION_DELETE_USER_TOKEN}`,{ids: [this.userTokenInfo.id]})
      }catch (error){
        //
      }
      this.loadPage()

    },
    async createToken() {
      this.isLoading = true;
      try {
     await this.$store.dispatch(`users/${ACTION_CREATE_USER_TOKEN}`,{})
      }catch (error){
        //
      }
      this.loadPage()
    },
    async loadPage() {
      this.isLoading = true;
      try {
        this.userTokenInfo = await this.$store.dispatch(`users/${ACTION_LOAD_USER_TOKENS}`,{})
      }catch (error){
        //
      }
      this.userTokenInfo = this.userTokenInfo ?? ''
      setTimeout(()=> this.isLoading = false, 500)
      // Логика для создания токена
    },
    async copyURL(token) {
      try {
        await navigator.clipboard.writeText(token);
        this.$notify({
          group: "main",
          text: `Скопировано в буфер`,
          duration: 5000,
          type: "success",
        });
      } catch(error) {
        //
      }
    }
  }
};
</script>

<style lang="scss">
.get-token-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 5px;
}
.dialog-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border-radius: 20px;
  background: #EDF1F7;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.header {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  background: none;
  margin-bottom: 20px;
}
.header__secondary {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}
.icon-message {
  width: 30px;
  height: 30px;
  margin: 10px 0 10px 0;
}

.body {
  font-size: 16px;
  margin-bottom: 20px;
}
.forgot-password{
  width: 250px;
  text-align: center;
}
.account-access {
  background: #EDF1F7;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
}
.access-header {
  font-size: 20px;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.access-info {
  .info-item {
    margin-bottom: 20px;
    display: flex;
    width: 400px;
    justify-content: space-between;
    .item {
      .item-label {
        font-weight: bold;
        margin-bottom: 4px;
      }
      .item-content {
        border-radius: 4px;
        word-break: break-all;
      }
    }
    .item-icon {
      margin: 20px auto 0 10px;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
}
.buttons-container {
  display: flex;
  gap: 10px;
  margin-top: 15px;
  justify-content: center;
  button {
    width: 50%;
  }
}
.copy-token-button {
  background: none;
}
</style>
