<template>
  <div>
    <SmartVSelect
      v-model="employeeIds"
      :multiple="true"
      :settings-remote-search="settingsSelectEmployee"
      class="input-row"
      placeholder="Введите ФИО сотрудника"
    />

    <div class="buttons-group buttons-group--align-right">
      <CamsButton type="button" @click="closeDialog()">
        Отмена
      </CamsButton>
      <CamsButton :disabled="!employeeIds || (employeeIds.length === 0)" priority="primary" type="button" @click="addEmployeesToDeviceAccessGroup()">
        Добавить
      </CamsButton>
    </div>

    <SpinnerLoadingModal v-if="isLoading" size="s" />
  </div>
</template>

<script>
import {ACTION_ADD_EMPLOYEES_TO_DEVICE_ACCESS_GROUP, ACTION_LOAD_EMPLOYEES_FOR_SELECT, EMPLOYEE_STRUCT} from "@/store/pacs/employees/index.js";
import {methodsForDialogMixin} from "@/components/pacs/mixins.js";

/**
 * Компонент диалога добавления сотрудника к группе доступа.
 */
export default {
  mixins: [methodsForDialogMixin],
  props: {
    /**
     * Идентификатор группы доступа.
     */
    deviceAccessGroupId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      employeeIds: null,
      settingsSelectEmployee: {
        action: `pacs/employees/${ACTION_LOAD_EMPLOYEES_FOR_SELECT}`,
        valueField: EMPLOYEE_STRUCT.fields.id.name,
        labelField: EMPLOYEE_STRUCT.fields.title.name,
      },
    };
  },
  methods: {
    /**
     * Отправка запроса на добавление сотрудников в группу.
     */
    async addEmployeesToDeviceAccessGroup() {
      if (!this.employeeIds || (this.employeeIds.length === 0)) {
        return;
      }
      this.isLoading = true;
      try {
        await this.$store.dispatch(`pacs/employees/${ACTION_ADD_EMPLOYEES_TO_DEVICE_ACCESS_GROUP}`, {
          employeeIds: this.employeeIds,
          deviceAccessGroupId: this.deviceAccessGroupId,
        });
        this.$notify({
          group: "main",
          text: "Сотдрудник добавлен в группу",
          duration: 5000,
          type: "default"
        });
        this.closeDialog(true);
      } catch (error) {
        const errorText = Object.values(error?.fields || {}).join("/r/n");
        this.$camsdals.alert(`Ошибка при добавлении сотрудников к группе. ${errorText}`);
      }
      this.isLoading = false;
    },
  },
};
</script>
