<template>
  <div class="cams-container__camera cams-container__camera--tile">
    <div v-if="grabbableMode" class="grabbable">
      <svg class="icon icon-grabbable">
        <use xlink:href="#icon-grabbable" />
      </svg>
    </div>
    <div class="cams-container__camera-address">
      {{ cameraInfo.address }}
    </div>

    <div v-if="cameraInfo.isInactive()"
         class="cams-container__camera-thumb cams-container__camera-thumb--inactive"
         @click="folderMode ? clickOnScreen() : null">
      <div class="cams-container__camera-meta camera-meta">
        <div class="camera-meta__status">
          {{ cameraInfo.inactivityPeriod.reason }}
        </div>
        <div class="camera-meta__todo">
          {{ cameraInfo.inactivityPeriod.todo }}
        </div>
        <div class="camera-meta__number">
          № {{ cameraInfo.number }}
        </div>
      </div>
      <div class="cams-container__camera-fogging" />
    </div>

    <div v-else-if="cameraInfo.isDisabled()"
         class="cams-container__camera-thumb cams-container__camera-thumb--inactive"
         @click="folderMode ? clickOnScreen() : null" >
      <div class="cams-container__camera-meta camera-meta">
        <div class="camera-meta__status">
          {{ "Камера отключена" }}
        </div>
        <div class="camera-meta__todo">
          {{ cameraInfo.recordDisablePeriod.start | localDateTime }}
        </div>
        <div class="camera-meta__number">
          № {{ cameraInfo.number }}
        </div>
      </div>
      <div class="cams-container__camera-fogging" />
    </div>
    <!-- todo у карточки с нерабочей (по хз причине) камерой должен быть класс .cams-container__camera-thumb--disabled-->
    <div
      v-else
      v-observe-visibility="visibilityChanged"
      :class="{'cams-container__camera-thumb--focus': showInfo, 'cams-container__camera-thumb--disabled': isDisabled}"
      class="cams-container__camera-thumb"
      @click="folderMode ? clickOnScreen() : null"
    >
      <template v-if="isVisible && !isDisabled">
        <img
          v-if="cameraInfo.server.screenshot_domain"
          :alt="cameraInfo.title"
          :src="cameraInfo.screenshotUrl(cameraScreenshotSizes.s400, screenshotSign)"
          class="cams-container__video-thumb"
          @error="isDisabled = true"
        >
        <video
          v-else
          :src="cameraInfo.screenshotUrl(cameraScreenshotSizes.s400, screenshotSign)"
          autoplay
          class="cams-container__video-thumb"
          @error="isDisabled = true"
        />
      </template>
      <!-- todo если чето не отработало, то в любой непонятной ситации выводим это (проверка неработоспособности определяется на основе не загруженного скрна) -->
      <template v-else>
        <div class="cams-container__camera-meta cams-container__camera-meta--error camera-meta"   @click="folderMode ? clickOnScreen() : null">
          <img alt="" src="@/assets/img/icons/video-camera.svg">
          <div class="camera-meta__status">
            Камера не обнаружена
          </div>
        </div>
      </template>

      <div class="cams-container__camera-meta camera-meta">
        <div>
          <div class="camera-meta__tariff">
            {{ cameraInfo.tariffName() }}
          </div>
          <div class="camera-meta__number">
            № {{ cameraInfo.number }}
          </div>
        </div>
        <button v-if="!selectableOnlyMode && cameraInfo.isEmbed" type="button" @click="showEmbed()">
          Встроить на сайт
          <svg class="icon icon-integration">
            <use xlink:href="#icon-integration" />
          </svg>
        </button>
      </div>

      <div v-show="isSelectedCamera" class="cams-container__selected-camera">
        <div class="cams-container__selected-camera-message">
          <svg height="19" viewBox="0 0 21 19" width="21" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.903 17.923L11.161.367A.768.768 0 0 0 10.5 0a.765.765 0 0 0-.66.367L.097 17.923a.691.691 0 0 0 .005.718.765.765 0 0 0 .654.359h19.486a.765.765 0 0 0 .654-.359.694.694 0 0 0 .006-.718zm-9.31-1.034H9.407v-2.111h2.188v2.11zm0-3.695H9.407v-6.86h2.188v6.86z" />
          </svg>
          <div>Камера выбрана для тревожного монитора</div>
        </div>
      </div>
      <div class="cams-container__camera-fogging" @click="clickOnScreen()" />
    </div>
    <div v-show="isSelectedCameraForFolder" class="cams-container__selected-camera">
      <div class="cams-container__selected-camera-message">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="15" cy="15" r="15" fill="#3B92CF"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M22.435 10.0502C22.8256 10.4407 22.8256 11.0739 22.435 11.4644L13.9497 19.9497C13.5592 20.3402 12.9261 20.3402 12.5355 19.9497L8.29289 15.7071C7.90237 15.3165 7.90237 14.6834 8.29289 14.2929C8.68342 13.9023 9.31658 13.9023 9.70711 14.2929L13.2426 17.8284L21.0208 10.0502C21.4113 9.65969 22.0445 9.65969 22.435 10.0502Z" fill="white"/>
        </svg>

        <div>Камера выбрана </div>
      </div>
    </div>
    <div class="cams-container__camera-fogging" @click="clickOnScreen()" />
    <button
      v-if="!selectableOnlyMode && !cameraInfo.isInactive()"
      class="cams-container__camera-controls__btn cams-container__camera-controls__btn--online"
      title="Просмотр онлайн"
      type="button"
      @click="play()"
    >
      <svg class="icon icon-play">
        <use xlink:href="#icon-play2" />
      </svg>
    </button>
    <button
      v-if="!selectableOnlyMode && !cameraInfo.isInactive() && cameraInfo.isAvailableArchive()"
      class="cams-container__camera-controls__btn cams-container__camera-controls__btn--archive"
      title="Просмотр архива"
      type="button"
      @click="playArchive()"
    >
      <svg class="icon icon-download">
        <use xlink:href="#icon-download" />
      </svg>
    </button>
    <button
      v-if="!cameraInfo.isInactive()"
      class="cams-container__camera-controls__btn cams-container__camera-controls__btn--info"
      title="Информация по камере"
      type="button"
      @click="showInfo = !showInfo"
    >
      <svg class="icon icon-info">
        <use xlink:href="#icon-info2" />
        <title>Информация по камере</title>
      </svg>
    </button>
    <!-- </div> -->

    <div class="cams-container__camera-desc">
      <button
        v-if="cameraInfo.hasAnalyticThermalVision"
        class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
        title="Тепловизор"
        type="button"
      >
        <svg class="icon icon-analytics">
          <use xlink:href="#icon-temperature" />
        </svg>
      </button>

      <button
        v-else-if="cameraInfo.hasAnalyticCarNumber"
        class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
        title="Распознавание номеров"
        type="button"
      >
        <svg class="icon icon-analytics">
          <use xlink:href="#icon-car" />
        </svg>
      </button>
      <button
        v-else-if="cameraInfo.hasAnalyticFaceRecognition"
        class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
        title="Распознавание лиц"
        type="button"
      >
        <svg class="icon icon-analytics">
          <use xlink:href="#icon-face" />
        </svg>
      </button>
      <button
        v-else-if="cameraInfo.hasAnalyticHelmet"
        class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
        title="Распознавание касок"
        type="button"
      >
        <svg class="icon icon-analytics">
          <use xlink:href="#icon-helmet" />
        </svg>
      </button>

      <button
        v-else-if="cameraInfo.hasAnalyticMask"
        class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
        title="Детектор масок"
        type="button"
      >
        <svg class="icon icon-analytics">
          <use xlink:href="#icon-mask" />
        </svg>
      </button>

      <button
        v-else-if="cameraInfo.hasAnalyticPerimeter"
        class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
        title="Детектор пересечения зоны"
        type="button"
      >
        <svg class="icon icon-analytics">
          <use xlink:href="#icon-perimeter" />
        </svg>
      </button>

      <button
        v-else-if="cameraInfo.hasAnalyticCrowd"
        class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
        title="Детектор скопления людей"
        type="button"
      >
        <svg class="icon icon-analytics">
          <use xlink:href="#icon-crowd" />
        </svg>
      </button>
      <button
        v-else-if="cameraInfo.hasAnalyticPeopleCount"
        class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
        title="Подсчет посетителей"
        type="button"
      >
        <svg class="icon icon-analytics">
          <use xlink:href="#icon-people-counter" />
        </svg>
      </button>
      <button
        v-else-if="cameraInfo.hasAnalyticMotionAlarm"
        class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
        title="Движение в кадре"
        type="button"
      >
        <svg class="icon icon-analytics">
          <use xlink:href="#icon-motion" />
        </svg>
      </button>
      <EditorCameraTitle
        :camera-info="cameraInfo"
        :is-editable="!selectableOnlyMode"
        class="cams-container__title"
        spinner-color="white"
      />
    </div>
  </div>
</template>

<script>
import {CAMERA_SCREENSHOT_SIZES} from "@/store/cameras/index.js";
import {viewTypeMixin} from "@/components/meshCameras/mixins.js";
import {YM_GOAL_PLAY_VIA_TILE} from "@/utils/tracking.js";

/**
 * Компонент отображает превью информацию по камере в виде плитки.
 *
 * todo разобрать маркеры видимости/отключения
 */
export default {
  name: "ViewTile",
  mixins: [viewTypeMixin],
  data() {
    return {
      showInfo: false,
      isVisible: false,
      isDisabled: false,
      cameraScreenshotSizes: CAMERA_SCREENSHOT_SIZES,
    };
  },
  methods: {
    /**
     * Клик по скриншоту вызывает действия в зависимости от режима работы компонента.
     */
    clickOnScreen() {
      this.selectCamera();
      !this.selectableOnlyMode && this.play(YM_GOAL_PLAY_VIA_TILE);
    },
    /**
     * todo
     *
     * @param {Boolean} isVisible
     */
    visibilityChanged(isVisible) {
      this.isVisible = this.isVisible || isVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
.cams-container__selected-camera {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  opacity: 1;
  z-index: 0;
  cursor: pointer;
}

.cams-container__selected-camera-message {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #fff;

  & svg {
    width: 50px;
    height: 50px;
    fill: #fff;
  }

  div {
    text-align: center;
  }
}
</style>
