<template>
  <div>
    <SpinnerLoading v-if="isLoading" class="loader_center" color="blue" />

    <template v-else-if="cameraInfo && cameraInfo.isReadyForLive">
      <div class="main__camera">
        <div v-html="sampleFrame" />
        <textarea :value="embedCode" class="embed-code js-clipboard" />
      </div>

      <div class="main__panel">
        <div class="panel__body embed-config">
          <SmartInputText
            v-model="paramWidth"
            caption="Ширина"
            input-type="number"
            min="100"
            @input="changeWidth"
          />
          <SmartInputText
            v-model="paramHeight"
            caption="Высота"
            input-type="number"
            @input="changeHeight"
          />
          <SmartSwitch
            v-model="paramFullscreen"
            :one-row="true"
            caption="Разворачивать в полный экран"
            class="embed-config__one-row"
          />
          <SmartSwitch
            v-model="paramAutoplay"
            :one-row="true"
            caption="Автоматический запуск"
            class="embed-config__one-row"
          />
          <SmartSwitch
            v-if="canChangeHttpMode"
            v-model="paramHttps"
            :one-row="true"
            caption="HTTPS"
            class="embed-config__one-row"
          />
          <div class="embed-config__one-row">
            <SmartSwitch
              v-model="paramLowLatencyMode"
              :one-row="true"
              caption="Прямой эфир без задержки"
            />
            <p v-show="!paramLowLatencyMode" class="embed-config__one-row" style="margin-top: 0px;">
              Стандартный способ воспроизведения.<br>Видео отдаётся с небольшой задержкой.
            </p>
            <p v-show="paramLowLatencyMode" style="margin-top: 0px;" class="embed-config__one-row">
              Видео транслируется без задержек.<br>Не поддерживается в некоторых браузерах.
            </p>
          </div>
          <SmartSwitch
            v-model="paramSound"
            :one-row="true"
            caption="Звук"
            class="embed-config__one-row"
          />
          <!-- .smart-input см. в camsng-frontend-shared -->
          <div class="embed-config__one-row smart-input">
            <div class="smart-input__name">
              Часов трансляции без перезагрузки:
            </div>

            <CustomSlider
              v-model="paramDurationAsHours"
              min="1"
              max="12"
              step="1"
              style=" margin: 20px 0 0 0"
            />
          </div>
          <button class="btn btn-blue embed-config__one-row" type="button" style="margin: 15px 0 0 70px" @click="copyToClipboard()">
            Скопировать код
          </button>
        </div>
      </div>
    </template>

    <template v-else>
      <p>Ошибка: не удалось запустить трансляцию</p>
    </template>
  </div>
</template>

<script>
import "vue-custom-range-slider/dist/vue-custom-range-slider.css";
import {loadCameraInfoMixin} from "@/components/oneScreen/mixins.js";
import {HTTP_MODE} from "camsng-frontend-shared/lib/consts.js";
import CustomSlider from "vue-custom-range-slider";
/**
 * Компонент для настроек встраивания плеера на другие сайты.
 */
export default {
  name: "EmbedScreen",
  mixins: [
    loadCameraInfoMixin,
  ],
  components: {
    CustomSlider
  },
  data() {
    return {
      paramWidth: 640,
      paramHeight: 380,
      paramFullscreen: true,
      paramAutoplay: true,
      paramSound: false,
      paramHttps: false,
      paramDurationAsHours: "1",
      paramLowLatencyMode: false,
    };
  },
  computed: {
    /**
     * @return {Number} Вернет количество секунд для настройки продолжительности воспроизведения без перезагрузки.
     */
    paramDurationAsSeconds() {
      return this.paramDurationAsHours * 3600;
    },
    /**
     * Разрешено ли менять (выбирать) пользователю http/https при запросе трансляции, или будет использоваться системный.
     * todo пусть всегда меняет
     *
     * @return {Boolean}
     */
    canChangeHttpMode() {
      return this.$store.state.context.httpMode === HTTP_MODE.composite;
    },
    /**
     * @return {String} Html с iframe для демонстрации плеера при изменении настроек.
     */
    sampleFrame() {
      if (!this.cameraInfo.isReadyForLive) {
        return "";
      }
      let protocol = this.$store.getters.httpMode;
      if (this.canChangeHttpMode) {
        protocol = this.paramHttps ? HTTP_MODE.https : HTTP_MODE.http;
      }
      const realtime = this.paramLowLatencyMode ? "realtime=true&" : "",
            embedPlayerUrl = `${protocol}://${this.cameraInfo.server.domain}/${this.cameraInfo.number}/embed.html?${realtime}token=${this.cameraInfo.tokenLive}&autoplay=${this.paramAutoplay}&mute=${!this.paramSound}&panzoom=true`,
            fullscreen = this.paramFullscreen ? `allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"` : "";

      return `<iframe class="player-embed-code" ${fullscreen} src="${embedPlayerUrl}" allow="autoplay"></iframe>`;
    },
    /**
     * @return {String} Html с iframe для вставки плеера на другие сайты.
     */
    embedCode() {
      if (!this.cameraInfo.isReadyForLive) {
        return "";
      }
      let baseUrl = window.location.origin;
      if (this.canChangeHttpMode) {
        baseUrl = `${this.paramHttps ? HTTP_MODE.https : HTTP_MODE.http}://${window.location.host}`;
      }
      const realtime = this.paramLowLatencyMode ? "realtime=true&" : "",
            embedPlayerUrl = `${baseUrl}/api/internal/embed/${this.cameraInfo.number}/?${realtime}ttl=${this.paramDurationAsSeconds}&autoplay=${this.paramAutoplay}&mute=${!this.paramSound}`,
            fullscreen = this.paramFullscreen ? `allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"` : "";

      return `<iframe ${fullscreen} width="${this.paramWidth}" height="${this.paramHeight}" src="${embedPlayerUrl}" allow="autoplay"></iframe>`;
    },
  },
  methods: {
    /**
     * Пропорциональное изменение ширины и высоты фрейма.
     *
     * @param {Number|String} newValue
     */
    changeWidth(newValue) {
      const newWidth = parseInt(newValue) || this.paramWidth;
      this.paramWidth = newWidth > 100 ? newWidth : 100;
      this.paramHeight = Math.floor(newWidth / 16 * 9);
    },
    /**
     * Пропорциональное изменение ширины и высоты фрейма.
     *
     * @param {Number|String} newValue
     */
    changeHeight(newValue) {
      const newHeight = parseInt(newValue) || this.paramHeight;
      this.paramWidth = Math.floor(newHeight / 9 * 16);
      this.paramHeight = newHeight;
    },
    /**
     * Скопирует содержимое лога в буфер обмена.
     */
    copyToClipboard() {
      const elementClipboard = document.querySelector(".js-clipboard");

      /**
       * Проверка на поддержку браузером Clipboard API.
       * В случае если версия браузера устаревшая,
       * то для копирования будет использоваться метод execCommand.
       */
      if (navigator.clipboard) {
        navigator.clipboard.writeText(elementClipboard.value);
      } else {
        const range = document.createRange();
        range.selectNode(elementClipboard);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
      }
      this.$notify({
        group: "main",
        text: "Скопировано в буфер обмена",
        duration: 3000,
        type: "default"
      });
    },
  },
};
</script>

<style lang="scss">
.embed-config {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 150px;
  grid-column-gap: 1vw;
  grid-row-gap: 1.5vw;
  padding: 11px 16px 8px 0;
  font-size: 13px;


  &__one-row {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

.embed-code {
  margin-left: 75px;
  width: 80%;
  height: 85px;
}
.smart-input_switch .smart-input__wrapper {
  margin-left: auto;
}
$label-color: #382121 !default;
</style>
