<template>
  <div class="pacs-container-entity">
    <SpinnerLoadingModal v-if="isLoading" />
    <p v-else-if="errorLoadEntity">
      Ошибка: невозможно отобразить информацию.
    </p>
    <template v-else>
      <form class="pacs-entity-form-primary" @keyup.enter="saveDataAndReload()" @submit.prevent="saveDataAndReload()">
        <SmartInputText
          v-model="dataForm.title"
          :error="dataErrors.title"
          caption="Название группы доступа"
          class="input-row"
          maxlength="128"
        />

        <SmartInputText
          v-model="dataForm.about"
          :error="dataErrors.about"
          caption="Дополнительное описание"
          class="input-row"
          input-type="area"
          maxlength="200"
        />

        <div v-if="employeesDataRows.length > 0" class="input-row">
          <PacsTable
            :column-captions="employeesColumnCaptions"
            :column-names="employeesColumnNames"
            :data-rows="employeesDataRows"
            :page-count="1"
            :view-only-mode="true"
            table-caption="Сотрудники"
          />
          <p>
            Количество сотрудников в группе: {{ employeesCount }},
            <router-link :to="routeEmployees">
              посмотреть все
            </router-link>
          </p>
        </div>

        <div class="buttons-group input-row">
          <CamsButton priority="primary" type="submit" @click="saveDataAndReload()">
            Сохранить
          </CamsButton>
          <CamsButton type="button" @click="openDialogAddExistEmployee()">
            Добавить сотрудника в группу
          </CamsButton>
          <CamsButton type="button" @click="deleteDeviceAccessGroup()">
            Удалить
          </CamsButton>
        </div>
      </form>
      <div class="pacs-entity-form-secondary">
        <SetPermissionsForDeviceAccessGroups
          :device-access-group-id="entityId"
        >
          <template #legendCaption>
            Настройки доступа
          </template>
        </SetPermissionsForDeviceAccessGroups>
      </div>
    </template>
  </div>
</template>

<script>
import {editEntityMixin} from "@/components/pacs/mixins.js";
import {
  ACTION_DELETE_DEVICE_ACCESS_GROUPS,
  ACTION_EDIT_DEVICE_ACCESS_GROUP,
  ACTION_LOAD_DEVICE_ACCESS_GROUP_FOR_EDIT,
  DEVICE_ACCESS_GROUP_STRUCT
} from "@/store/pacs/deviceAccessGroups/index.js";
import {ACTION_LOAD_EMPLOYEES_FOR_TABLE, EMPLOYEE_STRUCT} from "@/store/pacs/employees/index.js";
import PacsTable from "@/components/pacs/PacsTable.vue";
import {ROUTE_PACS_DEVICE_ACCESS_GROUPS, ROUTE_PACS_EDIT_EMPLOYEE, ROUTE_PACS_EMPLOYEES} from "@/router/names.js";
import {makeFilterApi, SORT_DIRECTIONS, SPECIAL_TYPES_DATA_IN_CELLS} from "@/store/pacs/helpers.js";
import AddEmployeeToDeviceAccessGroup from "@/components/pacs/deviceAccessGroups/AddEmployeeToDeviceAccessGroup.vue";
import {TableQueryParams} from "@/components/pacs/helpers.js";
import SetPermissionsForDeviceAccessGroups from "@/components/pacs/pacsPermissions/SetPermissionsForDeviceAccessGroups.vue";
import {QUERY_KEY_TABLE_PARAMS} from "@/router/queryKeys.js";

/**
 * Компонент страницы редактирования группы доступа.
 */
export default {
  components: {PacsTable, SetPermissionsForDeviceAccessGroups},
  mixins: [editEntityMixin],
  data() {
    const groupId = Number(this.$route.params.deviceAccessGroupId),
          paramsRouteEmployees = new TableQueryParams({
            filters: [makeFilterApi(EMPLOYEE_STRUCT.filters.device_access_group_id, "=", groupId)],
          }),
          routeEmployees = {name: ROUTE_PACS_EMPLOYEES, query: {[QUERY_KEY_TABLE_PARAMS]: paramsRouteEmployees.stringify()}};

    return {
      entityId: groupId,
      actionForSave: `pacs/deviceAccessGroups/${ACTION_EDIT_DEVICE_ACCESS_GROUP}`,
      actionForLoadEntity: `pacs/deviceAccessGroups/${ACTION_LOAD_DEVICE_ACCESS_GROUP_FOR_EDIT}`,
      dataForm: {
        groupId,
        title: "",
        about: "",
      },
      // Перечень сотрудников в группе.
      employeesColumnNames: [EMPLOYEE_STRUCT.fields.title.name],
      employeesColumnCaptions: {[EMPLOYEE_STRUCT.fields.title.name]: EMPLOYEE_STRUCT.fields.title.title},
      employeesDataRows: [],
      employeesCount: 0,
      routeEmployees: routeEmployees,
    };
  },
  methods: {
    /**
     * @link editEntityMixin.methods.afterLoadSourceData
     */
    async afterLoadSourceData() {
      this.dataForm = {
        ...this.dataForm,
        groupId: this.sourceData.entityInfo[DEVICE_ACCESS_GROUP_STRUCT.fields.id.name],
        title: this.sourceData.entityInfo[DEVICE_ACCESS_GROUP_STRUCT.fields.title.name],
        about: this.sourceData.entityInfo[DEVICE_ACCESS_GROUP_STRUCT.fields.about.name]
      };

      const employeesResponseData = await this.$store.dispatch(`pacs/employees/${ACTION_LOAD_EMPLOYEES_FOR_TABLE}`, {
        page: 1,
        pageSize: 10,
        orderBy: [{field: EMPLOYEE_STRUCT.fields.id.name, direction: SORT_DIRECTIONS.DESC}],
        filters: [makeFilterApi(EMPLOYEE_STRUCT.filters.device_access_group_id, "=", this.entityId)]
      });
      this.employeesCount = employeesResponseData.count;
      this.employeesDataRows = employeesResponseData.results.map((rawCarInfo) => {
        return {
          [EMPLOYEE_STRUCT.fields.title.name]: {
            type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
            value: rawCarInfo[EMPLOYEE_STRUCT.fields.title.name],
            params: {name: ROUTE_PACS_EDIT_EMPLOYEE, params: {employeeId: rawCarInfo[EMPLOYEE_STRUCT.key]}},
          },
        };
      });
    },
    /**
     * Сохранение данных и продолжение редактирования.
     * После успешного сохранения данных происходит перезагрузка исходных данных.
     */
    async saveDataAndReload() {
      try {
        await this.saveData();
        await this.loadSourceData();
        this.$notify({
          group: "main",
          text: "Сохранено успешно",
          duration: 5000,
          type: "default"
        });
      } catch {
        // Перехват пробрасываемого исключения с ошибками валидации.
      }
    },
    /**
     * Открытие диалога добавления ранее добавленного сотрудника.
     */
    openDialogAddExistEmployee() {
      this.$camsdals.open(
        AddEmployeeToDeviceAccessGroup,
        {deviceAccessGroupId: this.entityId},
        {dialogTitle: "Добавить сотрудника в группу"},
        {onClose: (success) => (success && this.loadSourceData())}
      );
    },
    /**
     * Отправка запроса на удаление текущей группы доступа к устройствам.
     */
    deleteDeviceAccessGroup() {
      this.$camsdals.confirm("Хотите удалить эту группу доступа?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`pacs/deviceAccessGroups/${ACTION_DELETE_DEVICE_ACCESS_GROUPS}`, [this.entityId]);
          await this.$router.push({name: ROUTE_PACS_DEVICE_ACCESS_GROUPS});
          this.$camsdals.alert("Группа доступа удалена");
        } catch {
          this.$camsdals.alert("Ошибка при удалении группы доступа");
        }
        this.isLoading = false;
      });
    },
  },
};
</script>
