<template>
  <div>
    <div v-if="isMobile" class="screen-coming-soon">
      <img class="screen-coming-soon__draft" alt="" src="@/assets/img/screen-coming-soon.svg">
      <div class="screen-coming-soon__title">
        Упс... Возникли трудности.
      </div>
      <div class="screen-coming-soon__desc">
        Мобильная версия сайта в разработке.
        <br>
        <!-- third party -->
        Вы можете воспользоваться приложением "{{ vendorIsUfanet ? "Мой умный дом" : "AVUCAMS" }}", чтобы посмотреть на свои камеры.
      </div>
      <div class="screen-coming-soon__links">
        <a :href="vendorIsUfanet ? 'https://play.google.com/store/apps/details?id=ru.ufanet.smarthome&hl=ru&showAllReviews=true' : 'https://play.google.com/store/apps/details?id=ru.ufanet.ucams'">
          <img alt="" src="@/assets/img/logo-google-store.png">
        </a>
        <a :href="vendorIsUfanet ? 'https://itunes.apple.com/ru/app/id1284483769?mt=8' : 'https://apps.apple.com/ru/app/id1527986372'">
          <img alt="" src="@/assets/img/logo-app-store.png">
        </a>
      </div>
    </div>
    <template v-else>
      <!-- third party -->
      <FavoriteNotification v-if="vendorIsUfanet" />
      <SpinnerLoadingModal v-if="isLoading" />
      <MainFrame v-else-if="isAuth && isLoadedContext" />
      <vuedal />
    </template>
  </div>
</template>

<script>
import {Component as Vuedal} from "vuedals";
import {resolveUserAgent} from "browserslist-useragent";
import {PAGE_SIZES, VENDOR_IS_UFANET} from "@/utils/consts.js";
import MobileDetect from "mobile-detect";
import {mapGetters, mapState} from "vuex";
import {MUTATION_SET_IS_MOBILE} from "@/store/mutations.js";
import {ACTION_AUTO_UPDATE, ACTION_LOAD_CONTEXT} from "@/store/actions.js";
import MainFrame from "@/components/MainFrame.vue";
import {MUTATION_SET_PAGE_SIZE} from "@/store/meshCameras/index.js";

/**
 * Главный компонент приложения. В нем описывается базовое поведение приложения вне зависимости от загружаемых компонентов.
 *
 * Компонент SpinnerLoadingModal - отображается в процессе загрузки приложения.
 *
 * Данные загружаются в MainFrame только если пользователь авторизован,
 * его токен проверен (или сессия проверена) и загружена сопутствующая по приложению информация.
 *
 * Если пользователь не авторизован, (он выходит из системы или авторизация автоматически сбрасывается по какой-то причине)
 * то происходит перезапрашивание контекстной информации, в рамках которой определяется дальнейшая навигация пользователя.
 *
 * В разделе watch происходит наблюдение за глоабльными вещами в т.ч. из состояния (более подходящего места не найдено).
 */
export default {
  name: "App",
  components: {
    MainFrame,
    Vuedal
  },
  data() {
    return {
      isLoading: false,
      isLoadedContext: false,
      vendorIsUfanet: VENDOR_IS_UFANET,
    };
  },
  computed: {
    ...mapState(["isMobile"]),
    ...mapGetters(["isAuth", "favicon"]),
  },
  watch: {
    /**
     * При выходе из системы сбрасывается токен перезапрашивается текущее состояние сервера для клиентского приложения.
     *
     * @param {Boolean} newValue
     */
    isAuth(newValue) {
      !newValue && this.loadContext();
    },
    /**
     * Показ сообщения если появились обновления.
     */
    needUpdate(needUpdate) {
      if (needUpdate) {
        this.$camsdals.confirm(
          {
            messageText: "Хотите перезагрузить страницу чтобы обновиться?",
            approveText: "Перезагрузить сейчас",
            rejectionText: "Отложить",
          },
          () => location.reload(),
          {dialogTitle: "Вышла новая версия приложения"}
        );
      }
    },
    /**
     * Отслеживание значения favicon в состоянии.
     *
     * При получении актуальных данных из контекста - будет обновлена иконка в браузере.
     *
     * @param {String} newFavicon
     */
    favicon(newFavicon) {
      if (newFavicon) {
        let elementFavicon = document.querySelector("link[rel='shortcut icon']");
        if (!elementFavicon) {
          elementFavicon = document.createElement("link");
          elementFavicon.setAttribute("rel", "shortcut icon");
          let elementHead = document.querySelector("elementHead");
          elementHead.appendChild(elementFavicon);
        }
        elementFavicon.setAttribute("href", newFavicon);
      }
    }
  },
  /**
   * Через библиотеку mobile-detect определяем является ли устройство мобильным,
   * это сохраняется в vuex для дальнейшей корректировки отображения.
   * Через browserslist-useragent уточняется юзерагент.
   *
   * Инициируется загрузка контекстной информации с сервера, после чего будет дальнейшее определение поведения
   * приложения (загрузка запрошенной информации или редирект на авторизацию).
   *
   * Настройка автоматического обновления клиентского приложения, на случай обнаружения изменений.
   */
  created() {
    // При создании приложения в консоль в дебаг режиме отправится отладочная информация используемая для сборки.
    devLog(process.env);

    const md = new MobileDetect(window.navigator.userAgent),
          browserFamily = resolveUserAgent(navigator.userAgent).family,
          isMobile = md.mobile();

    this.$store.commit(MUTATION_SET_IS_MOBILE, !!isMobile);
    if (isMobile || (browserFamily === "Edge") || (browserFamily === "Explorer")) {
      this.$store.commit("meshCameras/" + MUTATION_SET_PAGE_SIZE, PAGE_SIZES.LIMITED);
    }

    if (process.env.NODE_ENV === "production") {
      this.$store.dispatch(ACTION_AUTO_UPDATE);
    }
    this.loadContext();
  },
  methods: {
    /**
     * Загрузка контекстной информации.
     */
    async loadContext() {
      this.isLoading = true;
      this.isLoadedContext = false;
      try {
        const result = await this.$store.dispatch(ACTION_LOAD_CONTEXT);
        if (!result) {
          this.$camsdals.confirm(
            {
              messageText: "Ой, при загрузке страницы что-то пошло не так. Давайте попробуем перезагрузить страницу.",
              approveText: "Перезагрузить",
              rejectionText: "Отложить",
            },
            () => location.reload(),
            {dialogTitle: "Произошла ошибка"}
          );
        }
        this.isLoadedContext = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
