<template>
  <td class="cams-table__cell">
    <template v-if="typesData.BOOLEAN === dataCell.type">
      <div :class="[dataCell.value ? 'indicator_available': 'indicator_inaccessible']" class="indicator" />
    </template>

    <template v-else-if="typesData.DATE_TIME === dataCell.type">
      <span :title="dataCell.value | localDateTimeZone">{{ dataCell.value | localDateTime }}</span>
    </template>

    <template v-else-if="typesData.DATE === dataCell.type">
      {{ dataCell.value | localDate }}
    </template>

    <template v-else-if="typesData.ROUTE === dataCell.type">
      <router-link :to="dataCell.params">
        {{ dataCell.value }}
      </router-link>
    </template>

    <template v-else-if="(typesData.ARRAY === dataCell.type) && dataArray">
      <div v-html="dataArray.elementsForShow" />
      <router-link v-if="dataArray.link" :to="dataArray.link.to">
        {{ dataArray.link.text }}
      </router-link>
    </template>

    <template v-else>
      {{ dataCell.value }}
    </template>
  </td>
</template>

<script>
import {SPECIAL_TYPES_DATA_IN_CELLS} from "@/store/pacs/helpers.js";

/**
 * Компонент для отрисовки данных в ячейках таблицы.
 *
 * Данные могут быть сложными и для отображения некоторых (например ссылок) необходимо использовать компоненты.
 * Для принимаемых данных необходимо придерживаться структуры для параметра dataCell.
 */
export default {
  props: {
    /**
     * Стандартная структура для входных данных определяет их тип, значение и дополнительные параметры.
     *
     * @return {{type: null, params: {}, value: string}}
     */
    dataCell: {
      type: Object,
      default() {
        return {
          type: null,
          value: "",
          params: {},
        };
      }
    },
  },
  data() {
    return {
      typesData: SPECIAL_TYPES_DATA_IN_CELLS
    };
  },
  computed: {
    /**
     * Приведение значения типа {@link SPECIAL_TYPES_DATA_IN_CELLS.ARRAY} для представления в ячейке таблицы.
     *
     * Среди списка делается срез первых элементов для показа.
     *
     * Допускается передавать в качестве опциональных параметров значения, которые расцениваются как маршрут для навигации,
     * в таком случае будет отображаться ссылка.
     *
     * @return {Null|Object}
     */
    dataArray() {
      if (!Array.isArray(this.dataCell.value)) {
        return null;
      }

      let elementsForShow = this.dataCell.value.slice(0, 3).join(", ");
      if (this.dataCell.value.length > 3) {
        elementsForShow += " ...";
      }

      return {
        elementsForShow: elementsForShow,
        link: _.isEmpty(this.dataCell.params) ? null : {
          text: `посмотреть все (${this.dataCell.value.length})`,
          to: this.dataCell.params,
        }
      };
    }
  },
};
</script>
