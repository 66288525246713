/**
 * Отдельный vuex модуль для взаимодействия по setup-API
 */
import {
  DEFAULT_PAGE_SIZE_FOR_TABLE, reformatErrorsForm,
  SPECIAL_TYPES_DATA_IN_CELLS
} from "@/store/pacs/helpers.js";

// actions для cameras. Вызов действия начинать с "setup/"
export const ACTION_LOAD_CAMERAS_SETUP = "LOAD_CAMERAS_SETUP";
export const ACTION_LOAD_CAMERAS_SETUP_FOR_TABLE = "LOAD_CAMERAS_SETUP_FOR_TABLE";
export const ACTION_LOAD_CAMERAS_SETUP_EDIT = "LOAD_CAMERA_SETUP_EDIT";
export const ACTION_EDIT_CAMERA_SETUP = "EDIT_CAMERA_SETUP";
export const ACTION_REBOOT_CAMERA_SETUP = "REBOOT_CAMERA_SETUP";
export const ACTION_UPGRADE_CAMERA_SETUP = "UPGRADE_CAMERA_SETUP";
export const ACTION_LOAD_TIME_ZONE_FOR_SELECT = "LOAD_TIME_ZONE_FOR_SELECT";

/**
 * Структура для общения с API по конкретной сущности.
 */
export const CAMERA_SETUP_STRUCT = Object.freeze({
  key: "number",
  fields: {
    number: {
      name: "number",
      title: "Камера",
      type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
      sort: true,
    },
    title: {
      name: "title",
      title: "Имя",
      sort: true,
    },
    tariff: {
      name: "tariff",
      title: "Тариф",
    },
    address: {
      name: "address",
      title: "Адрес",
    },
  },
});
/**
 * Доступные разрешения на камере.
 *
 */
export const VIDEO_RESOLUTION_OPTIONS = [
  "1920x1080",
  "1280x720",
  "856x480"
];
export const VIDEO_BITRATE_OPTIONS = [
  "512",
  "1024",
  "1536",
  "2048"
];
export const NIGHT_MODE_OPTIONS = {
  "on":"Включена",
  "off":"Отключена",
  "auto":"Автоматический режим",
};
/**
 * Значения для сортировки таблицы с камерами.
 *
 */
export const PARAMS_SORT_CAMERAS_FOR_SETUP = Object.freeze({
  addr_asc: "addr_asc",
  addr_desc: "addr_desc",
  title_asc: "title_asc",
  title_desc: "title_desc",
  create_asc: "create_asc",
  create_desc: "create_desc",
});
export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка списка камер.
     *
     */
    async [ACTION_LOAD_CAMERAS_SETUP](context, {page, pageSize, orderBy, filters}) {
      const fields = Object.keys(CAMERA_SETUP_STRUCT.fields),
        response = await this.getters.ajaxClient.post("/v0/cameras/setup/supported_cameras", {
          page,
          page_size: pageSize,
          order_by: "addr_asc",
          fields,
          filters,
        });
      return response.data;
    },

    /**
     * Загрузка камеры для редактирования.
     *
     */
    async [ACTION_LOAD_CAMERAS_SETUP_EDIT](context, cameraNumber) {
      const response = await this.getters.ajaxClient.post("/v0/cameras/setup", {
        camera_number: cameraNumber,
      });
      return{entityInfo: response.data};
    },
    async [ACTION_LOAD_TIME_ZONE_FOR_SELECT]({dispatch}, {page, pageSize,search }) {
      const response = await this.getters.ajaxClient.post("/v0/timezones/", {
        page,
        search,
        pageSize
      });
      return response.data.results.map(currentValue => ({value: currentValue}));

    },
    /**
     * Загрузка списка камер доступных к конфигурации в таблице.
     */
    async [ACTION_LOAD_CAMERAS_SETUP_FOR_TABLE]({dispatch}, {
      page = 1,
      pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE,
      orderBy =  PARAMS_SORT_CAMERAS_FOR_SETUP.title_asc,
      filters = [],
    }) {
      return dispatch(ACTION_LOAD_CAMERAS_SETUP, {page, pageSize, filters, orderBy});
    },
    async [ACTION_REBOOT_CAMERA_SETUP](context, cameraNumber) {
      return this.getters.ajaxClient.post("/v0/cameras/setup/reboot",  {camera_number:cameraNumber});
    },
    async [ACTION_UPGRADE_CAMERA_SETUP](context, cameraNumber) {
      return this.getters.ajaxClient.post("/v0/cameras/setup/upgrade",  {camera_number:cameraNumber});
    },
    /**
     * Редактирование камеры.
     * @param {Object} context
     * @param {Number} employeeId
     * @param {String} cameraNumber
     * @param {Boolean} audioEnable
     * @param {Number} videoBitrate
     * @param {String} model
     * @return {Promise}
     */
    async [ACTION_EDIT_CAMERA_SETUP](context, {cameraNumber, audioEnable, model, nightModeEnable, ntpServer,timeZone,videoBitrate,videoEnable,firmWareVersion,videoResolution,cameraStatus}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/cameras/setup/configure/", {
          camera_number: cameraNumber,
          audio_enable: audioEnable,
          model: model,
          night_mode_enable: nightModeEnable,
          ntp_server: ntpServer,
          timezone: timeZone,
          video_bitrate: videoBitrate,
          video_enable: videoEnable,
          video_resolution: videoResolution,
          firmware_version: firmWareVersion,
          new_firmware_available: firmWareVersion,
          status: cameraStatus,
        });
        return response.data;
      } catch (error) {
        const apiDataErrors = reformatErrorsForm(error.response.data.fields),
          dataErrors = {
            model: apiDataErrors["model"],
            ntpServer: apiDataErrors["ntp_server"],
            timeZone: apiDataErrors["timezone"],
            videoBitrate: apiDataErrors["video_bitrate"],
          };
        throw [dataErrors, error.response.data];
      }
    },
  },
};
