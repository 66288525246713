<template>
  <div class="pacs-container-entity pacs-container-entity_column">
    <SpinnerLoadingModal v-if="isLoading" />
    <p v-else-if="errorLoadEntity">
      Ошибка: невозможно отобразить информацию.
    </p>
    <template v-else>
      <div class="input-row">
        <div><strong>Название устройства:</strong> {{ dataForm.title }}</div>
        <div>
          <strong>Состояние устройства:</strong>
          {{ dataForm.isActive ? "Активен" : "Неактивен" }}
        </div>
        <div>
          <strong>Доступ спецтранспорта:</strong>
          {{ dataForm.emergencyVehicleAccess ? "Есть" : "Нет" }}
        </div>
      </div>

      <div v-if="dataForm.cameraCount">
        <p>
          Количество привязанных камер к текущему устройству: {{ dataForm.cameraCount }}
        </p>
        <p v-if="listCamerasInfo.length" class="input-row">
          Количество доступных камер для текущего пользователя: {{ listCamerasInfo.length }}
        </p>
        <p v-else class="input-row">
          У текущего пользователя нет доступных камер.
        </p>
        <div class="input-row mesh-cameras-grid mesh-cameras-grid_tiles">
          <ViewTile
            v-for="cameraInfo in listCamerasInfo"
            :key="cameraInfo.number"
            :camera-info="cameraInfo"
            class="mesh-cameras-grid__cell"
          >
            {{ cameraInfo.number }}
          </ViewTile>
        </div>
      </div>
      <div v-else class="input-row">
        К устройству доступа не привязана ни одна камера.
      </div>

      <div class="buttons-group">
        <CamsButton
          type="button"
          @click="openDialogSetPermissionsForEmployees()"
        >
          Изменить права для сотрудников
        </CamsButton>

        <CamsButton
          type="button"
          @click="openDialogSetPermissionsForDeviceAccessGroups()"
        >
          Изменить права для групп доступа
        </CamsButton>
      </div>
    </template>
  </div>
</template>

<script>
import ViewTile from "@/components/meshCameras/ViewTile.vue";
import SetPermissionsForEmployees from "@/components/pacs/pacsPermissions/SetPermissionsForEmployees.vue";
import SetPermissionsForDeviceAccessGroups from "@/components/pacs/pacsPermissions/SetPermissionsForDeviceAccessGroups.vue";
import {ACTION_LOAD_DEVICE_FOR_EDIT, DEVICE_STRUCT} from "@/store/pacs/devices/index.js";
import {editEntityMixin} from "@/components/pacs/mixins.js";
import {ACTION_LOAD_CAMERAS, FIELDS_CAMERA} from "@/store/cameras/index.js";

export default {
  mixins: [editEntityMixin],
  components: {
    ViewTile
  },
  data() {
    const deviceId = Number(this.$route.params.deviceId);

    return {
      entityId: deviceId,
      actionForLoadEntity: `pacs/devices/${ACTION_LOAD_DEVICE_FOR_EDIT}`,
      dataForm: {
        ident: null,
        title: null,
        cameraCount: 0,
        cameraNumbers: [],
        isActive: false,
      },
      listCamerasInfo: [],
    };
  },
  methods: {
    /**
     * Получение перечня камер доступных камер на текущем устройстве доступа.
     */
    async afterLoadSourceData() {
      this.dataForm = {
        ident: this.sourceData.entityInfo[DEVICE_STRUCT.fields.ident.name],
        title: this.sourceData.entityInfo[DEVICE_STRUCT.fields.title.name],
        emergencyVehicleAccess: this.sourceData.entityInfo[DEVICE_STRUCT.fields.emergency_vehicle_access.name],
        cameraCount: this.sourceData.entityInfo[DEVICE_STRUCT.fields.camera_count.name],
        cameraNumbers: this.sourceData.entityInfo[DEVICE_STRUCT.fields.camera_numbers.name],
        isActive: this.sourceData.entityInfo[DEVICE_STRUCT.fields.is_active.name],
      };

      this.listCamerasInfo = [];
      if (!_.isEmpty(this.dataForm.cameraNumbers)) {
        this.listCamerasInfo = await this.$store.dispatch(`cameras/${ACTION_LOAD_CAMERAS}`, {
          fields: [
            FIELDS_CAMERA.number,
            FIELDS_CAMERA.address,
            FIELDS_CAMERA.title,
            FIELDS_CAMERA.timezone,
            FIELDS_CAMERA.analytics,
            FIELDS_CAMERA.is_public,
            FIELDS_CAMERA.inactivity_period,
            FIELDS_CAMERA.server,
            FIELDS_CAMERA.token_l,
            FIELDS_CAMERA.tariff,
            FIELDS_CAMERA.permission
          ],
          numbers: this.dataForm.cameraNumbers,
        });
        devLog(this.listCamerasInfo);
      }
    },
    /**
     * Открытие формы для изменения прав сотрудника на устройство.
     */
    openDialogSetPermissionsForEmployees() {
      this.$camsdals.open(
        SetPermissionsForEmployees,
        {deviceId: this.entityId},
        {dialogTitle: "Настройки доступа"},
        {name: "js-click-outside"}
      );
    },
    /**
     * Открытие формы для изменения прав групп доступа на устройство.
     */
    openDialogSetPermissionsForDeviceAccessGroups() {
      this.$camsdals.open(
        SetPermissionsForDeviceAccessGroups,
        {deviceId: this.entityId},
        {dialogTitle: "Настройки доступа"},
        {name: "js-click-outside"}
      );
    },
  }
};
</script>