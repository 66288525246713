<template>
  <div class="main__content content">
    <div class="pacs-settings-table">
      <div class="pacs-settings-table__group-setting">
        <SmartInputText
          v-model="searchText"
          class="pacs-settings-table__settings-control pacs-settings-table__settings-control_big"
          placeholder="Поиск"
          size="l"
          @keyup.native.enter="applyTableSettings()"
        >
          <CamsButton
            position="r"
            size="l"
            type="button"
            icon-type="only"
            @click="applyTableSettings()"
          >
            <svg class="icon-search">
              <use xlink:href="#icon-search" />
            </svg>
          </CamsButton>
        </SmartInputText>

        <SmartVSelect
          v-if="initialOptionsEmployees"
          v-model="filterEmployeeId"
          :initial-options="initialOptionsEmployees"
          :settings-remote-search="settingsSelectEmployee"
          class="pacs-settings-table__settings-control pacs-settings-table__settings-control_big"
          placeholder="Фильтр по сотрудникам"
          size="l"
        />

        <div v-show="showGroupOperations" class="pacs-settings-table__multi-edit">
          <CamsButton icon-type="fixed" size="l" type="button" @click="deleteCars()">
            Удалить автомобили
            <svg viewBox="-5 -5 30 30">
              <use xlink:href="#icon-delete" />
            </svg>
          </CamsButton>
        </div>

        <CamsButton priority="primary" size="l" type="button" @click="openDialogCreateCar()">
          Добавить
        </CamsButton>
      </div>
    </div>

    <SpinnerLoadingModal v-if="isLoading" />
    <SpinnerLoading v-if="isLoadingTable" class="loader_center" color="blue" />

    <PacsTable
      v-else
      :column-captions="columnCaptions"
      :column-names="columnNames"
      :column-widths="columnWidths"
      :current-page="currentPage"
      :data-rows="dataRows"
      :key-field="keyField"
      :page-count="pageCount"
      :sort-info="sortInfo"
      :total-count="totalCount"
      @new-page="selectPage"
      @new-sort="changeSort"
      @new-entities-for-multi-edit="selectedEntitiesForMultiEdit = $event"
    >
      <template #customThCells />
      <template #customTdCells="{dataRow}">
        <CamsButton
          icon-type="only"
          title="Удалить"
          type="button"
          @click="deleteCar(dataRow['_key_'])"
        >
          <svg>
            <use xlink:href="#icon-delete" />
          </svg>
        </CamsButton>
      </template>
    </PacsTable>
  </div>
</template>

<script>
import {ACTION_DELETE_CARS, ACTION_LOAD_CARS_FOR_MULTI_EDIT, ACTION_LOAD_CARS_FOR_TABLE, CAR_STRUCT} from "@/store/pacs/cars/index.js";
import {ROUTE_PACS_EDIT_CAR, ROUTE_PACS_EDIT_EMPLOYEE} from "@/router/names.js";
import PacsTable from "@/components/pacs/PacsTable.vue";
import {ACTION_LOAD_EMPLOYEES_FOR_SELECT, ACTION_LOAD_EMPLOYEES_FOR_TABLE, EMPLOYEE_STRUCT} from "@/store/pacs/employees/index.js";
import {getFilterApiValue, makeFilterApi} from "@/store/pacs/helpers.js";
import CreateCarDialog from "@/components/pacs/cars/CreateCarDialog.vue";
import {entityTableMixin} from "@/components/pacs/mixins.js";

/**
 * Компонент отображения таблицы и фильтров с информацией по автомобилям.
 */
export default {
  mixins: [
    entityTableMixin
  ],
  components: {
    PacsTable,
  },
  data() {
    return {
      entityStruct: CAR_STRUCT,
      entityFields: [
        CAR_STRUCT.fields.id,
        CAR_STRUCT.fields.number,
        CAR_STRUCT.fields.employee_id,
      ],
      actionLoadDataForMultiEdit: `pacs/cars/${ACTION_LOAD_CARS_FOR_MULTI_EDIT}`,
      // Настройки для фиксации ширины определенных колонок.
      columnWidths: {
        [CAR_STRUCT.fields.id.name]: "100px",
        [CAR_STRUCT.fields.number.name]: "150px",
      },
      // Опции фильтров.
      initialOptionsEmployees: null,
      settingsSelectEmployee: {
        action: `pacs/employees/${ACTION_LOAD_EMPLOYEES_FOR_SELECT}`,
        valueField: EMPLOYEE_STRUCT.fields.id.name,
        labelField: EMPLOYEE_STRUCT.fields.title.name
      },
      filterEmployeeId: null,
    };
  },
  watch: {
    /**
     * Загрузка автомобилей для определенного сотрудника.
     */
    filterEmployeeId() {
      const filterEmployeeId = getFilterApiValue(this.filters, CAR_STRUCT.filters.employee_id);
      if (filterEmployeeId !== this.filterEmployeeId) {
        this.stringifyQueryParams({
          currentPage: 1,
          filters: this.filterEmployeeId ? [
            makeFilterApi(CAR_STRUCT.filters.employee_id, "=", this.filterEmployeeId)
          ] : []
        });
      }
    },
    /**
     * Наблюдение за оригинальным списком фильтров чтобы актуализировать местные значения.
     */
    filters() {
      this.filterEmployeeId = getFilterApiValue(this.filters, CAR_STRUCT.filters.employee_id);
    },
  },
  /**
   * Начальная установка фильтров после настроек таблицы из URL, которая произошла в миксине.
   */
  mounted() {
    this.filterEmployeeId = getFilterApiValue(this.filters, CAR_STRUCT.filters.employee_id);
    this.loadInitialOptionsForFilters();
  },
  methods: {
    /**
     * Загрузка начальных опций для выпадающих списков фильтров.
     */
    async loadInitialOptionsForFilters() {
      let initialOptionsEmployees = [];
      if (this.filterEmployeeId) {
        const responseData = await this.$store.dispatch(`pacs/employees/${ACTION_LOAD_EMPLOYEES_FOR_TABLE}`, {
          filters: [makeFilterApi(EMPLOYEE_STRUCT.key, "=", this.filterEmployeeId)],
        });
        initialOptionsEmployees = responseData.results;
      }
      this.initialOptionsEmployees = initialOptionsEmployees;
    },
    /**
     * @link entityTableMixin.methods.loadPage
     */
    async loadPage() {
      this.isLoadingTable = true;
      try {
        const responseData = await this.$store.dispatch(`pacs/cars/${ACTION_LOAD_CARS_FOR_TABLE}`, {
                page: this.currentPage,
                search: this.searchText,
                orderBy: this.sortInfoForApi,
                filters: this.filters,
              }),
              titlesEmployees = _.chain(responseData.extra[CAR_STRUCT.extras.employee])
                .keyBy(EMPLOYEE_STRUCT.fields.id.name)
                .mapValues(EMPLOYEE_STRUCT.fields.title.name)
                .value();

        this.pageCount = responseData.page.all;
        this.totalCount = responseData.count;
        this.dataRows = responseData.results.map((rawCarInfo) => {
          const paramsRouteEditCar = {name: ROUTE_PACS_EDIT_CAR, params: {carId: rawCarInfo[CAR_STRUCT.key]}};
          return {
            ...this.calcDefaultDataRow(rawCarInfo),
            [CAR_STRUCT.fields.id.name]: {
              type: CAR_STRUCT.fields.id.type,
              value: rawCarInfo[CAR_STRUCT.fields.id.name],
              params: paramsRouteEditCar,
            },
            [CAR_STRUCT.fields.number.name]: {
              type: CAR_STRUCT.fields.number.type,
              value: rawCarInfo[CAR_STRUCT.fields.number.name],
              params: paramsRouteEditCar,
            },
            [CAR_STRUCT.fields.employee_id.name]: {
              type: CAR_STRUCT.fields.employee_id.type,
              value: titlesEmployees[rawCarInfo[CAR_STRUCT.fields.employee_id.name]],
              params: {name: ROUTE_PACS_EDIT_EMPLOYEE, params: {employeeId: rawCarInfo[CAR_STRUCT.fields.employee_id.name]}},
            },
          };
        });
      } finally {
        this.isLoadingTable = false;
      }
    },
    /**
     * Открытие диалогового окна для создания автомобиля.
     */
    openDialogCreateCar() {
      this.$camsdals.open(CreateCarDialog, {}, {dialogTitle: "Создание автомобиля"});
    },
    /**
     * Удаление автомобиля.
     *
     * @param {Number} carId
     */
    deleteCar(carId) {
      this.$camsdals.confirm("Хотите удалить этот автомобиль?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`pacs/cars/${ACTION_DELETE_CARS}`, [carId]);
          await this.loadPage();
          this.$camsdals.alert("Автомобиль удален");
        } catch {
          this.$camsdals.alert("Ошибка при удалении автомобиля");
        }
        this.isLoading = false;
      });
    },
    /**
     * Удаление автомобилей.
     */
    async deleteCars() {
      const carIds = await this.getSelectedEntitiesForMultiEdit();
      carIds.length && this.$camsdals.confirm(`Хотите удалить выбранные автомобили? Выбрано ${carIds.length} записей`, async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`pacs/cars/${ACTION_DELETE_CARS}`, carIds);
          await this.loadPage();
          this.$camsdals.alert("Автомобили удалены");
        } catch {
          this.$camsdals.alert("Ошибка при удалении автомобилей");
        }
        this.isLoading = false;
      });
    },
  },
};
</script>
