/**
 * Отдельный vuex модуль для взаимодействия по cameras-API
 */

import {PERMISSIONS, TOKEN_TTL} from "@/utils/consts.js";
import {ANALYTICS} from "@/store/analytics/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";

// actions для cameras. Вызов действия начинать с "cameras/"
export const ACTION_SEARCH_CAMERAS = "SEARCH_CAMERAS";
export const ACTION_LOAD_CAMERAS_MY = "LOAD_CAMERAS_MY";
export const ACTION_LOAD_CAMERAS_FAV = "LOAD_CAMERAS_FAV";
export const ACTION_LOAD_CAMERAS = "LOAD_CAMERAS";
export const ACTION_EDIT_TITLE = "EDIT_TITLE";
export const ACTION_PTZ_CAMERA = "PTZ_CAMERA";
export const ACTION_CHANGE_ORDER_FAV = "CHANGE_ORDER_FAV";
export const ACTION_LOAD_CAMERA = "LOAD_CAMERA";
export const ACTION_LOAD_CAMERA_FOR_PLAYER = "LOAD_CAMERA_FOR_PLAYER";
export const ACTION_LOAD_CAMERA_FOR_MULTI = "LOAD_CAMERA_FOR_MULTI";
export const ACTION_GET_DOWNLOAD_TOKEN = "GET_DOWNLOAD_TOKEN";
export const ACTION_LOAD_CAMERA_FOR_EDIT_ANALYTICS = "LOAD_CAMERA_FOR_EDIT_ANALYTICS";
export const ACTION_LOAD_CAMERAS_MY_FOLDERS = "LOAD_CAMERAS_MY_FOLDERS";
// Flussonic
export const ACTION_LOAD_WHOLE_RECORDING_STATUS = "LOAD_START_RECORDING_STATUS";
export const ACTION_LOAD_RECORDING_STATUSES = "LOAD_RECORDING_STATUSES";
export const ACTION_DISABLE_ARCHIVE = "DISABLE_ARCHIVE";
export const ACTION_ENABLE_ARCHIVE = "ENABLE_ARCHIVE";

/**
 * Перечисление констант с названиями всех полей, относящихся к камерам.
 * Возможность использования через API определенных полей определяется в конкретном endpoint.
 */
export const FIELDS_CAMERA = Object.freeze({
  number: "number",
  address: "address",
  title: "title",
  timezone: "timezone",
  longitude: "longitude",
  latitude: "latitude",
  is_embed: "is_embed",
  is_sounding: "is_sounding",
  embed_hosts: "embed_hosts",
  analytics: "analytics",
  blocking_lvl: "blocking_lvl",
  is_fav: "is_fav",
  is_public: "is_public",
  inventory: "inventory",
  inactivity_period: "inactivity_period",
  server: "server",
  tariff: "tariff",
  streams_count: "streams_count",
  token_l: "token_l",
  token_r: "token_r",
  token_d: "token_d",
  permission: "permission",
  record_disable_period: "record_disable_period",
});

/**
 * Перечисление доступных размеров для скриншотов.
 *
 * @type {Readonly<{"200": number, "400": number, "600": number}>}
 */
export const CAMERA_SCREENSHOT_SIZES = Object.freeze({
  s200: "200",
  s400: "400",
  s600: "600",
});

/**
 * Класс для представления информации о камере (по данным сервера) и комплексной работы с ней.
 */
export class CameraInfo {
  constructor(rawCameraInfo) {
    this.number = rawCameraInfo[FIELDS_CAMERA.number];
    this.address = rawCameraInfo[FIELDS_CAMERA.address];
    this.title = rawCameraInfo[FIELDS_CAMERA.title];
    this.timezone = rawCameraInfo[FIELDS_CAMERA.timezone];
    this.longitude = rawCameraInfo[FIELDS_CAMERA.longitude];
    this.latitude = rawCameraInfo[FIELDS_CAMERA.latitude];
    this.isEmbed = rawCameraInfo[FIELDS_CAMERA.is_embed];
    this.hasSound = rawCameraInfo[FIELDS_CAMERA.is_sounding];
    this.embedHosts = rawCameraInfo[FIELDS_CAMERA.embed_hosts];
    this.analytics = rawCameraInfo[FIELDS_CAMERA.analytics];
    this.blockingLvl = rawCameraInfo[FIELDS_CAMERA.blocking_lvl];
    this.inFavorites = rawCameraInfo[FIELDS_CAMERA.is_fav];
    this.isPublic = rawCameraInfo[FIELDS_CAMERA.is_public];
    this.isPtz = rawCameraInfo[FIELDS_CAMERA.inventory];
    this.inactivityPeriod = rawCameraInfo[FIELDS_CAMERA.inactivity_period]; // start reason todo
    this.server = rawCameraInfo[FIELDS_CAMERA.server]; // domain screenshot_domain
    this.tariff = rawCameraInfo[FIELDS_CAMERA.tariff]; // name dvr_size dvr_hours
    this.streamsCount = rawCameraInfo[FIELDS_CAMERA.streams_count];
    this.tokenLive = rawCameraInfo[FIELDS_CAMERA.token_l];
    this.tokenDVR = rawCameraInfo[FIELDS_CAMERA.token_r];
    this.tokenDownload = rawCameraInfo[FIELDS_CAMERA.token_d];
    this.permission = rawCameraInfo[FIELDS_CAMERA.permission];
    this.recordDisablePeriod = rawCameraInfo[FIELDS_CAMERA.record_disable_period];
  }

  /**
   * Вернет true если в объекте имеются базовые данные для формирования URL для прямой трансляции.
   *
   * Требуется:
   * - токен доступа к трансляции,
   * - данные сервера с доменом, откуда можно забирать трансляцию.
   *
   * @return {Boolean}
   */
  get isReadyForLive() {
    return Boolean(this.tokenLive && this.server && this.server.domain);
  }

  /**
   * Вернет true если в объекте имеются базовые данные для формирования URL для трансляции архива.
   *
   * Требуется:
   * - токен доступа к архиву,
   * - данные сервера с доменом, откуда можно забирать трансляцию.
   *
   * Не учитывает запрашиваемый период.
   *
   * @return {Boolean}
   */
  get isReadyForArchive() {
    return Boolean(this.tokenDVR && this.server && this.server.domain);
  }

  /**
   * Вернет true если камера поддерживает более одного потока.
   *
   * @return {Boolean}
   */
  get hasMultiStreams() {
    return this.streamsCount > 1;
  }

  /**
   * Вернет true в случае если у камеры есть координаты.
   *
   * @return {Boolean}
   */
  get hasCoordinates() {
    return Boolean((this.latitude || this.latitude === 0) && (this.longitude || this.longitude === 0));
  }

  /**
   * @return {null|Number} Количество часов архива доступного камере по ее тарифу.
   */
  get archiveHours() {
    return this?.tariff?.dvr_hours;
  }

  /**
   * Вернет true если у камеры имеется период неактивности.
   *
   * @return {Boolean}
   */
  isInactive() {
    return !!this.inactivityPeriod;
  }

  isDisabled() {
    return !!this.recordDisablePeriod;
  }

  /**
   * Есть ли у камеры архив согласно тарифу.
   * Стоит отметить, что текущий пользователь может и не иметь доступа к информации о тарифе.
   * В такой ситуации считаем, что архива нет.
   *
   * @return {Boolean}
   */
  get hasArchive() {
    return Boolean(this.tariff && (this.tariff.dvr_hours || this.tariff.dvr_size));
  }

  /**
   * Есть ли доступ к архиву.
   * Доступ есть если:
   * - камера активна (нет периода неактивности),
   * - на самой камере вообще есть архив,
   * - пользователь является владельцем камеры или имеет права на доступ к архиву.
   *
   * @return {Boolean}
   */
  isAvailableArchive() {
    return this.hasArchive && [PERMISSIONS.O, PERMISSIONS.R].includes(this.permission);
  }

  /**
   * Название тарифа камеры.
   * Учитывается ситуации, что у пользователя может не быть доступа к этой информации, в этом случае выводим пустую строку.
   *
   * @return {String}
   */
  tariffName() {
    return this.tariff ? this.tariff.name : "";
  }

  /**
   * Вернет true если для камеры доступна какая-либо (понятная для приложения) аналитика.
   *
   * @return {Boolean}
   */
  get hasAnalytics() {
    return !_.isEmpty(_.intersection(this.analytics, Object.keys(ANALYTICS)));
  }

  /**
   * Вернет true если для камеры доступно получение информации по аналитике тепловизоров.
   *
   * @return {Boolean}
   */
  get hasAnalyticThermalVision() {
    return this.analytics.includes(ANALYTICS.thermal_vision);
  }

  /**
   * Вернет true если для камеры доступно получение информации по распознаванию автомобильных номеров.
   *
   * @return {Boolean}
   */
  get hasAnalyticCarNumber() {
    return this.analytics.includes(ANALYTICS.car_number);
  }

  /**
   * Вернет true если для камеры доступно получение информации по распознаванию лиц.
   *
   * @return {Boolean}
   */
  get hasAnalyticFaceRecognition() {
    return this.analytics.includes(ANALYTICS.face_recognition);
  }

  /**
   * Вернет true если для камеры доступно получение информации по определению касок.
   *
   * @return {Boolean}
   */
  get hasAnalyticHelmet() {
    return this.analytics.includes(ANALYTICS.helmet);
  }

  /**
   * Вернет true если для камеры доступно получение информации по распознаванию движения.
   *
   * @return {Boolean}
   */
  get hasAnalyticMotionAlarm() {
    return this.analytics.includes(ANALYTICS.motion_alarm);
  }
  /**
   * Вернет true если для камеры доступно получение информации по скоплению людей.
   *
   * @return {Boolean}
   */
  get hasAnalyticCrowd() {
    return this.analytics.includes(ANALYTICS.crowd);
  }
  /**
   * Вернет true если для камеры доступно получение информации по распознаванию масок.
   *
   * @return {Boolean}
   */
  get hasAnalyticMask() {
    return this.analytics.includes(ANALYTICS.mask);
  }
  /**
   * Вернет true если для камеры доступно получение информации по вторжению в зону.
   *
   * @return {Boolean}
   */
  get hasAnalyticPerimeter() {
    return this.analytics.includes(ANALYTICS.perimeter_security);
  }
  get hasAnalyticPeopleCount() {
    return this.analytics.includes(ANALYTICS.people_counter);
  }

  /**
   * Вернет значение offset часового пояса камеры, если он есть.
   *
   * @return {String|Null}
   */
  get timezoneOffset() {
    return this.timezone ? moment().tz(this.timezone).format("Z") : null;
  }

  /**
   * Вернет URL для использования Server Sent Events через механизм EventSource.
   *
   * @param {String} pushServer
   * @param {String} analytic
   * @return {String}
   */
  getAnalyticSseUrl(pushServer, analytic) {
    if (!this.tokenLive) {
      return null;
    }
    return `//${pushServer}/api/v0/analytics/push/${analytic}/camera/${this.number}/?token=${this.tokenLive}`;
  }

  /**
   * Вернет URL для получения скриншота с камеры, или пустую строку если это невозможно сделать.
   *
   * Опционально можно передать значение из списка {@link CAMERA_SCREENSHOT_SIZES} для обозначения определенного размера скриншота.
   *
   * @param {String} sizeScreenshot
   * @param {String} signForUpdate Уникальная метка, которая добавляется в URL для избежания кеширования при регулярном обновлении.
   * @return {String}
   */
  screenshotUrl(sizeScreenshot = CAMERA_SCREENSHOT_SIZES.s400, signForUpdate = null) {
    if (!this.isReadyForLive) {
      return "";
    }
    const sign = signForUpdate ? `&sign=${signForUpdate}` : "";
    if (this.server.screenshot_domain && sizeScreenshot) {
      return `//${this.server.screenshot_domain}/api/v0/screenshots/${this.number}~${sizeScreenshot}.jpg?token=${this.tokenLive}${sign}`;
    }
    return `//${this.server.domain}/${this.number}/preview.mp4?token=${this.tokenLive}${sign}`;
  }
}
export const TOKEN_TYPES = Object.freeze({
  L: "L", // Прямая трансляция.
  R: "R", // Архив.
  D: "D", // Скачивание.
});
export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Общий метод для загрузки списка камер.
     *
     * Согласно API имеется ряд методов, принимающих некоторое количество одинаковых параметров для настройки
     * результатов в камерах, и некоторое количество специфических параметров, зависимых от предполагаемого контекста использования API.
     *
     * Все передаваемые параметры сливаются в один общий объект с корректными для API ключами, отфильтровываются пустые
     * и так передаются в API по заданному endpoint. Поскольку особенные параметры зависят от передаваемого endpoint то считаем что этот метод
     * не самостоятельный - вызывать его можно только в рамках текущего объекта - через те методы которые завязаны на определенный endpoint.
     *
     * @param {Object} context
     * @param {String} endPoint
     * @param {Object} rawApiParams
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async _commonLoadCameras(context, {endPoint, rawApiParams, cancelTokenKey = ""}) {
      const apiParams = _.omitBy({
        order_by: rawApiParams.orderBy,
        fields: rawApiParams.fields,
        token_l_ttl: rawApiParams.tokenLiveTTL,
        token_r_ttl: rawApiParams.tokenDVRTTL,
        token_d_ttl: rawApiParams.tokenDownloadTTL,
        token_d_duration: rawApiParams.tokenDownloadDuration,
        token_d_start: rawApiParams.tokenDownloadStart,
        token_label: rawApiParams.tokenLabel,
        numbers: rawApiParams.numbers,
        page: rawApiParams.page,
        page_size: rawApiParams.pageSize,
        camera_group_ids: rawApiParams.cameraGroupIds,
        query: rawApiParams.query,
        user_cameras: rawApiParams.userCameras,
        fav_cameras: rawApiParams.favCameras,
        public_cameras: rawApiParams.publicCameras,
      }, _.isNil);

      return this.getters.ajaxClient.post(
        `/v0/cameras/${endPoint}/`,
        apiParams,
        {cancelToken: cancelRequestOnReentry(cancelTokenKey)}
      );
    },
    async _commonLoadCamerasFoldersData(context, {endPoint, rawApiParams, cancelTokenKey = ""}) {
      const apiParams = _.omitBy({
        order_by: rawApiParams.orderBy,
        fields: rawApiParams.fields,
        token_l_ttl: rawApiParams.tokenLiveTTL,
        token_r_ttl: rawApiParams.tokenDVRTTL,
        token_d_ttl: rawApiParams.tokenDownloadTTL,
        token_d_duration: rawApiParams.tokenDownloadDuration,
        token_d_start: rawApiParams.tokenDownloadStart,
        token_label: rawApiParams.tokenLabel,
        numbers: rawApiParams.numbers,
        page: rawApiParams.page,
        page_size: rawApiParams.pageSize,
        camera_group_ids: rawApiParams.cameraGroupIds,
        query: rawApiParams.query,
        user_cameras: rawApiParams.userCameras,
        fav_cameras: rawApiParams.favCameras,
        public_cameras: rawApiParams.publicCameras,
        camera_folder_ids: rawApiParams.folderIds,
      }, _.isNil);

      return this.getters.ajaxClient.post(
        `/v0/cameras/${endPoint}/`,
        apiParams,
        {cancelToken: cancelRequestOnReentry(cancelTokenKey)}
      );
    },
    /**
     * Использует {@link _commonLoadCameras} как целевой запрос к API, поддерживающий постраничную навигацию.
     * Снабжает результат в promise информацию о страницах.
     *
     * @see _commonLoadCameras
     * @return {Promise.Array<CameraInfo[], Number, Number>} Список камер, количество страниц, общее количество камер.
     */
    async _commonLoadCamerasByPages({dispatch}, {endPoint, rawApiParams, cancelTokenKey = ""}) {
      try {
        const response = await dispatch("_commonLoadCamerasFoldersData", {endPoint, rawApiParams, cancelTokenKey});
        if (response.data.count) {
          return [response.data.results.map(rawCameraInfo => new CameraInfo(rawCameraInfo)), response.data.page.all, response.data.count];
        }
      } catch (error) {
        devLog("[_commonLoadCamerasByPages]", error);
        handleErrorInRequest(error);
      }
      return [[], 0, 0];
    },
    async _commonLoadCamerasFolders ({dispatch}, {endPoint, rawApiParams, cancelTokenKey = ""}) {
      try {
        const response = await dispatch("_commonLoadCamerasFoldersData", {endPoint, rawApiParams, cancelTokenKey});
        if (response.data.count) {
          return [response.data.results.map(rawCameraInfo => new CameraInfo(rawCameraInfo)), response.data.page.all, response.data.count];
        }
      } catch (error) {
        devLog("[_commonLoadCamerasFolders]", error);
        handleErrorInRequest(error);
      }
      return [[], 0, 0];
    },
    /**
     * Загрузка заданного перечня камер.
     *
     * @see _commonLoadCameras
     */
    async [ACTION_LOAD_CAMERAS]({dispatch}, {orderBy, fields, tokenLiveTTL, tokenDVRTTL, tokenDownloadTTL, tokenDownloadDuration, tokenDownloadStart, tokenLabel, numbers}) {
      try {
        const response = await dispatch("_commonLoadCameras", {
          endPoint: "this",
          rawApiParams: {
            orderBy,
            fields,
            tokenLiveTTL,
            tokenDVRTTL,
            tokenDownloadTTL,
            tokenDownloadDuration,
            tokenDownloadStart,
            tokenLabel,
            numbers
          },
        });
        if (response.data.count) {
          return response.data.results.map(rawCameraInfo => new CameraInfo(rawCameraInfo));
        }
      } catch (error) {
        devLog("[ACTION_LOAD_CAMERAS]", error);
        handleErrorInRequest(error);
      }
      return [];
    },
    /**
     * Загрузка списка камер пользователя для которых были выданы права.
     *
     * @see _commonLoadCamerasByPages
     */
    async [ACTION_LOAD_CAMERAS_MY]({dispatch}, {orderBy, fields, tokenLiveTTL, tokenDVRTTL, tokenDownloadTTL, tokenDownloadDuration, tokenDownloadStart, tokenLabel, page, pageSize, cameraGroupIds}) {
      return dispatch("_commonLoadCamerasByPages", {
        endPoint: "my",
        rawApiParams: {orderBy, fields, tokenLiveTTL, tokenDVRTTL, tokenDownloadTTL, tokenDownloadDuration, tokenDownloadStart, tokenLabel, page, pageSize, cameraGroupIds},
      });
    },
    /**
     * Загрузка списка камер по конкретной папке.
     *
     * @see _commonLoadCamerasFolders
     */
    async [ACTION_LOAD_CAMERAS_MY_FOLDERS]({dispatch}, {orderBy, fields, tokenLiveTTL, tokenDVRTTL, tokenDownloadTTL, tokenDownloadDuration, tokenDownloadStart, tokenLabel, page, pageSize, cameraGroupIds, folderIds}) {
      return dispatch("_commonLoadCamerasFolders", {
        endPoint: "my",
        rawApiParams: {orderBy, fields, tokenLiveTTL, tokenDVRTTL, tokenDownloadTTL, tokenDownloadDuration, tokenDownloadStart, tokenLabel, page, pageSize, cameraGroupIds, folderIds},
      });
    },
    /**
     * Загрузка списка камер пользователя добавленных в избранное.
     *
     * @see _commonLoadCamerasByPages
     */
    async [ACTION_LOAD_CAMERAS_FAV]({dispatch}, {orderBy, fields, tokenLiveTTL, tokenDVRTTL, tokenDownloadTTL, tokenDownloadDuration, tokenDownloadStart, tokenLabel, page, pageSize}) {
      return dispatch("_commonLoadCamerasByPages", {
        endPoint: "fav",
        rawApiParams: {orderBy, fields, tokenLiveTTL, tokenDVRTTL, tokenDownloadTTL, tokenDownloadDuration, tokenDownloadStart, tokenLabel, page, pageSize},
        cancelTokenKey: ACTION_LOAD_CAMERAS_FAV,
      });
    },
    /**
     * Загрузка списка камер по заданному критерию поиска.
     *
     * @see _commonLoadCamerasByPages
     */
    async [ACTION_SEARCH_CAMERAS]({dispatch}, {orderBy, fields, tokenLiveTTL, tokenDVRTTL, tokenDownloadTTL, tokenDownloadDuration, tokenDownloadStart, tokenLabel, page, pageSize, query, userCameras, favCameras, publicCameras}) {
      return dispatch("_commonLoadCamerasByPages", {
        endPoint: "search",
        rawApiParams: {orderBy, fields, tokenLiveTTL, tokenDVRTTL, tokenDownloadTTL, tokenDownloadDuration, tokenDownloadStart, tokenLabel, page, pageSize, query, userCameras, favCameras, publicCameras},
        cancelTokenKey: ACTION_SEARCH_CAMERAS,
      });
    },
    /**
     * Загрузка одной камеры.
     *
     * @param {Function} dispatch
     * @param {Array} fields
     * @param {Number} tokenLiveTTL
     * @param {Number} tokenDVRTTL
     * @param {Number} tokenDownloadTTL
     * @param {Number} tokenDownloadDuration
     * @param {Number} tokenDownloadStart
     * @param {String} number
     * @return {Promise.<CameraInfo>}
     */
    async [ACTION_LOAD_CAMERA]({dispatch}, {fields, tokenLiveTTL, tokenDVRTTL, tokenDownloadTTL, tokenDownloadDuration, tokenDownloadStart, number}) {
      const listCamerasInfo = await dispatch(ACTION_LOAD_CAMERAS, {
        fields,
        tokenLiveTTL,
        tokenDVRTTL,
        tokenDownloadTTL,
        tokenDownloadDuration,
        tokenDownloadStart,
        numbers: [number]
      });
      return _.head(listCamerasInfo) || null;
    },
    /**
     * Специальное действие с настроенным запросом информации по камере для работы плеера.
     * Можно кешировать чтобы избежать повторных запросов при быстрых переключениях между камерами.
     *
     * @param {Function} dispatch
     * @param {String} number
     * @param {Number} tokenLiveTTL
     * @param {Number} tokenDVRTTL
     * @param {Number} tokenDownloadTTL
     * @param {Number} tokenDownloadDuration
     * @param {Number} tokenDownloadStart
     * @return {Promise.<CameraInfo>}
     */
    async [ACTION_LOAD_CAMERA_FOR_PLAYER]({dispatch}, {number, tokenLiveTTL, tokenDVRTTL, tokenDownloadTTL, tokenDownloadDuration, tokenDownloadStart}) {
      const fields = [
        // todo отфильтровать
        FIELDS_CAMERA.number,
        FIELDS_CAMERA.address,
        FIELDS_CAMERA.title,
        FIELDS_CAMERA.timezone,
        FIELDS_CAMERA.longitude,
        FIELDS_CAMERA.latitude,
        FIELDS_CAMERA.is_embed,
        FIELDS_CAMERA.is_sounding,
        // FIELDS_CAMERA.embed_hosts,
        FIELDS_CAMERA.analytics,
        // FIELDS_CAMERA.blocking_lvl,
        // FIELDS_CAMERA.marker_id,
        FIELDS_CAMERA.is_fav,
        FIELDS_CAMERA.is_public,
        FIELDS_CAMERA.inventory,
        FIELDS_CAMERA.inactivity_period,
        FIELDS_CAMERA.server,
        FIELDS_CAMERA.tariff,
        FIELDS_CAMERA.streams_count,
        FIELDS_CAMERA.permission,
        FIELDS_CAMERA.record_disable_period,
      ];
      if (tokenLiveTTL) {
        fields.push(FIELDS_CAMERA.token_l);
      }
      if (tokenDVRTTL) {
        fields.push(FIELDS_CAMERA.token_r);
      }
      if (tokenDownloadTTL || (tokenDownloadDuration && tokenDownloadStart)) {
        fields.push(FIELDS_CAMERA.token_d);
      }

      return dispatch(ACTION_LOAD_CAMERA, {
        fields,
        tokenLiveTTL,
        tokenDVRTTL,
        tokenDownloadTTL,
        tokenDownloadDuration,
        tokenDownloadStart,
        number
      });
    },
    /**
     * Специальное действие с настроенным запросом информации по камере для работы плеера в рамках мультиэкрана.
     * Часть информации не является необходимой для собственно самого мультиэкрана, но нужна для показа событий в рамках него.
     * Можно кешировать чтобы избежать повторных запросов при быстрых переключениях между камерами.
     *
     * @param {Function} dispatch
     * @param {String} number
     * @return {Promise.<CameraInfo>}
     */
    async [ACTION_LOAD_CAMERA_FOR_MULTI]({dispatch}, number) {
      return dispatch(ACTION_LOAD_CAMERA, {
        fields: [
          FIELDS_CAMERA.number,
          FIELDS_CAMERA.address,
          FIELDS_CAMERA.title,
          FIELDS_CAMERA.is_sounding,
          FIELDS_CAMERA.analytics,
          FIELDS_CAMERA.server,
          FIELDS_CAMERA.tariff,
          FIELDS_CAMERA.streams_count,
          FIELDS_CAMERA.token_l,
          FIELDS_CAMERA.token_r,
          FIELDS_CAMERA.permission,
          FIELDS_CAMERA.inventory,
        ],
        tokenLiveTTL: TOKEN_TTL.MULTI,
        tokenDVRTTL: TOKEN_TTL.MULTI,
        number
      });
    },
    /**
     * Отправка запроса на получение токена для скачивания и его возврат в случае успеха.
     *
     * @param {Function} dispatch
     * @param {String} number
     * @param {Number} tokenDownloadDuration
     * @param {Number} tokenDownloadStart
     * @param {Number} tokenDownloadTTL
     * @return {Promise.<String>}
     */
    async [ACTION_GET_DOWNLOAD_TOKEN]({dispatch}, {number, tokenDownloadDuration, tokenDownloadStart, tokenDownloadTTL = TOKEN_TTL.DEFAULT}) {
      try {
        const cameraInfoWithTokenDownload = await dispatch(ACTION_LOAD_CAMERA, {
          fields: [FIELDS_CAMERA.token_d],
          tokenDownloadDuration,
          tokenDownloadStart,
          tokenDownloadTTL,
          number,
        });
        return cameraInfoWithTokenDownload.tokenDownload;
      } catch (error) {
        devLog("[ACTION_GET_DOWNLOAD_TOKEN]", error);
        return null;
      }
    },
    /**
     * Отправка запроса на изменение заголовка камеры.
     *
     * todo есть смысл сбросить тут весь кеш
     *
     * @param {Object} context
     * @param {String} number
     * @param {String} title
     * @return {Promise}
     */
    async [ACTION_EDIT_TITLE](context, {number, title}) {
      return this.getters.ajaxClient.post("/v0/cameras/title/", {number, title});
    },
    /**
     * Управление камерой PTZ
     *
     * @param {Object} context
     * @param {Array.<String>} camerasNumbers
     * @return {Promise}
     */
    async [ACTION_PTZ_CAMERA](context, action) {
      return this.getters.ajaxClient.post("v0/cameras/setup/ptz", {
        ...action,
      })
    },
    /**
     * Отправка запроса к серверу flussonic для уточнения общего периода доступного участка архива и извлечение из него начальной даты.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {String} domain
     * @param {String} token
     * @return {Promise.<Array>} Массив из двух timestamp значений границ архива.
     */
    async [ACTION_LOAD_WHOLE_RECORDING_STATUS](context, {cameraNumber, domain, token}) {
      const response = await fetch(`${this.getters.protocolVideoOverHTTP}://${domain}/${cameraNumber}/recording_status.json?token=${token}`),
        responseData = await response.json();
      if (responseData[cameraNumber].from && responseData[cameraNumber].to) {
        return [responseData[cameraNumber].from, responseData[cameraNumber].to];
      }
    },
    /**
     * Отправка запроса к серверу flussonic для уточнения доступных участков видео.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {String} domain
     * @param {String} token
     * @param {Number} unixFrom
     * @param {Number} unixTo
     * @return {Promise.<Array>}
     */
    async [ACTION_LOAD_RECORDING_STATUSES](context, {cameraNumber, domain, token, unixFrom = 0, unixTo = Math.trunc(Date.now() / 1000)}) {
      try {
        const response = await fetch(`${this.getters.protocolVideoOverHTTP}://${domain}/${cameraNumber}/recording_status.json?token=${token}&request=ranges&from=${unixFrom}&to=${unixTo}`),
          responseData = await response.json();

        return responseData[0].ranges.map(({duration, from}) => {
          return [
            new Date(from * 1000),
            new Date((from + duration) * 1000),
          ];
        });
      } catch (e) {
        return [];
      }
    },
    /**
     * Специальное действие с настроенным запросом информации по камере
     * для работы плеера в рамках редактирования зоны распознавания номеров по конкретной камере.
     *
     * @param {Function} dispatch
     * @param {String} numbers
     * @return {Promise.<CameraInfo>}
     */
    async [ACTION_LOAD_CAMERA_FOR_EDIT_ANALYTICS]({dispatch}, {orderBy, fields, tokenLiveTTL, tokenDVRTTL, tokenDownloadTTL, tokenDownloadDuration, tokenDownloadStart, tokenLabel, page, pageSize, query, userCameras, favCameras, publicCameras, numbers}) {
      return dispatch("_commonLoadCameras", {
        endPoint: "gang",
        rawApiParams: {
          orderBy, fields, tokenLiveTTL, tokenDVRTTL, tokenDownloadTTL, tokenDownloadDuration, tokenDownloadStart, tokenLabel, page, pageSize, query, userCameras, favCameras, publicCameras, numbers},
      });

    },
  },
};
