<template>
  <div class="tabs-container map-links">
    <!-- Для широких экранов. -->
    <div class="map-links__tabs">
      <router-link :to="{name: routesForTabs.ROUTE_MAP_MY}" class="tabs-container__tab-item">
        Мои камеры
      </router-link>
      <router-link :to="{name: routesForTabs.ROUTE_MAP_FAV}" class="tabs-container__tab-item">
        Избранные
      </router-link>
      <router-link :to="{name: routesForTabs.ROUTE_MAP_PUBLIC}" class="tabs-container__tab-item">
        Публичные
      </router-link>
    </div>
    <!-- Для узких экранов. -->
    <div class="map-links__select">
      <SmartSelect
        v-model="selectedRoute"
        :options="routesForSelect"
        size="l"
        width="fill"
        @input="$router.push({name: $event})"
      />
    </div>
  </div>
</template>

<script>
import {ROUTE_MAP_FAV, ROUTE_MAP_MY, ROUTE_MAP_PUBLIC} from "@/router/names.js";

/**
 * Компонент ссылок на карте для навигации между списками камер.
 */
export default {
  name: "MapLinks",
  data() {
    return {
      routesForTabs: {
        ROUTE_MAP_MY,
        ROUTE_MAP_FAV,
        ROUTE_MAP_PUBLIC,
      },
      routesForSelect: {
        [ROUTE_MAP_MY]: "Мои камеры",
        [ROUTE_MAP_FAV]: "Избранные",
        [ROUTE_MAP_PUBLIC]: "Публичные",
      },
      selectedRoute: this.$route.name,
    };
  },
  watch: {
    /**
     * Синхронизация маршрута и выбранного элемента в выпадающем списке.
     */
    $route(to) {
      this.selectedRoute = to.name;
    },
  },
};
</script>
