    <template>
      <form @submit.prevent="saveDataAndCloseDialog()">
      <div class="mesh-cameras mesh-cameras_in-dialog">

        <div class="mesh-cameras__folder_actions">
          <div class="left-group">
            <SmartInputText
              class="pacs-settings-table__settings-control pacs-settings-table__settings-control_bigest"
              size="l"
              placeholder="Введите имя папки"
              v-model="dataForm.folderTitle"
              :error="dataErrors.folderTitle"
            />
            <div class="buttons-group buttons-group--align-right">
              <CamsButton priority="primary" type="submit" size="l">
                Сохранить изменения
              </CamsButton>
            </div>
          </div>
          <div class="buttons-group buttons-group--align-left">
            <CamsButton priority="primary" type="button" size="m" @click.stop="openSubFolderDialog()">
              Создать подпапку
            </CamsButton>
            <CamsButton priority="primary" type="button" size="m" @click.stop="deleteFolder()">
              Удалить папку
            </CamsButton>
          </div>
        </div>

    <div class="mesh-cameras__view-options">
      <SelectSort
        v-model="selectedSort"
        :available-params-sort="availableParamsSort"
        class="mesh-cameras__sort-selector"
        @input="saveStateAndLoadCameras()"
      />


      <ViewTypeSwitcher
        v-model="selectedView"
        class="mesh-cameras__view-type-switcher"
        @input="saveState()"
      />
    </div>
        <div class="mesh-cameras__navigate">
          <div class="tabs-container">
            <a
              :class="{'tabs-container__tab-item--active': selectedTab === tabs.MY}"
              class="tabs-container__tab-item"
              @click="selectTab(tabs.MY)"
            >
              Мои камеры
            </a>
            <a
              v-if="queryTabSearch"
              :class="{'tabs-container__tab-item--active': selectedTab === tabs.SEARCH}"
              class="tabs-container__tab-item"
              @click="selectTab(tabs.SEARCH)"
            >
              Поиск
            </a>
          </div>

          <CamerasFinder
            v-model="querySearchCameras"
            :selectable-only-mode="true"
            @input="saveState()"
            @select-camera="selectCamera"
            @show-all-results="showAllSearchResults()"
          />

        </div>
    <SpinnerLoadingModal v-if="isLoading"/>
    <template v-else>
      <paginate
        v-if="pageCount > 1"
        v-model="selectedPage"
        :click-handler="saveStateAndLoadCameras"
        :hide-prev-next="true"
        :page-count="pageCount"
        :page-range="5"
        active-class="smart-pagination__page_active"
        break-view-class="smart-pagination__page_collapse"
        container-class="smart-pagination"
        next-class="smart-pagination__page smart-pagination__next-prev"
        next-link-class="smart-pagination__page-link"
        next-text="<svg class='icon icon-arrow-left'><use xlink:href='#icon-arrow-left'></use></svg>"
        page-class="smart-pagination__page"
        page-link-class="smart-pagination__page-link"
        prev-class="smart-pagination__page smart-pagination__next-prev"
        prev-link-class="smart-pagination__page-link"
        prev-text="<svg class='icon icon-arrow-right'><use xlink:href='#icon-arrow-right'></use></svg>"
      />

      <SpinnerLoading v-if="isLoadingPage" class="loader_center" color="blue" />
      <div
        v-else-if="listCamerasInfo.length"
        class="mesh-cameras-grid"
        :class="[isTableView ? 'mesh-cameras-grid_table' : 'mesh-cameras-grid_tiles']"
      >
        <component
          :folder-mode="true"
          :is="componentView"
          v-for="cameraInfo in listCamerasInfo"
          :key="cameraInfo.number"
          :camera-info="cameraInfo"
          :is-selected-camera-for-folder="multiSelectableMode && selectedCameraNumbers.includes(cameraInfo.number)"
          :screenshot-sign="screenshotSign"
          :selectable-only-mode="true"
          class="mesh-cameras-grid__cell"
          @select-camera="selectCamera"
        />
      </div>
      <p v-else>
        Камер не найдено
      </p>

      <paginate
        v-if="pageCount > 1"
        v-model="selectedPage"
        :click-handler="saveStateAndLoadCameras"
        :hide-prev-next="true"
        :page-count="pageCount"
        :page-range="5"
        active-class="smart-pagination__page_active"
        break-view-class="smart-pagination__page_collapse"
        container-class="smart-pagination"
        next-class="smart-pagination__page smart-pagination__next-prev"
        next-link-class="smart-pagination__page-link"
        next-text="<svg class='icon icon-arrow-left'><use xlink:href='#icon-arrow-left'></use></svg>"
        page-class="smart-pagination__page"
        page-link-class="smart-pagination__page-link"
        prev-class="smart-pagination__page smart-pagination__next-prev"
        prev-link-class="smart-pagination__page-link"
        prev-text="<svg class='icon icon-arrow-right'><use xlink:href='#icon-arrow-right'></use></svg>"
      />
    </template>


  </div>
        <SpinnerLoadingModal v-if="isLoading" />

      </form>
</template>

<script>
import {FRAMES, TABS} from "@/store/meshCameras/index.js";
import {TYPES_VIEWS} from "@/utils/consts.js";
import CamerasFinder from "@/components/meshCameras/CamerasFinder.vue";
import SelectSort from "@/components/meshCameras/SelectSort.vue";
import ViewTypeSwitcher from "@/components/meshCameras/ViewTypeSwitcher.vue";
import ViewTable from "@/components/meshCameras/ViewTable.vue";
import ViewTile from "@/components/meshCameras/ViewTile.vue";
import {meshFrameMixin} from "@/components/meshCameras/mixins.js";
import {
  ACTION_ADD_CAMERAS_TO_FOLDER,
  ACTION_DELETE_FOLDER,
  ACTION_EDIT_FOLDER,
} from "@/store/folders/index.js";
import {createEntityMixin} from "@/components/pacs/mixins.js";
import MeshCreateFolderFrameDialog from "@/components/meshCameras/MeshCreateFolderFrameDialog.vue";

/**
 * Компонент для представления списка камер на диалоговом окне для выбора конкретной камеры.
 */
export default {
  name: "MeshFrameDialog",
  mixins: [meshFrameMixin,createEntityMixin],
  components: {
    CamerasFinder,
    SelectSort,
    ViewTypeSwitcher,
  },
  props: {
    /**
     * Список выбранных камер для режима выбора нескольких камер. Если нужно выбирать по 1 камере передавать false.
     */
    initialSelectedCameraNumbers: {
      type: [Boolean, Array],
      default: false,
    },
    folderInfo: {
      type: Object,
    },
  },
  data() {
    return {
      currentFrame: FRAMES.DIALOG,
      dataErrorsFolder: null,
      selectedCameraNumbers: this.initialSelectedCameraNumbers,
      folderTitle: null,
      subFolderName: "",
      actionForSave:`folders/${ACTION_EDIT_FOLDER}`,
      dataForm: {
        folderTitle: this.folderInfo.name,
        folderId: this.folderInfo.id,
      }
    };
  },
  computed: {
    /**
     * @return {Boolean} Флаг который определяет что можно выбирать несколько камер сразу а не по одной.
     */
    multiSelectableMode() {
      return Array.isArray(this.selectedCameraNumbers);
    },
    /**
     * Компонент для выбранного типа отображения.
     *
     * @return {Component}
     */
    componentView() {
      return _.get(
        {
          [TYPES_VIEWS.TABLE]: ViewTable,
          [TYPES_VIEWS.TILE]: ViewTile,
        },
        this.selectedView,
        ViewTile
      );
    },
    /**
     * @return {Boolean} true если выбрано отображение в виде таблицы.
     */
    isTableView() {
      return this.selectedView === TYPES_VIEWS.TABLE;
    },
  },
  methods: {
    openSubFolderDialog() {
      this.$camsdals.open(
        MeshCreateFolderFrameDialog ,
        {initialSelectedCameraNumbers: [],parentId: this.folderInfo.id},
        {dialogTitle: `Создать подпапку для ${this.folderInfo.name}`},
        {
          size: "vuedal-auto-width vuedal-all-height",
          name: "js-click-outside"
        },
      );
    },
    /**
     * Переключение таба со списком камер.
     * Необходимо вначале загрузить ранее сохраненное состояние, а после обновить его для сохранения открытого таба.
     *
     * @param {String} newTab
     */
    selectTab(newTab) {
      this.selectedTab = newTab;
      this.restoreStateForSelectedTab();
      this.saveState();
      this.isLoadingCommon = true;
      this.loadCameras().then(() => {
        this.isLoadingCommon = false;
      });
    },
    /**
     * Обработка случая когда необходимо показать все результаты работы от компонента поиска {@link CamerasFinder}.
     * Сохраняется поисковый запрос из компонента в хранилище и тем самым регулируется содержимое таба с поиском.
     */
    showAllSearchResults() {
      this.queryTabSearch = this.querySearchCameras;
      this.saveState();
      this.selectTab(TABS.SEARCH);
    },
    /**
     * Клик по конкретной камере из списка. Инициирует закрытие диалога и передачу номера камеры в родительский компонент.
     *
     * @param {Number} cameraNumber
     */
    selectCamera(cameraNumber) {
      if (!this.multiSelectableMode) {
        this.$vuedals.close(cameraNumber);
        return;
      }

      if (this.selectedCameraNumbers.includes(cameraNumber)) {
        this.selectedCameraNumbers.splice(this.selectedCameraNumbers.indexOf(cameraNumber), 1);
      } else {
        this.selectedCameraNumbers.push(cameraNumber);

      }
    },
    async addCamerasToFolder(){
    try{
     await this.$store.dispatch(`folders/${ACTION_ADD_CAMERAS_TO_FOLDER}`, {
        folderId: this.folderInfo.id,
       cameraNumbers: this.selectedCameraNumbers
      });

    } catch{
      this.$camsdals.alert("Ошибка при добавлении камер")
    }

},

    async deleteFolder(){
      this.$camsdals.confirm("Внимание! При удалении папки удалятся все вложенные папки и камеры", async () => {
        this.isLoading = true;
      try{
        await this.$store.dispatch(`folders/${ACTION_DELETE_FOLDER}`, {
          folderId: this.dataForm.folderId,
        });

      } catch{
        this.$camsdals.alert("Ошибка при удалении папки")
      }
        this.closeDialog( this.$root.$emit('folder-created', this.folderInfo.parent_id))
        this.$notify({
          group: "main",
          text: `Папка ${this.folderInfo.name} успешно удалена`,
          duration: 5000,
          type: "success",
        });
        this.isLoading = false;

    });
    },
    async saveDataAndCloseDialog() {

      try {
        await this.addCamerasToFolder()
        await this.saveData();
        this.closeDialog( this.$root.$emit('folder-created', this.dataForm.folderId))
        this.$root.$emit('load-cameras')
        this.$notify({
          group: "main",
          text: `Изменения сохранены `,
          duration: 5000,
          type: "success",
        });

      } catch {
        // Перехват пробрасываемого исключения с ошибками валидации.
      }
    },
  },
};
</script>

<style lang="scss">
@import "./scss/mesh-cameras.scss";
</style>
