<template>
  <form @keyup.enter="saveDataAndRedirect()" @submit.prevent="saveDataAndRedirect()">
    <SmartInputText
      v-model="dataForm.carNumber"
      :error="dataErrors.carNumber"
      caption="Номер автомобиля"
      class="input-row"
      maxlength="9"
    />

    <SmartVSelect
      v-model="dataForm.employeeId"
      :error="dataErrors.employeeId"
      :settings-remote-search="settingsSelectEmployees"
      caption="Владелец автомобиля"
      class="input-row"
      placeholder="Введите ФИО сотрудника"
      @input="getEmployeeCars($event)"
    />

    <div v-if="carsDataRows.length > 0">
      <PacsTable
        :column-captions="carsColumnCaptions"
        :column-names="carsColumnNames"
        :data-rows="carsDataRows"
        :page-count="1"
        :view-only-mode="true"
        table-caption="Существующие автомобили сотрудника"
      />
      <p>
        Показано автомобилей сотрудника: {{ carsDataRows.length }}, всего у него: {{ carsCount }}
      </p>
    </div>

    <div class="buttons-group buttons-group--align-right">
      <CamsButton type="button" @click="closeDialog()">
        Отменить
      </CamsButton>
      <CamsButton priority="primary" type="submit">
        Сохранить
      </CamsButton>
    </div>

    <SpinnerLoadingModal v-if="isLoading" />
  </form>
</template>

<script>
import {ROUTE_PACS_EDIT_CAR} from "@/router/names.js";
import {createEntityMixin} from "@/components/pacs/mixins.js";
import {ACTION_CREATE_CAR, ACTION_LOAD_CARS_FOR_TABLE, CAR_STRUCT} from "@/store/pacs/cars/index.js";
import {ACTION_LOAD_EMPLOYEES_FOR_SELECT, EMPLOYEE_STRUCT} from "@/store/pacs/employees/index.js";
import {makeFilterApi, SPECIAL_TYPES_DATA_IN_CELLS} from "@/store/pacs/helpers.js";
import PacsTable from "@/components/pacs/PacsTable.vue";

/**
 * Компонент диалога создания автомобиля.
 */
export default {
  components: {PacsTable},
  mixins: [createEntityMixin],
  data() {
    return {
      actionForSave: `pacs/cars/${ACTION_CREATE_CAR}`,
      dataForm: {
        carNumber: "",
        employeeId: null,
      },
      settingsSelectEmployees: {
        action: `pacs/employees/${ACTION_LOAD_EMPLOYEES_FOR_SELECT}`,
        valueField: EMPLOYEE_STRUCT.fields.id.name,
        labelField: EMPLOYEE_STRUCT.fields.title.name,
      },
      carsColumnNames: [CAR_STRUCT.fields.number.name],
      carsColumnCaptions: {[CAR_STRUCT.fields.number.name]: CAR_STRUCT.fields.number.title},
      carsDataRows: [],
      carsCount: 0,
    };
  },
  methods: {
    /**
     * Получить перечень автомобилей сотрудника.
     *
     * @param {Number} employeeId
     */
    async getEmployeeCars(employeeId) {
      const carsResponseData = await this.$store.dispatch(`pacs/cars/${ACTION_LOAD_CARS_FOR_TABLE}`, {
        page: 1,
        pageSize: 5,
        filters: [makeFilterApi(CAR_STRUCT.filters.employee_id, "=", employeeId)]
      });
      this.carsCount = carsResponseData.count;
      this.carsDataRows = carsResponseData.results.map((rawCarInfo) => {
        return {
          [CAR_STRUCT.fields.number.name]: {
            type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
            value: rawCarInfo[CAR_STRUCT.fields.number.name],
            params: {name: ROUTE_PACS_EDIT_CAR, params: {carId: rawCarInfo[CAR_STRUCT.key]}},
          },
        };
      });
    },
    /**
     * Перенаправление на страницу редактирования автомобиля.
     *
     * @link saveEntityMixin.methods.getRouteRedirectAfterSave
     */
    getRouteRedirectAfterSave(newEntity) {
      return {name: ROUTE_PACS_EDIT_CAR, params: {carId: newEntity[CAR_STRUCT.fields.id.name]}};
    },
  },
};
</script>
