<template>
  <div>
    <SmartVSelect
      v-model="deviceAccessGroupId"
      :caption="forDelete ? `Выберите группу доступа для удаления из нее сотрудников (${employeeIds.length})` : `Выберите группу доступа для добавления ее к сотрудникам (${employeeIds.length})`"
      :settings-remote-search="settingsSelectDeviceAccessGroup"
      class="input-row"
      placeholder="Введите группу доступа"
    />

    <div class="buttons-group buttons-group--align-right">
      <CamsButton type="button" @click="closeDialog()">
        Отмена
      </CamsButton>
      <CamsButton :disabled="!deviceAccessGroupId" priority="primary" type="button" @click="editEmployeesInDeviceAccessGroup()">
        {{ forDelete ? "Удалить из этой группы" : "Добавить в эту группу" }}
      </CamsButton>
    </div>

    <SpinnerLoadingModal v-if="isLoading" size="s" />
  </div>
</template>

<script>
import {ACTION_ADD_EMPLOYEES_TO_DEVICE_ACCESS_GROUP, ACTION_DELETE_EMPLOYEES_FROM_DEVICE_ACCESS_GROUP} from "@/store/pacs/employees/index.js";
import {methodsForDialogMixin} from "@/components/pacs/mixins.js";
import {ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_SELECT, DEVICE_ACCESS_GROUP_STRUCT} from "@/store/pacs/deviceAccessGroups/index.js";

/**
 * Компонент диалога для добавления/удаления сотрудников к/из группе доступа.
 */
export default {
  mixins: [methodsForDialogMixin],
  props: {
    /**
     * Идентификаторы сотрудников.
     */
    employeeIds: {
      type: Array,
      required: true,
    },
    /**
     * Флаг для переключения режима работы компонента. Если true - удалять сотрудников из группы, false - добавлять в группу.
     */
    forDelete: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      isLoading: false,
      deviceAccessGroupId: null,
      settingsSelectDeviceAccessGroup: {
        action: `pacs/deviceAccessGroups/${ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_SELECT}`,
        valueField: DEVICE_ACCESS_GROUP_STRUCT.fields.id.name,
        labelField: DEVICE_ACCESS_GROUP_STRUCT.fields.title.name,
      },
    };
  },
  methods: {
    /**
     * Отправка запроса на редактирование сотрудников в группе.
     */
    async editEmployeesInDeviceAccessGroup() {
      if (!this.deviceAccessGroupId) {
        return;
      }
      this.isLoading = true;
      try {
        await this.$store.dispatch(
          this.forDelete
            ? `pacs/employees/${ACTION_DELETE_EMPLOYEES_FROM_DEVICE_ACCESS_GROUP}`
            : `pacs/employees/${ACTION_ADD_EMPLOYEES_TO_DEVICE_ACCESS_GROUP}`,
          {
            employeeIds: this.employeeIds,
            deviceAccessGroupId: this.deviceAccessGroupId,
          }
        );
        this.closeDialog(true);
      } catch (error) {
        const errorText = Object.values(error?.fields || {}).join("/r/n");
        this.$camsdals.alert(
          this.forDelete
            ? `Ошибка при удалении сотрудников из группы. ${errorText}`
            : `Ошибка при добавлении сотрудников к группе. ${errorText}`
        );
      }
      this.isLoading = false;
    },
  },
};
</script>
