<template>
  <header class="top-menu">
    <h1 class="top-menu__title">
      {{ title }}
    </h1>

    <div class="top-menu__username">
      <CamsButton
        @click="toggleOptions"
        class="button-settings"
        icon-type="only"
      >
        <svg class="icon icon-settings">
          <use xlink:href="../assets/img/icons.svg#icon-settings" />
        </svg>
        <span class="button-settings__username"> {{ username }}</span>
      </CamsButton>
    </div>
    <!-- Dropdown menu -->
    <div v-if="showDropdown" class="top-menu__dropdown" ref="dropdownMenu">
      <CamsButton
        class="button-account"
        @click="openDialogSetLinksForClients"
      >
        Привязанные аккаунты
      </CamsButton>
      <CamsButton
        class="button-account"
        @click="openDialogGetToken"
      >
        Доступ к аккаунту
      </CamsButton>
      <CamsButton @click="signOut" class="button-exit">
        Выход
      </CamsButton>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import SetLinksForClientsDialog from "@/components/users/SetLinksForClientsDialog.vue";
import GetTokenDialog from "@/components/users/GetTokenDialog.vue";

export default {
  name: "TopMenu",
  data() {
    return {
      showDropdown: false
    };
  },
  computed: {
    ...mapState({
      title: (state) => state.context.title,
      username: (state) => state.username
    }),
    ...mapGetters(["urlSignOut"])
  },
  methods: {
    toggleOptions(event) {
      this.showDropdown = !this.showDropdown;
      if (this.showDropdown) {
        document.addEventListener("click", this.closeDropdownMenu);
      } else {
        document.removeEventListener("click", this.closeDropdownMenu);
      }
      event.stopPropagation();
    },
    openDialogSetLinksForClients() {
      this.$camsdals.open(
        SetLinksForClientsDialog,
        {},
        { dialogTitle: "Настройка связей учетной записи " },
      );
    },
    openDialogGetToken() {
      this.$camsdals.open(
        GetTokenDialog,
        {},
        {},
        {          closeOnBackdrop: false,
          size: "vuedal-auto-width",
          name: "js-click-outside",},
      );
    },
    closeDropdownMenu(event) {
      if (!this.$refs.dropdownMenu.contains(event.target)) {
        this.showDropdown = false;
        document.removeEventListener("click", this.closeDropdownMenu);
      }
    },
    signOut() {
      window.location.href = this.urlSignOut;
    }
  }
};
</script>

<style lang="scss">
@import "./scss/top-menu.scss";
</style>
