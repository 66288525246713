<template>
  <div>
    <div class="main__header">
      <div class="tabs-container" style="padding-top: 30px">
        <router-link :to="{ name: routes.ROUTE_PACS_EVENTS_CARS }" class="tabs-container__tab-item">
          Автомобили
        </router-link>
        <router-link :to="{ name: routes.ROUTE_PACS_EVENTS_THERMALS }" class="tabs-container__tab-item">
          Тепловизоры
        </router-link>
        <router-link :to="{ name: routes.ROUTE_PACS_EVENTS_MOTIONS }" class="tabs-container__tab-item">
          Движения
        </router-link>
        <router-link :to="{ name: routes.ROUTE_PACS_EVENTS_HELMETS }" class="tabs-container__tab-item">
          Каски
        </router-link>
        <router-link :to="{ name: routes.ROUTE_PACS_EVENTS_MASKS }" class="tabs-container__tab-item">
          Маски
        </router-link>
        <router-link :to="{ name: routes.ROUTE_PACS_EVENTS_CROWDS }" class="tabs-container__tab-item">
          Скопление людей
        </router-link>
        <router-link :to="{ name: routes.ROUTE_PACS_EVENTS_PERIMETER_SECURITYS }" class="tabs-container__tab-item">
          Пересечение зоны
        </router-link>
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
import {
  ROUTE_PACS_EVENTS_CARS,
  ROUTE_PACS_EVENTS_MOTIONS,
  ROUTE_PACS_EVENTS_THERMALS,
  ROUTE_PACS_EVENTS_HELMETS,
  ROUTE_PACS_EVENTS_MASKS,
  ROUTE_PACS_EVENTS_CROWDS, ROUTE_PACS_EVENTS_PERIMETER_SECURITYS
} from "@/router/names.js";

export default {
  name: "PacsEventsFrame",
  data() {
    return {
      routes: {
        ROUTE_PACS_EVENTS_CARS,
        ROUTE_PACS_EVENTS_THERMALS,
        ROUTE_PACS_EVENTS_MOTIONS,
        ROUTE_PACS_EVENTS_HELMETS,
        ROUTE_PACS_EVENTS_MASKS,
        ROUTE_PACS_EVENTS_CROWDS,
        ROUTE_PACS_EVENTS_PERIMETER_SECURITYS
      },
    };
  },
};
</script>
