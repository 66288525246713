<template>
  <div class="smart-player-screen">
    <SpinnerLoading v-if="isLoading" class="loader_center" color="blue" />

    <template v-else-if="cameraInfo && (cameraInfo.isReadyForLive || cameraInfo.isAvailableArchive())">
      <div class="main__camera">
        <SmartPlayer
          :archive-token="cameraInfo.tokenDVR"
          :available-archive-fragments="availableArchiveFragments"
          :camera-number="cameraInfo.number"
          :domain="cameraInfo.server.domain"
          :vendor-name="cameraInfo.server.vendor_name"
          :get-line-fragments="getLineFragments"
          :has-sound="cameraInfo.hasSound"
          :http-protocol="$store.getters.protocolVideoOverHTTP"
          :initial-low-latency-mode="cameraInfo.isPtz.is_ptz"
          :initial-time-shift="neededTimeShift"
          :live-token="cameraInfo.tokenLive"
          :has-ptz="cameraInfo.isPtz.is_ptz"
          :max-unix-download-delta="maxUnixDownloadDelta"
          :min-unix-download-delta="minUnixDownloadDelta"
          :stream-count="cameraInfo.streamsCount"
          :ws-protocol="$store.getters.protocolVideoOverWS"
          class="smart-player-screen__player"
          @disaster="setupCamera(cameraInfo.number)"
          @download-video="downloadVideo"
          @time-shift-update="changeTimeShift"
          @archive-mode-update="archiveMode = $event"
          :onPTZStart="debouncedStartPTZ"
          :onPTZStop="stopPTZ"
          :onPTZCentralize="centralizePTZ"

        />
        <!--iframe для скачивания-->
        <iframe :src="downloadUrl" frameborder="0" height="1" width="1" />
      </div>
      <div class="main__panel">
        <div class="panel__header">
          <button
            v-if="analyticsMode"
            :class="{'btn--active': selectedTab === sideTabs.EVENTS}"
            class="btn"
            type="button"
            @click="selectedTab = sideTabs.EVENTS"
          >
            События
          </button>
          <button
            :class="{'btn--active': selectedTab === sideTabs.INFO}"
            class="btn"
            type="button"
            @click="selectedTab = sideTabs.INFO"
          >
            Информация
          </button>
        </div>

        <template v-if="analyticsMode">
          <template v-if="archiveMode">
            <ArchiveEvents
              v-show="selectedTab === sideTabs.EVENTS"
              :archive-from="archiveFrom"
              :archive-to="archiveTo"
              :camera-info="cameraInfo"
              class="panel__body"
              @play-event-start="playEventStart($event, cameraInfo)"
              @show-full-screenshot="showFullScreenshot($event, cameraInfo)"
            />
          </template>
          <template v-else>
            <LiveEvents
              v-show="selectedTab === sideTabs.EVENTS"
              :analytics-settings="analyticsSettings"
              :camera-info="cameraInfo"
              class="panel__body"
              @show-full-screenshot="showFullScreenshot($event, cameraInfo)"
            />
          </template>
        </template>

        <div v-show="selectedTab === sideTabs.INFO" class="panel__body">
          <div class="body__item d-block">
            <p class="camera-info">
              <strong>Камера №:</strong> {{ cameraInfo.number }}
            </p>
            <p class="camera-info">
              <strong>Адрес камеры:</strong> {{ cameraInfo.address }}
            </p>
            <p v-if="cameraInfo.timezone" class="camera-info">
              <strong>{{ cameraInfo.timezoneOffset }}:</strong> {{ cameraInfo.timezone }}
            </p>
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <p>Ошибка: не удалось запустить трансляцию</p>
    </template>
  </div>
</template>

<script>
import SmartPlayer from "camsng-frontend-shared/components/smartPlayer/SmartPlayer.vue";
import {ACTION_GET_DOWNLOAD_TOKEN, ACTION_LOAD_RECORDING_STATUSES, ACTION_PTZ_CAMERA} from "@/store/cameras/index.js";
import {loadCameraInfoMixin} from "@/components/oneScreen/mixins.js";
import LiveEvents from "@/components/oneScreen/LiveEvents.vue";
import ArchiveEvents from "@/components/oneScreen/ArchiveEvents.vue";
import {eventHandlersMixin} from "@/components/events/mixins.js";
import {ACTION_LOAD_ARCHIVE_EVENTS_BY_CAMERA, ACTION_LOAD_ARCHIVE_EVENTS_CHUNKS} from "@/store/analytics/index.js";
import {MUTATION_SET_SHARED_TIME_SHIFT} from "@/store/mutations.js";
import {QUERY_KEY_ONE_SCREEN_TIME_SHIFT} from "@/router/queryKeys.js";
import {MAX_UNIX_DOWNLOAD_DELTA_FOR_PLAYER, MIN_UNIX_DOWNLOAD_DELTA_FOR_PLAYER} from "@/utils/consts.js";

/**
 * Табы бокового меню, содержат блок с общей информацией по камере и блок с отображением информации по событиям аналитики.
 */
const SIDE_TABS = Object.freeze({
  EVENTS: "EVENTS",
  INFO: "INFO",
});

/**
 * Компонент с универсальным плеером для показа прямой трансляции и архива видео с камеры,
 * а так же встроенной боковой панелью, на которой отображаются дополнительные опции показа видео и событий аналитики.
 */
export default {
  name: "SmartPlayerScreen",
  mixins: [
    loadCameraInfoMixin,
    eventHandlersMixin,
  ],
  components: {
    SmartPlayer,
    LiveEvents,
    ArchiveEvents,
  },
  props: {
    /**
     * Начало для воспроизведения архивного видео при открытии плеера.
     */
    initialTimeShift: {
      type: Date,
      default: null,
    },
  },
  data() {
    return {
      availableArchiveFragments: [],
      ptzInterval: null,
      timeoutIdForReceivingRecordingStatus: null,
      downloadUrl: "",
      sideTabs: SIDE_TABS,
      selectedTab: SIDE_TABS.INFO,
      archiveMode: false,
      currentTimeShift: null, // Отслеживаемый сдвиг во времени - необходим для запроса архивных событий.
      // todo требует корректировки в работе временной шкалы либо выносить новый плеер на данный срез
      neededTimeShift: this.initialTimeShift, // Необходимый сдвиг во времени - в случае перехода к конкретному времени (событию) в архиве.
      minUnixDownloadDelta: MIN_UNIX_DOWNLOAD_DELTA_FOR_PLAYER,
      maxUnixDownloadDelta: MAX_UNIX_DOWNLOAD_DELTA_FOR_PLAYER,
    };
  },
  computed: {
    /**
     * @return {Date} Начальный отрезок времени для запроса архивных событий.
     */
    archiveFrom() {
      const calculatedArchiveFrom = new Date(this.currentTimeShift);
      calculatedArchiveFrom.setMinutes(calculatedArchiveFrom.getMinutes() - 5, 0, 0);
      return calculatedArchiveFrom;
    },
    /**
     * @return {Date} Конечный отрезок времени для запроса архивных событий.
     */
    archiveTo() {
      const calculatedArchiveTo = new Date(this.currentTimeShift);
      calculatedArchiveTo.setMinutes(calculatedArchiveTo.getMinutes() + 5, 0, 0);
      return calculatedArchiveTo;
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeoutIdForReceivingRecordingStatus);
  },
  watch: {
    /**
     * Признак того, что маршрут поменялся после нажатия на кнопку,
     * отображаемую при наведении на описание события.
     * Далее следует перевод временного сдвига плеера к точке события.
     *
     * @param {Object} to
     */
    "$route"(to) {
      if (to.params.isClickButtonToPlayEvent) {
        this.neededTimeShift = new Date(to.query[QUERY_KEY_ONE_SCREEN_TIME_SHIFT] - 1000);
      }
    },
    /**
     * Для того, чтобы держать актуальный url, следим за изменением режима просмотра
     * и при переключении на live-режим изменяем url.
     */
    archiveMode(newValue) {
      if (!newValue) {
        this.$router.push({
          name: this.$route.name,
          query: _.omit(this.$route.query, [QUERY_KEY_ONE_SCREEN_TIME_SHIFT])
        });
      }
    }
  },
  created() {
    this.debouncedStartPTZ = _.debounce(this.startPTZ, 0.1);
  },
  methods: {
    /**
     * Сохранить дату, соответствующую метке на временной шкале находясь в режиме просмотра архива.
     * Сохранение происходит ежесекундно поскольку после выбора времени на шкале
     * необходимо постоянно иметь актуальное архивное время при движении метки по шкале.
     *
     * @param {Date} timeShift
     */
    changeTimeShift(timeShift) {
      this.currentTimeShift = timeShift;
      if (this.archiveMode) {
        this.$store.commit(MUTATION_SET_SHARED_TIME_SHIFT, timeShift);
      }
    },
    /**
     * Функция для перекрытия и запуска операций, которые необходимо выполнить после загрузки
     * информации по камере.
     *
     * @see loadCameraInfoMixin.methods.afterLoadCameraInfo
     * @return {Promise}
     */
    async afterLoadCameraInfo() {
      await this.receiveRecordingStatus();
      this.selectedTab = this.analyticsMode ? SIDE_TABS.EVENTS : SIDE_TABS.INFO;
    },
    async sendPTZRequest({ action, code, camera_number }) {
      const payload = {
        action,
        camera_number,
      };
      if (code) {
        payload.code = code;
      }
      await this.$store.dispatch(`cameras/${ACTION_PTZ_CAMERA}`, payload);
    },
    async startPTZ(direction) {
      await this.sendPTZRequest({ action: 'start', code: direction, camera_number: this.cameraInfo.number });
    },
    async stopPTZ() {
      clearInterval(this.ptzInterval);
      await this.sendPTZRequest({ action: 'stop', code: '', camera_number:this.cameraInfo.number });
    },
    async centralizePTZ() {
      await this.sendPTZRequest({ action: 'centralize', code: '', camera_number: this.cameraInfo.number});
    },
  /**
     * Получение доступных фрагментов видео от сервера. Периодический ее вызов для актуализации данных в плеере.
     */
    async receiveRecordingStatus() {
      if (!this.cameraInfo.isAvailableArchive()) {
        return;
      }

      this.availableArchiveFragments = await this.$store.dispatch(`cameras/${ACTION_LOAD_RECORDING_STATUSES}`, {
        cameraNumber: this.cameraInfo.number,
        domain: this.cameraInfo.server.domain,
        token: this.cameraInfo.tokenDVR,
      });
      this.timeoutIdForReceivingRecordingStatus = setTimeout(this.receiveRecordingStatus, 7000);
    },
    /**
     * Отправка запроса на получение токена для скачивания фрагмента видео.
     * При его получении формирование URL и автоматический запуск скачивания.
     *
     * @param {Function} getterDownloadUrl
     * @param {Number} unixDownloadFrom
     * @param {Number} unixDownloadDelta
     */
    async downloadVideo([getterDownloadUrl, unixDownloadFrom, unixDownloadDelta]) {
      // Конкретные ограничения по длительности запрашиваемого видео.
      if ((unixDownloadDelta < MIN_UNIX_DOWNLOAD_DELTA_FOR_PLAYER) || (unixDownloadDelta > MAX_UNIX_DOWNLOAD_DELTA_FOR_PLAYER)) {
        this.$camsdals.alert("Невозможно скачать. Выбран некорректный диапазон времени, попробуйте изменить границы скачивания.");
        return;
      }

      const tokenDownload = await this.$store.dispatch(`cameras/${ACTION_GET_DOWNLOAD_TOKEN}`, {
        number: this.cameraInfo.number,
        tokenDownloadDuration: unixDownloadDelta,
        tokenDownloadStart: unixDownloadFrom,
      });
      if (!tokenDownload) {
        this.$camsdals.alert("Невозможно скачать. Попробуйте позднее.");
        return;
      }

      this.downloadUrl = getterDownloadUrl(tokenDownload);
    },
    /**
     * Функция должна вернуть актуальный набор данных для отображения на временной шкале.
     * Для доступного архива фильтруются доступные участки для рисования, уточняется их цвет и низший приоритет.
     * Для событий - они запрашиваются из API и преобразуются из объектов сообщений в простую структуру для выгрузки.
     *
     * @param {Date} leftBoundary
     * @param {Date} rightBoundary
     * @return {Promise<Array<Array<Date, Date, String, Number>>>}
     */
    async getLineFragments(leftBoundary, rightBoundary) {
      const availableArchiveFragments = this.availableArchiveFragments.filter(([startDate, endDate]) => {
        return +leftBoundary < +endDate && +rightBoundary > +startDate;
      }).map(([startDate, endDate]) => {
        return [startDate, endDate, "#bbbbbb", 0]; // Приоритет 0 = Архив.
      });
      if (!this.analyticsMode) {
        // Камеры без рабочих аналитик показывают только доступных архив на шкале.
        return availableArchiveFragments;
      }

      let archiveEventsFragments = [];
      if ((rightBoundary - leftBoundary) > 86400000) {
        // На шкале больше суток.
        const archiveEventsChunks = await this.$store.dispatch(`analytics/${ACTION_LOAD_ARCHIVE_EVENTS_CHUNKS}`, {
          cameraNumber: this.cameraInfo.number,
          archiveFrom: leftBoundary,
          archiveTo: rightBoundary,
        });
        archiveEventsFragments = archiveEventsChunks.map((chunk) => {
          return [chunk.startDate, chunk.endDate, chunk.color, chunk.priority];
        });

        // Обработка случая когда смотрим на live а чанков за этот период еще не собрано (обычно за последний час нет чанков),
        // тогда берется следующее событие и из его времени создается псевдо чанк длиной до конца архива.
        const lastChunk = archiveEventsChunks[0];
        if (lastChunk) {
          const archiveEventsNotInChunk = await this.$store.dispatch(`analytics/${ACTION_LOAD_ARCHIVE_EVENTS_BY_CAMERA}`, {
                  cameraNumber: this.cameraInfo.number,
                  archiveFrom: new Date(+lastChunk.endDate + 1000),
                  archiveTo: rightBoundary,
                  limit: 1,
                  orderByDate: "asc"
                }),
                messageNotInChunk = archiveEventsNotInChunk[0];
          if (messageNotInChunk) {
            archiveEventsFragments.push([messageNotInChunk.startDate, rightBoundary, messageNotInChunk.color, messageNotInChunk.priority]);
          }
        }
      } else {
        // На шкале меньше суток.
        const archiveEvents = await this.$store.dispatch(`analytics/${ACTION_LOAD_ARCHIVE_EVENTS_BY_CAMERA}`, {
          cameraNumber: this.cameraInfo.number,
          archiveFrom: leftBoundary,
          archiveTo: rightBoundary,
        });
        archiveEventsFragments = archiveEvents.map((message) => {
          return [message.startDate, message.endDate, message.color, message.priority];
        });
      }

      return [...availableArchiveFragments, ...archiveEventsFragments];
    },
  }
};
</script>
