<template>
  <div class="settings-access">
    <div v-if="accountsInfo.length > 0" class="settings-access__content">
      <div class="settings-access__title">
        Учетная запись привязана к : {{ linkCount }}
      </div>
      <div
        v-for="(accountInfo, rowIndex) in accountsInfo"
        :key="`row-${rowIndex}`"
        class="access-container__list-item"
      >
        {{ accountInfo.username }}
        <CamsButton
          class="settings-access__button-delete-field"
          icon-type="only"
          type="button"
          @click="deleteParentLink(accountInfo.parentId)"
        >
          <svg class="icon-close">
            <use xlink:href="#icon-delete" />
          </svg>
        </CamsButton>
      </div>
    </div>
    <SpinnerLoadingModal v-if="isLoading" />
  </div>
</template>

<script>

import {
  ACTION_DELETE_PARENT_LINKS,
  ACTION_LOAD_PARENT_LINKS,
  FIELDS_USER_LINK
} from "@/store/users/index.js";

/**
 * Компонент отображения и удаления родительских связей между учетными записями.
 */

export default {
  data() {
    return {
      accountsInfo: [],
      linkCount: null,
    };
  },
  created() {
    this.loadParentLinks();
  },
  methods: {
    /**
     * Загрузка списка родительских связей.
     */
    async loadParentLinks() {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch(`users/${ACTION_LOAD_PARENT_LINKS}`);
        this.linkCount = response.count;
        this.accountsInfo = response.results.map((rawUserLinkInfo) => {
          return {
            username: rawUserLinkInfo[FIELDS_USER_LINK.username],
            date: rawUserLinkInfo[FIELDS_USER_LINK.date],
            parentId: rawUserLinkInfo[FIELDS_USER_LINK.user_id],
          };
        });
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Отправка запроса на удаление родительской связи между учетными записями.
     */
    async deleteParentLink(parentId) {
      this.$camsdals.confirm("Удалить связь с учетной записью?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`users/${ACTION_DELETE_PARENT_LINKS}`, parentId);
        } catch (error) {
          this.$camsdals.alert(error);
        }
        await this.loadParentLinks();
        this.$root.$emit('load-cameras')
        this.isLoading = false;
      });
    },
  }
};
</script>
