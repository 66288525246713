import {ANALYTICS} from "@/store/analytics/helpers.js";
import {CameraInfo} from "@/store/cameras/index.js";

/**
 * Перечень цветов на шкале по аналитикам.
 */
export const ANALYTICS_COLORS = Object.freeze({
  [ANALYTICS.thermal_vision]: "#FC4741",
  [ANALYTICS.car_number]: "#FD7923",
  [ANALYTICS.face_recognition]: "#2C86F1",
  [ANALYTICS.helmet]: "#F623FD",
  [ANALYTICS.motion_alarm]: "#FC4741",
  [ANALYTICS.mask]: "#41FC4AFF",
  [ANALYTICS.crowd]: "#FCD741",
  [ANALYTICS.perimeter_security]: "#886650",
  [ANALYTICS.people_counter]: "#11969a",
});

/**
 * Перечень приоритетов по аналитикам.
 */
export const ANALYTICS_PRIORITIES = Object.freeze({
  [ANALYTICS.thermal_vision]: 40,
  [ANALYTICS.car_number]: 70,
  [ANALYTICS.face_recognition]: 60,
  [ANALYTICS.helmet]: 30,
  [ANALYTICS.motion_alarm]: 50,
  [ANALYTICS.mask]: 25,
  [ANALYTICS.crowd]: 20,
  [ANALYTICS.perimeter_security]: 70,
  [ANALYTICS.people_counter]: 55,
});

/**
 * Интерфейсный класс для сообщений и чанков сообщений по аналитике.
 */
export class CommonAnalyticInfo {
  constructor(analyticMessageOrChunk) {
    this.date = new Date(analyticMessageOrChunk.date); // Дата начала событий.
    const analyticType = analyticMessageOrChunk.type; // Тип используемой аналитики в событиях.
    if (!(analyticType in ANALYTICS)) {
      throw new Error(`Analytic type is undefined - ${analyticType}`);
    }
    this.analyticType = analyticType;
    this.count = analyticMessageOrChunk.count; // Количество событий.
  }

  /**
   * @return {Date} Дата начала события.
   */
  get startDate() {
    return this.date;
  }

  /**
   * @return {Date} Дата завершения события.
   */
  get endDate() {
    return new Date(+this.date + 1000);
  }

  /**
   * @return {String} Цвет для рисования линии на временной шкале плеера.
   */
  get color() {
    return ANALYTICS_COLORS[this.analyticType];
  }

  /**
   * @return {Number} Приоритет события.
   */
  get priority() {
    return ANALYTICS_PRIORITIES[this.analyticType];
  }
}

/**
 * Класс для представления сообщения поступившего из какой-то аналитики.
 * Принимает ограниченный набор полей для сообщений аналитик которые поступают по разным каналам (архивный или SSE).
 */
export class AnalyticMessage extends CommonAnalyticInfo {
  constructor(rawAnalyticMessage) {
    // В новых (поступающих по SSE) сообщениях не передается дата, в этом случае она ставится как текущая,
    // но в архивных она имеется.
    rawAnalyticMessage.date = rawAnalyticMessage.date ? new Date(rawAnalyticMessage.date) : new Date();
    super(rawAnalyticMessage);
    this.id = rawAnalyticMessage.id;
    this.text = rawAnalyticMessage.text;
    this.cameraNumber = rawAnalyticMessage.camera_number; // Только для новых поступающих по SSE.
    this.duration = 5;
    this._cameraInfo = null;
  }

  /**
   * @param {CameraInfo} cameraInfo
   */
  set cameraInfo(cameraInfo) {
    if (cameraInfo instanceof CameraInfo) {
      this._cameraInfo = cameraInfo;
    }
  }

  /**
   * @return {Date} Дата завершения события.
   */
  get endDate() {
    return new Date(+this.date + (this.duration * 1000));
  }

  /**
   * @return {CameraInfo} Экземпляр камеры связанный с сообщением
   */
  get cameraInfo() {
    return this._cameraInfo;
  }

  /**
   * @return {String} Вернет строку с суммарной информации о событии.
   */
  get summarize() {
    return _.filter([moment.tz(this.date, moment.tz.guess()).format("DD.MM.YYYY HH:mm:ss"), this.text]).join(" | ");
  }
}

/**
 * Класс для представления количества сообщения на определенном временном промежутке.
 */
export class AnalyticMessagesChunk extends CommonAnalyticInfo {
  constructor(rawAnalyticMessagesChunk) {
    super(rawAnalyticMessagesChunk);
    this._endDate = new Date(rawAnalyticMessagesChunk.end); // Дата завершения событий.
    this.count = rawAnalyticMessagesChunk.count; // Количество событий.
  }

  /**
   * @return {Date} Дата завершения событий в чанке.
   */
  get endDate() {
    return this._endDate;
  }
}

/**
 * Класс для представления сообщения поступившего из аналитики по тепловизору.
 */
export class ThermalVisionMessage extends AnalyticMessage {
  constructor(rawThermalVisionMessage) {
    super(rawThermalVisionMessage);
    this.temperature = rawThermalVisionMessage.temperature;
    this.alarm = rawThermalVisionMessage.alarm;
    this.faceScreenshotBase64 = rawThermalVisionMessage.face_screenshot; // Доступен только для SSE.
    this.faceScreenshotUrl = rawThermalVisionMessage.face_screenshot_url;
    this.fullScreenshotUrl = rawThermalVisionMessage.full_screenshot_url;
  }

  /**
   * Вернет URL до скриншота события маленького размера. Для доступа к скриншоту нужен любой токен из камеры.
   *
   * @param {String} anyToken
   * @return {String}
   */
  getFaceScreenshotUrl(anyToken) {
    return this.faceScreenshotBase64 || (this.faceScreenshotUrl && `${this.faceScreenshotUrl}?token=${anyToken}`) || "";
  }

  /**
   * Вернет URL до полноразмерного скриншота события. Для доступа к скриншоту нужен любой токен из камеры.
   *
   * @param {String} anyToken
   * @return {String}
   */
  getFullScreenshotUrl(anyToken) {
    return (this.fullScreenshotUrl && `${this.fullScreenshotUrl}?token=${anyToken}`) || "";
  }
}

/**
 * Подписи к автомобилям относящимся к классу спецтранспорт.
 */
const EMERGENCY_VEHICLES = Object.freeze({
  "fire": "Пожарная",
  "police": "Полиция",
  "ambulance": "Скорая",
  "mcs": "МЧС",
});

/**
 * Класс для представления сообщения поступившего из аналитики по автомобильным номерам.
 */
export class CarNumberMessage extends AnalyticMessage {
  constructor(rawCarNumberMessage) {
    super(rawCarNumberMessage);
    this.number = rawCarNumberMessage.number;
    this.access = rawCarNumberMessage.access;
    this.plateScreenshotBase64 = rawCarNumberMessage.plate_screenshot; // Доступен только для SSE.
    this.plateScreenshotUrl = rawCarNumberMessage.plate_screenshot_url;
    this.fullScreenshotUrl = rawCarNumberMessage.full_screenshot_url;
    this.deviceId = rawCarNumberMessage.device_id; // Доступно для отчетов аналитики в СКУД.
    this.employeeId = rawCarNumberMessage.employee_id; // Доступно для отчетов аналитики в СКУД.
    this.transportClass = rawCarNumberMessage.transport_class; // Доступно только для SSE.
    this.emergencyVehicleLight = rawCarNumberMessage.emergency_vehicle_light; // Доступно только для SSE.
    this.confidence = rawCarNumberMessage.confidence; // Доступно только для SSE.
  }

  get summarize() {
    return _.filter([moment.tz(this.date, moment.tz.guess()).format("DD.MM.YYYY HH:mm:ss"), this.number, this.titleIfEmergencyVehicle, this.text]).join(" | ");
  }

  /**
   * Вернет URL до скриншота события маленького размера. Для доступа к скриншоту нужен любой токен из камеры.
   *
   * @param {String} anyToken
   * @return {String}
   */
  getPlateScreenshotUrl(anyToken) {
    return this.plateScreenshotBase64 || (this.plateScreenshotUrl && `${this.plateScreenshotUrl}?token=${anyToken}`) || "";
  }

  /**
   * Вернет URL до полноразмерного скриншота события. Для доступа к скриншоту нужен любой токен из камеры.
   *
   * @param {String} anyToken
   * @return {String}
   */
  getFullScreenshotUrl(anyToken) {
    return (this.fullScreenshotUrl && `${this.fullScreenshotUrl}?token=${anyToken}`) || "";
  }

  /**
   * @return {String} Вернет строку обозначение спецтранспорта. Но из-за ошибок распознавания лучше не уточнять что именно за машина приехала.
   */
  get titleIfEmergencyVehicle() {
    return this.transportClass && this.transportClass in EMERGENCY_VEHICLES ? "Спецтранспорт" : "";
  }
}

/**
 * Класс для представления сообщения поступившего из аналитики по распознаванию лиц.
 */
export class FaceRecognitionMessage extends AnalyticMessage {
  constructor(rawFaceRecognitionMessage) {
    super(rawFaceRecognitionMessage);
    this.access = rawFaceRecognitionMessage.access;
    this.faceScreenshotBase64 = rawFaceRecognitionMessage.face_screenshot; // Доступен только для SSE.
    this.faceScreenshotUrl = rawFaceRecognitionMessage.face_screenshot_url;
    this.fullScreenshotUrl = rawFaceRecognitionMessage.full_screenshot_url;
  }

  /**
   * Вернет URL до скриншота события маленького размера. Для доступа к скриншоту нужен любой токен из камеры.
   *
   * @param {String} anyToken
   * @return {String}
   */
  getFaceScreenshotUrl(anyToken) {
    return this.faceScreenshotBase64 || (this.faceScreenshotUrl && `${this.faceScreenshotUrl}?token=${anyToken}`) || "";
  }

  /**
   * Вернет URL до полноразмерного скриншота события. Для доступа к скриншоту нужен любой токен из камеры.
   *
   * @param {String} anyToken
   * @return {String}
   */
  getFullScreenshotUrl(anyToken) {
    return (this.fullScreenshotUrl && `${this.fullScreenshotUrl}?token=${anyToken}`) || "";
  }
}

/**
 * Класс для представления сообщения поступившего из аналитики определения касок.
 */
export class HelmetMessage extends AnalyticMessage {
  constructor(rawHelmetMessage) {
    super(rawHelmetMessage);
    this.headScreenshotBase64 = rawHelmetMessage.head_screenshot;
    this.headScreenshotUrl = rawHelmetMessage.head_screenshot_url;
    this.fullScreenshotUrl = rawHelmetMessage.full_screenshot_url;
  }
  /**
   * Вернет URL до скриншота события маленького размера. Для доступа к скриншоту нужен любой токен из камеры.
   *
   * @param {String} anyToken
   * @return {String}
   */
  getHeadScreenshotUrl(anyToken) {
    return this.headScreenshotBase64 || (this.headScreenshotUrl && `${this.headScreenshotUrl}?token=${anyToken}`) || "";
  }

  /**
   * Вернет URL до полноразмерного скриншота события. Для доступа к скриншоту нужен любой токен из камеры.
   *
   * @param {String} anyToken
   * @return {String}
   */
  getFullScreenshotUrl(anyToken) {
    return (this.fullScreenshotUrl && `${this.fullScreenshotUrl}?token=${anyToken}`) || "";
  }
}
/**
 * Класс для представления сообщения поступившего из аналитики определения масок.
 */
export class MaskMessage extends AnalyticMessage {
  constructor(rawMaskMessage) {
    super(rawMaskMessage);
    this.maskScreenshotBase64 = rawMaskMessage.mask_screenshot;
    this.maskScreenshotUrl = rawMaskMessage.mask_screenshot_url;
    this.fullScreenshotUrl = rawMaskMessage.full_screenshot_url;
  }
  /**
   * Вернет URL до скриншота события маленького размера. Для доступа к скриншоту нужен любой токен из камеры.
   *
   * @param {String} anyToken
   * @return {String}
   */
  getMaskScreenshotUrl(anyToken) {
    return this.maskScreenshotBase64 || (this.maskScreenshotUrl && `${this.maskScreenshotUrl}?token=${anyToken}`) || "";
  }

  /**
   * Вернет URL до полноразмерного скриншота события. Для доступа к скриншоту нужен любой токен из камеры.
   *
   * @param {String} anyToken
   * @return {String}
   */
  getFullScreenshotUrl(anyToken) {
    return (this.fullScreenshotUrl && `${this.fullScreenshotUrl}?token=${anyToken}`) || "";
  }
}
/**
 * Класс для представления сообщения поступившего из аналитики вторжения в зону.
 */
export class PerimeterSecurityMessage extends AnalyticMessage {
  constructor(rawPerimeterSecurityMessage) {
    super(rawPerimeterSecurityMessage);

    this.perimeterScreenshotBase64 = rawPerimeterSecurityMessage.event_screenshot;
    this.perimeterScreenshotUrl = rawPerimeterSecurityMessage.event_screenshot_url;
    this.fullScreenshotUrl = rawPerimeterSecurityMessage.full_screenshot_url;
  }
  /**
   * Вернет URL до скриншота события маленького размера. Для доступа к скриншоту нужен любой токен из камеры.
   *
   * @param {String} anyToken
   * @return {String}
   */
  getPerimeterSecurityScreenshotUrl(anyToken) {
    return this.perimeterScreenshotBase64 || (this.perimeterScreenshotUrl && `${this.perimeterScreenshotUrl}?token=${anyToken}`) || "";
  }

  /**
   * Вернет URL до полноразмерного скриншота события. Для доступа к скриншоту нужен любой токен из камеры.
   *
   * @param {String} anyToken
   * @return {String}
   */
  getFullScreenshotUrl(anyToken) {
    return (this.fullScreenshotUrl && `${this.fullScreenshotUrl}?token=${anyToken}`) || "";
  }
}
/**
 * Класс для представления сообщения поступившего из аналитики подсчета посетителей.
 */
export class PeopleCountMessage extends AnalyticMessage {
  constructor(rawPeopleCountMessage) {
    super(rawPeopleCountMessage);
  }
}
/**
 * Класс для представления сообщения поступившего из аналитики определения толпы.
 */
export class CrowdMessage extends AnalyticMessage {
  constructor(rawCrowdMessage) {
    super(rawCrowdMessage);
    this.crowdScreenshotBase64 = rawCrowdMessage.crowd_screenshot;
    this.crowdScreenshotUrl = rawCrowdMessage.crowd_screenshot_url;
    this.fullScreenshotUrl = rawCrowdMessage.full_screenshot_url;
  }
  /**
   * Вернет URL до скриншота события маленького размера. Для доступа к скриншоту нужен любой токен из камеры.
   *
   * @param {String} anyToken
   * @return {String}
   */
  getCrowdScreenshotUrl(anyToken) {
    return this.crowdScreenshotBase64 || (this.crowdScreenshotUrl && `${this.crowdScreenshotUrl}?token=${anyToken}`) || "";
  }

  /**
   * Вернет URL до полноразмерного скриншота события. Для доступа к скриншоту нужен любой токен из камеры.
   *
   * @param {String} anyToken
   * @return {String}
   */
  getFullScreenshotUrl(anyToken) {
    return (this.fullScreenshotUrl && `${this.fullScreenshotUrl}?token=${anyToken}`) || "";
  }
}


/**
 * Класс для представления сообщения поступившего из аналитики по распознаванию движения.
 *
 * Только для новых поступающих по SSE.
 * is_new - Если событие новое - true, если старое - false + с обновленной длительностью.
 * calculated_length - Длительность события.
 * Только для архивных.
 * length - Длительность события.
 */
export class MotionAlarmMessage extends AnalyticMessage {
  constructor(rawMotionAlarmMessage) {
    super(rawMotionAlarmMessage);
    this.duration = rawMotionAlarmMessage.length || rawMotionAlarmMessage.calculated_length;
    this.continues = rawMotionAlarmMessage.is_new === false;
  }
}

/**
 * Фабрика по созданию экземпляров сообщений.
 *
 * @param {String|Object} rawEventData
 * @param {String} cameraNumber
 * @param {String} specificAnalyticType
 * @return {AnalyticMessage}
 */
export default function makeMessage(rawEventData, cameraNumber = null, specificAnalyticType = null) {
  // Сериализованные сообщения поступают по SSE, чистые JSON по API архивных сообщений.
  const rawMessage = _.isString(rawEventData) ? JSON.parse(rawEventData) : rawEventData,
    analyticType = specificAnalyticType ?? rawMessage.type,
    messageClass = {
      [ANALYTICS.thermal_vision]: ThermalVisionMessage,
      [ANALYTICS.car_number]: CarNumberMessage,
      [ANALYTICS.face_recognition]: FaceRecognitionMessage,
      [ANALYTICS.helmet]: HelmetMessage,
      [ANALYTICS.motion_alarm]: MotionAlarmMessage,
      [ANALYTICS.mask]: MaskMessage,
      [ANALYTICS.crowd]: CrowdMessage,
      [ANALYTICS.perimeter_security]: PerimeterSecurityMessage,
      [ANALYTICS.people_counter]: PeopleCountMessage,
    }[analyticType];
  if (!messageClass) {
    throw new Error(`Analytic class is undefined - ${analyticType}`);
  }

  if (cameraNumber) {
    // Номер камеры может отсутствовать в сообщениях API, т.к. часто запросы к API по сообщениям предусматривают собственно камеру,
    // поэтому подмешиваем камеру так как если бы она пришла через SSE.
    rawMessage.camera_number = cameraNumber;
  }
  if (!rawMessage.type) {
    // Тип события подмешивается если внутри тела сообщения был не определен.
    rawMessage.type = analyticType;
  }

  return new messageClass(rawMessage);
}
