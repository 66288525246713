<template>
  <div class="main__content content">
    <div class="pacs-settings-table">
      <CamerasFinder
        v-model="searchText"
        style="margin-bottom: 20px"
        :selectable-only-mode="true"
        @select-camera="selectCamera"
      />
    </div>
    <SpinnerLoadingModal v-if="isLoading" />
    <SpinnerLoading v-if="isLoadingTable" class="loader_center" color="blue" />
    <PacsTable
      v-else
      :column-captions="columnCaptions"
      :column-names="columnNames"
      :current-page="currentPage"
      :data-rows="dataRows"
      :key-field="keyField"
      :page-count="pageCount"
      :total-count="totalCount"
      :off-check-box="offCheckBox"
      @new-page="selectPage"
    />
  </div>
</template>

<script>
import {
  ACTION_LOAD_CAMERAS_SETUP_FOR_TABLE,
  CAMERA_SETUP_STRUCT
} from "@/store/setup/index.js";
import PacsTable from "@/components/pacs/PacsTable.vue";
import {entityTableMixin} from "@/components/pacs/mixins.js";
import {ROUTE_SETUP_EDIT_CAMERA} from "@/router/names.js";
import CamerasFinder from "@/components/meshCameras/CamerasFinder.vue";

/**
 * Компонент отображения таблицы и фильтров с информацией по камерам с настройкой.
 */
export default {
  mixins: [
    entityTableMixin
  ],
  components: {
    PacsTable,
    CamerasFinder
  },
  data() {
    return {
      entityStruct: CAMERA_SETUP_STRUCT,
      offCheckBox: true,
      entityFields: [
        CAMERA_SETUP_STRUCT.fields.number,
        CAMERA_SETUP_STRUCT.fields.title,
        CAMERA_SETUP_STRUCT.fields.tariff,
        CAMERA_SETUP_STRUCT.fields.address,
      ],
    };
  },
  /**
   * Выбор камеры в строке поиска
   */
  methods: {
    selectCamera(cameraNumber) {
      this.$router.push({name: ROUTE_SETUP_EDIT_CAMERA, params: {cameraNumber}});
    },
    async loadPage() {
      this.isLoadingTable = true;
      try {
        const responseData = await this.$store.dispatch(`setup/${ACTION_LOAD_CAMERAS_SETUP_FOR_TABLE}`, {
          page: this.currentPage,
          search: this.searchText,
          orderBy: this.sortInfoForApi,
          filters: this.filters,
        });
        this.pageCount = responseData.page.all;
        this.totalCount = responseData.count;
        this.dataRows = responseData.results.map((rawCameraInfo) => {
          const paramsRouteEditCamera = {name: ROUTE_SETUP_EDIT_CAMERA, params: {cameraNumber: rawCameraInfo[CAMERA_SETUP_STRUCT.key]}};
          return {
            ...this.calcDefaultDataRow(rawCameraInfo),
            [CAMERA_SETUP_STRUCT.fields.number.name]: {
              type: CAMERA_SETUP_STRUCT.fields.number.type,
              value: rawCameraInfo[CAMERA_SETUP_STRUCT.fields.number.name],
              params: paramsRouteEditCamera,
            },
            [CAMERA_SETUP_STRUCT.fields.title.name]: {
              type: CAMERA_SETUP_STRUCT.fields.title.type,
              value: rawCameraInfo[CAMERA_SETUP_STRUCT.fields.title.name],
            },
            [CAMERA_SETUP_STRUCT.fields.tariff.name]: {
              type: CAMERA_SETUP_STRUCT.fields.tariff.type,
              value: rawCameraInfo.tariff.name
            },
          };
        });
      } finally {
        this.isLoadingTable = false;
      }
    },
  }
};
</script>
