/**
 * Отдельный vuex модуль для взаимодействия по employees-API
 */
import {
  DEFAULT_PAGE_SIZE_FOR_SELECT,
  DEFAULT_PAGE_SIZE_FOR_TABLE,
  getExtraApi,
  makeFilterApi,
  reformatErrorsForm,
  SPECIAL_TYPES_DATA_IN_CELLS
} from "@/store/pacs/helpers.js";
import {ACTION_LOAD_ENTITIES_FOR_MULTI_EDIT} from "@/store/pacs/actions.js";

// actions для employees. Вызов действия начинать с "pacs/employees/"
export const ACTION_LOAD_EMPLOYEES = "LOAD_EMPLOYEES";
export const ACTION_LOAD_EMPLOYEES_FOR_TABLE = "LOAD_EMPLOYEES_FOR_TABLE";
export const ACTION_LOAD_EMPLOYEES_FOR_CSV = "LOAD_EMPLOYEES_FOR_CSV";
export const ACTION_LOAD_EMPLOYEES_FOR_SELECT = "LOAD_EMPLOYEES_FOR_SELECT";
export const ACTION_LOAD_EMPLOYEE_FOR_EDIT = "LOAD_EMPLOYEE_FOR_EDIT";
export const ACTION_LOAD_EMPLOYEES_FOR_MULTI_EDIT = "LOAD_EMPLOYEES_FOR_MULTI_EDIT";
export const ACTION_CREATE_EMPLOYEE = "CREATE_EMPLOYEE";
export const ACTION_CREATE_EMPLOYEE_FROM_FILE = "CREATE_EMPLOYEE_FROM_FILE";
export const ACTION_GET_TEMPLATE_FILE = "GET_TEMPLATE_FILE";
export const ACTION_EDIT_EMPLOYEE = "EDIT_EMPLOYEE";
export const ACTION_DELETE_EMPLOYEES = "DELETE_EMPLOYEES";
export const ACTION_ADD_EMPLOYEES_TO_DEVICE_ACCESS_GROUP = "ADD_EMPLOYEES_TO_DEVICE_ACCESS_GROUP";
export const ACTION_DELETE_EMPLOYEES_FROM_DEVICE_ACCESS_GROUP = "DELETE_EMPLOYEES_FROM_DEVICE_ACCESS_GROUP";

/**
 * Структура для общения с API по конкретной сущности.
 */
export const EMPLOYEE_STRUCT = Object.freeze({
  key: "id",
  fields: {
    id: {
      name: "id",
      title: "ID",
      type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
      sort: true,
    },
    title: {
      name: "title",
      title: "Имя",
      type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
      sort: true,
    },
    device_access_group_count: {
      name: "device_access_group_count",
      title: "Количество групп доступа к устройствам",
      extra: "device_access_group",
    },
    attached_usernames: {
      name: "attached_usernames",
      title: "Учетные записи",
    },
    device_access_group_ids: {
      name: "device_access_group_ids",
      title: "Группы доступа",
      type: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
      extra: "device_access_group",
    },
    car_count: {
      name: "car_count",
      title: "Автомобили",
      type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
      extra: "car",
    },
    car_ids: {
      name: "car_ids",
      title: "Автомобили",
      type: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
      extra: "car",
    },
    is_active: {
      name: "is_active",
      title: "Активен",
      type: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
      sort: true,
    },
  },
  extras: {
    device_access_group: "device_access_group",
    car: "car",
  },
  filters: {
    device_access_group_id: "device_access_group_id",
  },
});

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка списка сотрудников.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_EMPLOYEES](context, {page, pageSize, orderBy, filters, search}) {
      const fields = Object.keys(EMPLOYEE_STRUCT.fields),
        response = await this.getters.ajaxClient.post("/v0/employees/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra: getExtraApi(EMPLOYEE_STRUCT, fields),
          search
        });
      return response.data;
    },
    /**
     * Загрузка списка сотрудников для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_EMPLOYEES_FOR_TABLE]({dispatch}, {
      page = 1,
      pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE,
      orderBy = [],
      filters = [],
      search = ""
    }) {
      return dispatch(ACTION_LOAD_EMPLOYEES, {page, pageSize, orderBy, filters, search});
    },
    async [ACTION_LOAD_EMPLOYEES_FOR_CSV]({dispatch}, {
      page = 1,
      pageSize = 1000,
      orderBy = [],
      filters = [],
      search = ""
    }) {
      return dispatch(ACTION_LOAD_EMPLOYEES, {page, pageSize, orderBy, filters, search});
    },
    /**
     * Загрузка списка сотрудников для отображения в селекте.
     *
     * @param {Function} dispatch
     * @param {Number} pageSize
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_EMPLOYEES_FOR_SELECT]({dispatch}, {pageSize = DEFAULT_PAGE_SIZE_FOR_SELECT, search}) {
      const responseData = await dispatch(ACTION_LOAD_EMPLOYEES, {pageSize, search});
      return responseData.results;
    },
    /**
     * Загрузка одного сотрудника для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} employeeId
     * @return {Promise}
     */
    async [ACTION_LOAD_EMPLOYEE_FOR_EDIT]({dispatch}, employeeId) {
      const responseData = await dispatch(ACTION_LOAD_EMPLOYEES, {
        page: 1,
        pageSize: 1,
        filters: [makeFilterApi(EMPLOYEE_STRUCT.key, "=", employeeId)]
      });
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Загрузка ключевых данных сотрудников для множественного редактирования.
     *
     * @param {Function} dispatch
     * @param {Array} filters
     * @param {String} search
     * @return {Promise.<Array.<Number>>}
     */
    async [ACTION_LOAD_EMPLOYEES_FOR_MULTI_EDIT]({dispatch}, {filters, search}) {
      return dispatch(`pacs/${ACTION_LOAD_ENTITIES_FOR_MULTI_EDIT}`, {
        apiUrl: "/v0/employees/",
        keyField: EMPLOYEE_STRUCT.key,
        dataForApi: {filters, search},
      }, {root: true});
    },
    /**
     * Создание сотрудника.
     *
     * @param {Object} context
     * @param {String} title
     * @param {Boolean} isActive
     * @param {Array.<String>} carNumbers
     * @param {Array.<Number>} deviceAccessGroupIds
     * @return {Promise}
     */
    async [ACTION_CREATE_EMPLOYEE](context, {title, isActive, carNumbers, deviceAccessGroupIds,attachedUsernames}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/employees/create/", {
          title,
          is_active: isActive,
          cars: carNumbers.map(carNumber => ({"number": carNumber.toLocaleUpperCase()})),
          attached_usernames: attachedUsernames,
          device_access_group_ids: deviceAccessGroupIds,
        });
        return response.data;
      } catch (error) {
        const apiDataErrors = reformatErrorsForm(error.response.data.fields),
          dataErrors = {
            title: apiDataErrors["title"],
            isActive: apiDataErrors["is_active"],
            carNumbers: apiDataErrors["cars"],
            deviceAccessGroupIds: apiDataErrors["device_access_group_ids"],
            attachedUsernames: apiDataErrors["attached_usernames"],
          };
        throw [dataErrors, error.response.data];
      }
    },
    /**
     * Редактирование сотрудника.
     *
     * @param {Object} context
     * @param {Number} employeeId
     * @param {String} title
     * @param {Boolean} isActive
     * @param {Array.<Number>} deviceAccessGroupIds
     * @param {Array.<Object>} cars
     * @return {Promise}
     */
    async [ACTION_EDIT_EMPLOYEE](context, {employeeId, title, isActive, deviceAccessGroupIds, cars,attachedUsernames}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/employees/edit/", {
          id: employeeId,
          title,
          is_active: isActive,
          device_access_group_ids: deviceAccessGroupIds,
          attached_usernames: attachedUsernames,
          cars: cars.map(car => ({number: car})),
        });
        return response.data;
      } catch (error) {
        const apiDataErrors = reformatErrorsForm(error.response.data.fields),
          dataErrors = {
            title: apiDataErrors["title"],
            isActive: apiDataErrors["is_active"],
            deviceAccessGroupIds: apiDataErrors["device_access_group_ids"],
            cars: apiDataErrors["cars"],
            attachedUsernames: apiDataErrors["attached_usernames"],
          };
        throw [dataErrors, error.response.data];
      }
    },
    /**
     * Удаление сотрудников.
     *
     * @param {Object} context
     * @param {Array.<Number>} employeeIds
     * @return {Promise}
     */
    async [ACTION_DELETE_EMPLOYEES](context, employeeIds) {
      return this.getters.ajaxClient.post("/v0/employees/delete/", {ids: employeeIds});
    },

    async [ACTION_CREATE_EMPLOYEE_FROM_FILE](context, file) {
      let formData = new FormData();
      formData.append('file', file.file);
      try {
        const response = await this.getters.ajaxClient.post("/v0/employees/create/from_csv/", formData,{
          headers: {
            'content-type': 'multipart/form-data'}
        });
        return response.data;
      } catch (error) {
        const apiDataErrors = reformatErrorsForm(error.response.data.fields),
          dataErrors = {
          file: apiDataErrors["file"]
          }

        throw [dataErrors, error.response.data];
      }
    },

    async [ACTION_GET_TEMPLATE_FILE]() {
      return this.getters.ajaxClient.post("/v0/employees/create/download_csv_template/", {
        headers: {
          'content-type': 'content-type'}
      });
    },
    /**
     * Добавление сотрудников к группе.
     *
     * @param {Object} context
     * @param {Array.<Number>} employeeIds
     * @param {Number} deviceAccessGroupId
     * @return {Promise}
     */
    async [ACTION_ADD_EMPLOYEES_TO_DEVICE_ACCESS_GROUP](context, {deviceAccessGroupId, employeeIds}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/device_access_groups/employees/add/", {
          device_access_group_id: deviceAccessGroupId,
          employee_ids: employeeIds,
        });
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление сотрудников из группы.
     *
     * @param {Object} context
     * @param {Array.<Number>} employeeIds
     * @param {Number} deviceAccessGroupId
     * @return {Promise}
     */
    async [ACTION_DELETE_EMPLOYEES_FROM_DEVICE_ACCESS_GROUP](context, {deviceAccessGroupId, employeeIds}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/device_access_groups/employees/delete/", {
          device_access_group_id: deviceAccessGroupId,
          employee_ids: employeeIds,
        });
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
  },
};
