<template>
  <form @keyup.enter="saveDataAndRedirect()" @submit.prevent="saveDataAndRedirect()">
    <SmartInputText
      v-model="dataForm.title"
      :error="dataErrors.title"
      caption="Имя сотрудника"
      class="input-row"
      maxlength="255"
      placeholder="Укажите ФИО сотрудника"
    />

    <SmartVSelect
      v-model="dataForm.deviceAccessGroupIds"
      :multiple="true"
      :settings-remote-search="settingsSelectDeviceAccessGroup"
      caption="Группы доступа"
      class="input-row"
      placeholder="Укажите группы доступа и сотрудник получит доступ к устройствам"
    />

    <!-- todo до переделки апи фото
    <div class="input-row">
      <CamsButton priority="primary" type="button" @click="$refs.fileInput.click()">
        Выбрать фото
      </CamsButton>
      <input
        ref="fileInput"
        accept="image/*"
        multiple
        style="display:none"
        type="file"
        @change="selectPhoto"
      >

      <div v-if="photos.length" class="pacs-entity-photos">
        <div
          v-for="(photo, index) in photos"
          :key="index"
          class="employee-photo-preview"
        >
          <img
            :src="photo"
            class="employee-photo-preview__img"
          >
          <CamsButton
            class="employee-photo-preview__delete-button"
            icon-type="only"
            size="s"
            title="Удалить фото"
            type="button"
            @click="deleteNewPhoto(photo)"
          >
            <img alt="Удалить фото" src="@/assets/img/icons/close.svg">
          </CamsButton>
        </div>
      </div>
    </div>

    <div class="input-row">
      Требования к фото:
      <ul class="employee-photo-requirements">
        <li>— нейтральный фон;</li>
        <li>— взгляд направленный на камеру;</li>
        <li>— лицо ровно перпендикулярно к камере;</li>
        <li>— нейтральное выражение лица;</li>
        <li>— отсутствие аксессуаров на лице (очки, каска, маска и т.п);</li>
        <li>— в кадре должно быть только одно лицо;</li>
        <li>— формат файла - JPEG;</li>
      </ul>
      <div class="employee-photo-recommend">
        Рекомендация: загрузите несколько фотографий для лучшего распознавания.
      </div>
    </div>
    -->

    <SmartInputArray
      v-model="dataForm.carNumbers"
      :error="dataErrors.carNumbers"
      caption="Автомобильные номера"
      class="input-row"
    />
    <SmartInputArray
      v-model="dataForm.attachedUsernames"
      :error="dataErrors.attachedUsernames"
      caption="Учетная запись (номер договора)"
      class="input-row"
    />

    <SmartSwitch
      v-model="dataForm.isActive"
      :error="dataErrors.isActive"
      caption="Активен?"
      class="input-row"
    />

    <div class="buttons-group buttons-group--align-right">
      <CamsButton priority="primary" type="button" @click="saveDataAndSaveNextEmployee()">
        Сохранить и добавить нового
      </CamsButton>
      <CamsButton type="button" @click="closeDialog()">
        Отменить
      </CamsButton>
      <CamsButton priority="primary" type="submit">
        Сохранить
      </CamsButton>
    </div>

    <SpinnerLoadingModal v-if="isLoading" />
  </form>
</template>

<script>
import {ROUTE_PACS_EDIT_EMPLOYEE} from "@/router/names.js";
import {createEntityMixin} from "@/components/pacs/mixins.js";
import {ACTION_CREATE_EMPLOYEE, EMPLOYEE_STRUCT} from "@/store/pacs/employees/index.js";
import {actionsWithPhotosMixin} from "@/components/pacs/employess/mixins.js";
import {ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_SELECT, DEVICE_ACCESS_GROUP_STRUCT} from "@/store/pacs/deviceAccessGroups/index.js";

/**
 * Компонент диалога создания сотрудника.
 */
export default {
  mixins: [createEntityMixin, actionsWithPhotosMixin],
  data() {
    return {
      actionForSave: `pacs/employees/${ACTION_CREATE_EMPLOYEE}`,
      dataForm: {
        title: "",
        isActive: true,
        carNumbers: [],
        attachedUsernames: [],
        deviceAccessGroupIds: []
      },
      // Настройки выпадающих списков.
      settingsSelectDeviceAccessGroup: {
        action: `pacs/deviceAccessGroups/${ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_SELECT}`,
        valueField: DEVICE_ACCESS_GROUP_STRUCT.fields.id.name,
        labelField: DEVICE_ACCESS_GROUP_STRUCT.fields.title.name,
      },
    };
  },
  methods: {
    /**
     * Перенаправление на страницу редактирования сотрудника.
     *
     * @link saveEntityMixin.methods.getRouteRedirectAfterSave
     */
    getRouteRedirectAfterSave(newEntity) {
      return {name: ROUTE_PACS_EDIT_EMPLOYEE, params: {employeeId: newEntity[EMPLOYEE_STRUCT.fields.id.name]}};
    },
    /**
     * Сохранение сотрудника и переадресация на страницу редактирования после успешного сохранения.
     * При наличии фотографий сохранит и их, выполнив отдельные запросы.
     *
     * @link saveEntityMixin.methods.saveDataAndRedirect
     */
    async saveDataAndRedirect() {
      this.isLoading = true;
      try {
        const newEmployee = await this.saveData();
        await this.saveAllPhotosToEmployee(newEmployee[EMPLOYEE_STRUCT.fields.id.name]);
        this.redirectTo(newEmployee);
      } catch {
        // Перехват пробрасываемого исключения с ошибками валидации.
      }
      this.isLoading = false;
    },
    /**
     * Сохранение сотрудника и очищение полей title и carNumbers, для сохранения нового сотрудника. Группы доступа сохраняются.
     *
     */
    async saveDataAndSaveNextEmployee() {
      this.isLoading = true;
      try {
        await this.saveData();
        this.dataForm.title = "";
        this.dataForm.carNumbers = [];
        this.dataForm.attachedUsernames = [];
        this.$notify({
          group: "main",
          text: "Сотрудник успешно создан",
          duration: 5000,
          type: "default"
        });
      } catch {
        // Перехват пробрасываемого исключения с ошибками валидации.
      }
      this.isLoading = false;
    },
  },
};
</script>
