import {loadCameraInfoMixin} from "@/components/oneScreen/mixins.js";
import {eventHandlersMixin} from "@/components/events/mixins.js";
import {ACTION_LOAD_WHOLE_RECORDING_STATUS} from "@/store/cameras/index.js";

/**
 * Примесь для компонентов отчетов с общими функциями.
 * Отчеты нужны для показа архивных событий с камеры в виде таблицы, с возможностью перехода к просмотру видео этих событий,
 * скриншотов и скачивания самих отчетов.
 */
export const analyticReportMixin = {
  mixins: [
    loadCameraInfoMixin,
    eventHandlersMixin,
  ],
  data() {
    const minEventArchiveFrom = new Date(),
      maxEventArchiveTo = new Date();
    minEventArchiveFrom.setDate(minEventArchiveFrom.getDate() - 31);
    maxEventArchiveTo.setDate(maxEventArchiveTo.getDate() + 1);

    return {
      isLoadingReport: false,
      messages: [],
      // Начало и конец отрезка времени запрошенного архивного фрагмента - для проброса между компонентами.
      minEventArchiveFrom: minEventArchiveFrom,
      maxEventArchiveTo: maxEventArchiveTo,
      // Отдельно надо знать доступность видео, чтобы иметь возможность показать его только там где оно есть.
      minVideoArchiveFrom: new Date(),
    };
  },
  methods: {
    /**
     * Функция для перекрытия и запуска операций, которые необходимо выполнить после загрузки
     * информации по камере.
     *
     * @see loadCameraInfoMixin.methods.afterLoadCameraInfo
     * @return {Promise}
     */
    async afterLoadCameraInfo() {
      await this.receiveWholeRecordingStatus();
      this.resetFilters();
    },
    /**
     * Получение меток доступного фрагмента архива для корректного отображения календаря.
     */
    async receiveWholeRecordingStatus() {
      try {
        const wholeRecordingStatus = await this.$store.dispatch(`cameras/${ACTION_LOAD_WHOLE_RECORDING_STATUS}`, {
          cameraNumber: this.cameraInfo.number,
          domain: this.cameraInfo.server.domain,
          token: this.cameraInfo.tokenDVR,
        });
        this.minVideoArchiveFrom = new Date(wholeRecordingStatus[0] * 1000);
      } catch {
        // Игнорируем ошибки.
      }
    },
    /**
     * Сброс фильтров в начальное состояние.
     */
    resetFilters() {
    },
  }
};
