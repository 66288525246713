import { render, staticRenderFns } from "./ChildLinksForClients.vue?vue&type=template&id=6dee4c6c"
import script from "./ChildLinksForClients.vue?vue&type=script&lang=js"
export * from "./ChildLinksForClients.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports