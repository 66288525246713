<template>
  <div class="one-screen-analytic-messages">
    <SpinnerLoading v-if="isLoading" class="loader_center" color="blue" />

    <template v-else-if="messages.length > 0">
      <component
        :is="analyticMessageViewComponents[message.analyticType]"
        v-for="(message, messageIndex) in messages"
        :key="messageIndex"
        :analytic-message="message"
        class="one-screen-analytic-messages__analytic-message"
        @play-event-start="$emit('play-event-start', $event)"
        @show-full-screenshot="$emit('show-full-screenshot', $event)"
      />
    </template>

    <template v-else>
      <br>
      <p>События на данном промежутке времени отсутствуют.</p>
    </template>
  </div>
</template>

<script>
import {CameraInfo} from "@/store/cameras/index.js";
import {ACTION_LOAD_ARCHIVE_EVENTS_BY_CAMERA} from "@/store/analytics/index.js";
import {analyticMessageViewMixin} from "@/components/events/mixins.js";

/**
 * Компонент для отображения списка архивных событий (сообщений).
 * Применяется для отображения событий аналитики камер за определенный период времени и для перехода ко времени наступления этих событий.
 */
export default {
  name: "ArchiveEvents",
  mixins: [
    analyticMessageViewMixin,
  ],
  props: {
    /**
     * Экземпляр {@link CameraInfo} по которому отображается информация в компоненте.
     */
    cameraInfo: {
      type: CameraInfo,
      required: true
    },
    /**
     * Начало отрезка времени для запроса архивных событий.
     */
    archiveFrom: {
      type: Date,
      default: null,
    },
    /**
     * Конец отрезка времени для запроса архивных событий.
     */
    archiveTo: {
      type: Date,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      messages: [],
    };
  },
  watch: {
    /**
     * Отслеживание изменений начала отрезка времени для запроса архива событий.
     *
     * @param {Date} newValue
     * @param {Date} oldValue
     */
    archiveFrom(newValue, oldValue) {
      if (+newValue !== +oldValue) {
        this.loadMessages();
      }
    },
    /**
     * Отслеживание изменений конца отрезка времени для запроса архива событий.
     *
     * @param {Date} newValue
     * @param {Date} oldValue
     */
    archiveTo(newValue, oldValue) {
      if (+newValue !== +oldValue) {
        this.loadMessages();
      }
    },
  },
  /**
   * Запрос архивных событий на начальном заданном отрезке времени.
   */
  mounted() {
    this.loadMessages();
  },
  methods: {
    /**
     * Запрос архивных событий на текущем временном диапазоне.
     */
    async loadMessages() {
      this.isLoading = true;
      this.messages = [];
      this.messages = await this.$store.dispatch(`analytics/${ACTION_LOAD_ARCHIVE_EVENTS_BY_CAMERA}`, {
        cameraNumber: this.cameraInfo.number,
        archiveFrom: this.archiveFrom,
        archiveTo: this.archiveTo,
      });
      this.messages.map((analyticMessage) => {
        analyticMessage.cameraInfo = this.cameraInfo;
      });

      this.isLoading = false;
    },
  }
};
</script>
