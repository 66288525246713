/**
 * Константы для использования во всем проекте.
 */

// todo определить необходимость
export const MAP_TARGET = Object.freeze({
  my: "my",
  fav: "fav",
  pub: "pub",
});
export const MAP_CAMERAS_API_URL = Object.freeze({
  [MAP_TARGET.my]: "/v0/cameras/map/my/",
  [MAP_TARGET.fav]: "/v0/cameras/map/fav/",
  [MAP_TARGET.pub]: "/v0/cameras/map/public/",
});
export const PERMISSIONS = Object.freeze({
  LIVE: 30,
  L: 30,
  DVR: 20,
  R: 20,
  OWNER: 10,
  O: 10,
});
/**
 * Поля которые можно запросить по камере для карты.
 */
export const MAP_CAMERA_FIELDS = Object.freeze({
  number: "number",
  address: "address",
  title: "title",
  longitude: "longitude",
  latitude: "latitude",
  marker_id: "marker_id",
});
//---------------------------------------------------------------------------------------------
/**
 * Браузерный заголовок приложения.
 */
export const APPLICATION_TITLE = process.env.VUE_APP_TITLE;
/**
 * URL для тайлов карты.
 */
export const MAP_TILES_URL = process.env.VUE_APP_MAP_TILES_URL;
/**
 * Базовый URL передается в собранное приложение через переменную окружения VUE_APP_AUTH_TOKEN.
 */
export const CONFIG_BASE_URL = process.env.VUE_APP_API_BASE_PATH;
/**
 * Флаг для определения что сборка выполнена для ufanet.
 */
export const VENDOR_IS_UFANET = process.env.VUE_APP_VENDOR === "ufanet";
/**
 * Возможность авторизации через токен опциональная, может определяться при сборке.
 *
 * Актуально при разработке - запрашивать токен вручную и передавать его для сборки,
 * чтобы локально не организовывать процесс авторизации.
 */
export const CONFIG_AUTH_TOKEN = process.env.VUE_APP_AUTH_TOKEN;
export const CONFIG_AJAX_TIMEOUT = 10000;
export const CONFIG_AJAX_PREFIX_HEADER_AUTH = "Bearer ";
/**
 * Размеры страниц для списка загружаемых камер.
 */
export const PAGE_SIZES = Object.freeze({
  DEFAULT: 60,
  LIMITED: 12,
});
/**
 * Типы отображения камер.
 */
export const TYPES_VIEWS = Object.freeze({
  TILE: "TILE",
  TABLE: "TABLE"
});
/**
 * Параметры сортировки при получении камер.
 */
export const PARAMS_SORT_CAMERAS = Object.freeze({
  addr_asc: "addr_asc",
  addr_desc: "addr_desc",
  title_asc: "title_asc",
  title_desc: "title_desc",
  create_asc: "create_asc",
  create_desc: "create_desc",
  user: "user",
});
/**
 * Основные табы для использования внутри компонента с одним экраном.
 */
export const ONE_SCREEN_TABS = Object.freeze({
  SMART_PLAYER: "SMART_PLAYER",
  EMBED: "EMBED",
  THERMAL_VISION_REPORT: "THERMAL_VISION_REPORT",
  CAR_NUMBER_REPORT: "CAR_NUMBER_REPORT",
  FACE_RECOGNITION_REPORT: "FACE_RECOGNITION_REPORT",
  HELMET_REPORT: "HELMET_REPORT",
  MOTION_ALARM_REPORT: "MOTION_ALARM_REPORT",
  MASK_REPORT: "MASK_REPORT",
  PERIMETER_REPORT: "PERIMETER_REPORT",
  CROWD_REPORT: "CROWD_REPORT",
  PEOPLE_REPORT: "PEOPLE_REPORT",
});
/**
 * Время жизни события (в мс), за которое будет показываться событие на ячейке мультиэкрана.
 */
export const DEFAULT_SHOW_EVENT_TTL = 7000;
/**
 * Время жизни (в мс) соединения SSE для мультиэкрана.
 */
export const DEFAULT_MULTI_SSE_TTL = 540000;
/**
 * Максимальное количество камер для мозаики. См. API запросов на создание/редактирование.
 */
export const MAX_CAMERAS_FOR_MOSAIC = 32;
/**
 * Минимальное количество секунд для скачивания видео в плеере. См. API запросов токена на скачивание.
 */
export const MIN_UNIX_DOWNLOAD_DELTA_FOR_PLAYER = 20;
/**
 * Максимальное количество секунд для скачивания видео в плеере. См. API запросов токена на скачивание.
 */
export const MAX_UNIX_DOWNLOAD_DELTA_FOR_PLAYER = 28800; // 8 часов.
/**
 * Набор TTL для токенов просмотра/архива видео в плеере, которые будут запрошены с сервера.
 * Указывается в секундах. Необходим чтобы иметь представление в каких участках кода используются те или иные ограничения.
 */
export const TOKEN_TTL = Object.freeze({
  MIN: 60, // Минимум по API.
  MAX: 86400, // Максимум по API.
  DEFAULT: 3600, // По умолчанию по API.
  PLAYER: 3600, // 1 час.
  MULTI: 14400, // 4 часа.
});
/**
 * Время жизни кеша в секундах для результатов vuex действий.
 */
export const VUEX_CACHE_TTL = 300;

/**
 * Максимальное количество строк в таблице над которыми допускается производить групповые операции.
 * todo Число выдумано из головы, возможно потребует корректировки.
 */
export const MAX_ENTITIES_FOR_MULTI_EDIT = 10000;
