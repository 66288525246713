<template>
  <div class="cams-container__camera cams-container__camera--table" @click="selectCamera()">
    <div :class="{'cursor--pointer': selectableOnlyMode}" class="cams-container__camera-wrap">
      <div v-if="!selectableOnlyMode" class="cams-container__camera-controls">
        <button
          v-if="grabbableMode"
          :class="cameraInfo.isInactive() ? 'btn-gray' : 'btn-blue'"
          class="btn-blue cams-container__camera-controls__btn cams-container__camera-controls__btn--grabbable grabbable"
          title="Переместить"
          type="button"
        >
          <svg class="icon icon-grabbable">
            <use xlink:href="#icon-grabbable-grey" />
          </svg>
        </button>
        <button
          :class="cameraInfo.isInactive() ? 'btn-gray' : 'btn-blue'"
          :disabled="cameraInfo.isInactive()"
          class="btn-blue cams-container__camera-controls__btn cams-container__camera-controls__btn--online"
          title="Просмотр онлайн"
          type="button"
          @click="play()"
        >
          <svg class="icon icon-circle-play">
            <use xlink:href="#icon-circle-play" />
          </svg>
        </button>

        <button
          v-if="cameraInfo.isAvailableArchive()"
          :class="cameraInfo.isInactive() ? 'btn-gray' : 'btn-blue'"
          :disabled="cameraInfo.isInactive()"
          class="btn-blue cams-container__camera-controls__btn cams-container__camera-controls__btn--archive"
          title="Просмотр архива"
          type="button"
          @click="playArchive()"
        >
          <svg class="icon icon-circle-download">
            <use xlink:href="#icon-circle-download" />
          </svg>
        </button>
      </div>

      <div class="cams-container__camera-desc">
        <button
          v-if="cameraInfo.hasAnalyticThermalVision"
          class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
          title="Тепловизор"
          type="button"
        >
          <svg class="icon icon-analytics">
            <use xlink:href="#icon-temperature" />
          </svg>
        </button>
        <button
          v-else-if="cameraInfo.hasAnalyticCarNumber"
          class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
          title="Распознавание номеров"
          type="button"
        >
          <svg class="icon icon-analytics">
            <use xlink:href="#icon-car" />
          </svg>
        </button>
        <button
          v-else-if="cameraInfo.hasAnalyticFaceRecognition"
          class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
          title="Распознавание лиц"
          type="button"
        >
          <svg class="icon icon-analytics">
            <use xlink:href="#icon-face" />
          </svg>
        </button>
        <button
          v-else-if="cameraInfo.hasAnalyticHelmet"
          class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
          title="Распознавание касок"
          type="button"
        >
          <svg class="icon icon-analytics">
            <use xlink:href="#icon-helmet" />
          </svg>
        </button>
        <button
          v-else-if="cameraInfo.hasAnalyticMask"
          class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
          title="Детектор масок"
          type="button"
        >
          <svg class="icon icon-analytics">
            <use xlink:href="#icon-mask" />
          </svg>
        </button>

        <button
          v-else-if="cameraInfo.hasAnalyticPerimeter"
          class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
          title="Детектор пересечения зоны"
          type="button"
        >
          <svg class="icon icon-analytics">
            <use xlink:href="#icon-perimeter" />
          </svg>
        </button>
        <button
          v-else-if="cameraInfo.hasAnalyticCrowd"
          class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
          title="Детектор скопления людей"
          type="button"
        >
          <svg class="icon icon-analytics">
            <use xlink:href="#icon-crowd" />
          </svg>
        </button>
        <button
          v-else-if="cameraInfo.hasAnalyticPeopleCount"
          class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
          title="Подсчет посетителей"
          type="button"
        >
          <svg class="icon icon-analytics">
            <use xlink:href="#icon-people-counter" />
          </svg>
        </button>
        <button
          v-else-if="cameraInfo.hasAnalyticMotionAlarm"
          class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
          title="Движение в кадре"
          type="button"
        >
          <svg class="icon icon-analytics">
            <use xlink:href="#icon-motion" />
          </svg>
        </button>
        <EditorCameraTitle
          :camera-info="cameraInfo"
          :is-editable="!selectableOnlyMode"
          class="cams-container__title"
          spinner-color="blue"
        />
        <div class="cams-container__camera-address cams-container__camera-address--mobile">
          {{ cameraInfo.address }}
        </div>
      </div>

      <div class="cams-container__camera-meta">
        {{ cameraInfo.tariffName() }}
      </div>

      <div :title="cameraInfo.address" class="cams-container__camera-address">
        {{ cameraInfo.address }}
      </div>

      <div class="cams-container__camera-id">
        {{ "№ " + cameraInfo.number }}
      </div>

      <div v-if="!selectableOnlyMode" class="cams-container__camera-controls cams-container__camera-controls--additional">
        <button
          v-if="!selectableOnlyMode && cameraInfo.isEmbed"
          class="cams-container__camera-controls__btn cams-container__camera-controls__btn--code btn-blue"
          title="Встроить на сайт"
          type="button"
          @click="showEmbed()"
        >
          <svg class="icon icon-circle-integration">
            <use xlink:href="#icon-circle-integration" />
          </svg>
        </button>
      </div>

      <div v-show="isSelectedCamera" class="cams-container__selected-camera">
        <div class="cams-container__selected-camera-message">
          <svg height="19" viewBox="0 0 21 19" width="21" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.903 17.923L11.161.367A.768.768 0 0 0 10.5 0a.765.765 0 0 0-.66.367L.097 17.923a.691.691 0 0 0 .005.718.765.765 0 0 0 .654.359h19.486a.765.765 0 0 0 .654-.359.694.694 0 0 0 .006-.718zm-9.31-1.034H9.407v-2.111h2.188v2.11zm0-3.695H9.407v-6.86h2.188v6.86z" />
          </svg>
          <div>Камера выбрана для тревожного монитора</div>
        </div>
      </div>
      <div v-show="isSelectedCameraForFolder">
          <svg width="15" height="15" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15" cy="15" r="15" fill="#3B92CF"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.435 10.0502C22.8256 10.4407 22.8256 11.0739 22.435 11.4644L13.9497 19.9497C13.5592 20.3402 12.9261 20.3402 12.5355 19.9497L8.29289 15.7071C7.90237 15.3165 7.90237 14.6834 8.29289 14.2929C8.68342 13.9023 9.31658 13.9023 9.70711 14.2929L13.2426 17.8284L21.0208 10.0502C21.4113 9.65969 22.0445 9.65969 22.435 10.0502Z" fill="white"/>
          </svg>
      </div>
    </div>
  </div>
</template>

<script>
import {viewTypeMixin} from "@/components/meshCameras/mixins.js";

/**
 * Компонент отображает превью информацию по камере в виде строки таблицы.
 */
export default {
  name: "ViewTable",
  mixins: [viewTypeMixin],
};
</script>

<style lang="scss" scoped>
.cams-container__selected-camera {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  opacity: 1;
  z-index: 0;
  cursor: pointer;
}

.cams-container__selected-camera-message {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #fff;

  & svg {
    margin-right: 15px;
    width: 35px;
    height: 35px;
    fill: #fff;
  }

  div {
    text-align: center;
  }
}
</style>
