<template>
  <div>
    <div class="multiscreen-settings">
      <div class="multiscreen-settings__main-menu">
        <div class="multiscreen-settings__navigate">
          <CamsButton
            :disabled="isEditable"
            icon-type="only"
            title="Развернуть на весь экран"
            type="button"
            class="multiscreen-settings__fullscreen"
            @click="switchOnFullscreenMosaic()"
          >
            <svg class="icon-fullscreen">
              <use xlink:href="/img/symbol-defs-v2.svg#icon-fullscreen" />
            </svg>
          </CamsButton>

          <div class="multiscreen-tabs">
            <!-- Короткий список мозаик будет показан в виде табов. -->
            <template v-if="!showMosaicsForSelect">
              <router-link
                v-for="mosaicInfo in listMosaicsInfo"
                :key="mosaicInfo.id"
                v-slot="{navigate, isActive}"
                :to="{name: routes.ROUTE_MULTI_SCREEN_MOSAIC, params: {mosaicId: mosaicInfo.id}}"
                custom
              >
                <div
                  :class="{'multiscreen-tabs__item_active': isActive}"
                  class="multiscreen-tabs__item"
                  @click="navigate"
                >
                  <div
                    v-show="!(isEditable && !isCreatable && (mosaicId === mosaicInfo.id))"
                    :title="mosaicInfo.title"
                    class="multiscreen-tabs__item-title"
                    v-text="mosaicInfo.title"
                  />
                  <SmartInputText
                    v-show="isEditable && !isCreatable && (mosaicId === mosaicInfo.id)"
                    v-model="mosaicTitle"
                    :make-focus="true"
                    maxlength="16"
                    size="s"
                    class="multiscreen-tabs__item-input"
                  />
                </div>
              </router-link>
            </template>
            <!-- Длинный список мозаик будет показан в виде выпадающего списка. -->
            <SmartSelect
              v-else
              :one-row="true"
              :options="mosaicsInfoForSelect"
              :value="mosaicId"
              empty-value="Новая мозаика"
              label-field="title"
              placeholder="Укажите название"
              size="s"
              sort-field="order"
              value-field="id"
              width="fix"
              @input="selectMosaic"
            />

            <div
              v-show="isEditable && (isCreatable || showMosaicsForSelect)"
              class="multiscreen-tabs__item multiscreen-tabs__item_active"
            >
              <SmartInputText
                v-model="mosaicTitle"
                maxlength="16"
                size="s"
                :make-focus="true"
                class="multiscreen-tabs__item-input"
              />
            </div>
          </div>

          <router-link
            v-show="isAvailableCreateNewMosaic && !isCreatable"
            v-slot="{navigate}"
            :to="{name: routes.ROUTE_MULTI_SCREEN_NEW_MOSAIC}"
            custom
          >
            <div class="multiscreen-settings__new-mosaic-link" @click="navigate">
              <svg class="icon-circle-add">
                <use xlink:href="/img/symbol-defs-v2.svg#icon-circle-add" />
              </svg>
              Добавить мозаику
            </div>
          </router-link>
        </div>

        <div class="multiscreen-settings__editor-controls">
          <CamsButton
            :class="{'mode-button_mode-active': showMediator}"
            :disabled="isEditable"
            :title="showMediator ? 'Скрыть инструменты работы с архивом' : 'Показать инструменты работы с архивом'"
            class="mode-button"
            icon-type="fixed"
            type="button"
            @click="showMediator = !showMediator"
          >
            Архив
            <svg class="icon-in-button">
              <use xlink:href="../../assets/img/icons.svg#cloud-archive" />
            </svg>
          </CamsButton>
          <CamsButton
            :class="{'mode-button_mode-active': isEditable}"
            :disabled="showMediator"
            :title="isEditable ? 'Отменить все изменения в мозаике и скрыть настройки' : 'Показать настройки текущей мозаики'"
            class="mode-button"
            icon-type="fixed"
            type="button"
            @click="isEditable = !isEditable; !isEditable && backChanges()"
          >
            Настройки
            <svg class="icon-in-button">
              <use xlink:href="/img/symbol-defs-v2.svg#icon-pencil" />
            </svg>
          </CamsButton>
        </div>
      </div>

      <div v-if="isEditable" class="multiscreen-settings__editor">
        <div class="multiscreen-settings__editor-controls">
          <SmartSwitch v-model="lowLatencyMode" caption="Без задержки" title="Установить для всех камер режим работы без задержки" />
          <CamsButton type="button" title="Выбрать тревожные камеры для этой мозаики" @click="openSelectAlarmCamerasDialog()">
            Тревожные камеры
          </CamsButton>
          <button
            :class="{'active': selectedGrid === gridsMosaics.GRID1}"
            class="btn"
            type="button"
            title="1 камера"
            @click="selectedGrid = gridsMosaics.GRID1"
          >
            <svg class="icon-grid1">
              <use xlink:href="/img/symbol-defs-v2.svg#icon-grid1" />
            </svg>
          </button>
          <button
            :class="{'active': selectedGrid === gridsMosaics.GRID4}"
            class="btn"
            type="button"
            title="Сетка на 4 камеры"
            @click="selectedGrid = gridsMosaics.GRID4"
          >
            <svg class="icon-grid4">
              <use xlink:href="/img/symbol-defs-v2.svg#icon-grid4" />
            </svg>
          </button>
          <button
            :class="{'active': selectedGrid === gridsMosaics.GRID5}"
            class="btn"
            type="button"
            title="Сетка на 5 камер"
            @click="selectedGrid = gridsMosaics.GRID5"
          >
            <svg class="icon-grid5">
              <use xlink:href="/img/symbol-defs-v2.svg#icon-grid5" />
            </svg>
          </button>
          <button
            :class="{'active': selectedGrid === gridsMosaics.GRID6}"
            class="btn"
            type="button"
            title="Сетка на 6 камер"
            @click="selectedGrid = gridsMosaics.GRID6"
          >
            <svg class="icon-grid6">
              <use xlink:href="/img/symbol-defs-v2.svg#icon-grid6" />
            </svg>
          </button>
          <button
            :class="{'active': selectedGrid === gridsMosaics.GRID8}"
            class="btn"
            type="button"
            title="Сетка на 8 камер"
            @click="selectedGrid = gridsMosaics.GRID8"
          >
            <svg class="icon-grid8">
              <use xlink:href="/img/symbol-defs-v2.svg#icon-grid8" />
            </svg>
          </button>
          <button
            :class="{'active': selectedGrid === gridsMosaics.GRID9}"
            class="btn"
            type="button"
            title="Сетка на 9 камер"
            @click="selectedGrid = gridsMosaics.GRID9"
          >
            <svg class="icon-grid9">
              <use xlink:href="/img/symbol-defs-v2.svg#icon-grid9" />
            </svg>
          </button>
          <button
            :class="{'active': selectedGrid === gridsMosaics.GRID10}"
            class="btn"
            type="button"
            title="Сетка на 10 камер"
            @click="selectedGrid = gridsMosaics.GRID10"
          >
            <svg class="icon-grid10">
              <use xlink:href="/img/symbol-defs-v2.svg#icon-grid10" />
            </svg>
          </button>
          <button
            :class="{'active': selectedGrid === gridsMosaics.GRID16}"
            class="btn"
            type="button"
            title="Сетка на 16 камер"
            @click="selectedGrid = gridsMosaics.GRID16"
          >
            <svg class="icon-grid16">
              <use xlink:href="/img/symbol-defs-v2.svg#icon-grid16" />
            </svg>
          </button>
        </div>

        <div class="multiscreen-settings__editor-controls">
          <CamsButton
            priority="primary"
            title="Сохранить все изменения в мозаике"
            type="button"
            @click="saveChanges()"
          >
            Сохранить
          </CamsButton>
          <CamsButton
            type="button"
            title="Отменить все изменения в мозаике и скрыть настройки"
            @click="backChanges()"
          >
            Отменить
          </CamsButton>
          <CamsButton
            v-show="!isCreatable"
            title="Удалить текущую мозаику"
            type="button"
            @click="deleteCurrentMosaic()"
          >
            Удалить
          </CamsButton>
        </div>
      </div>
    </div>

    <div
      v-if="isLoadedMosaic"
      ref="fullscreen"
      :class="{'multiscreen-fullscreen_editable': isEditable, 'multiscreen-fullscreen_with-mediator': showMediator}"
      class="multiscreen-fullscreen"
    >
      <draggable
        v-model="actualCameraNumbers"
        :class="currentGridConfig.gridClass"
        :on-change="updateCssClasses"
        class="multiscreen-grid multiscreen-fullscreen__grid"
        handle=".grabbable"
        @end="actualizeListCamerasInfo(); updateCssClasses();"
      >
        <!-- Для корректной работы draggable все элементы должны быть в DOM, пусть и не все видны.
        Если при смене сетки будет меняться количество отрендереных ячеек,
        то перенос камер в "новые" ячейки может сопровождаться глюками и камеры туда не переедут с первого раза. -->
        <GridCell
          v-for="(cameraNumber, position) in actualCameraNumbers"
          v-show="position < currentGridConfig.cellCount"
          :key="`grid-cell-${position}`"
          :class="currentGridConfig.bigCells[position]"
          :initial-alarm-mode="(isEditable ? alarmSlots : visibleAlarmSlots).includes(position)"
          :initial-camera-info="listCamerasInfo[position]"
          :is-editable="isEditable"
          :low-latency-mode="lowLatencyMode"
          :stream-number="position in currentGridConfig.bigCells ? 1 : 2"
          class="multiscreen-grid__cell"
          @need-refresh="refreshCameraInMosaic($event, position)"
          @new-camera="setNewCameraInMosaic($event, position)"
          @change-alarm-mode="changeAlarmModeInSlot($event, position)"
          :show-mediator="showMediator"
        />
      </draggable>

      <!-- Одновременная работа медиатора и тревожного монитора не предусмотрена. -->
      <SmartPlayerMediator
        v-if="showMediator"
        :available-archive-from="availableArchiveFrom"
        :get-component-with-content="getComponentWithEventsForMediator"
        :get-line-fragments="getLineFragments"
        fullscreen-element-or-selector=".multiscreen-fullscreen"
        @time-shift-update="showUpcomingEvents"
      />
      <MultiLiveEvents v-else-if="sseUrl" :sse-url="sseUrl" @new-message="redAlert" />
    </div>
    <SpinnerLoading v-else class="loader_center" color="blue" />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import SmartPlayerMediator from "camsng-frontend-shared/components/smartPlayer/SmartPlayerMediator.vue";
import GridCell from "@/components/multiScreen/GridCell.vue";
import {
  ACTION_DELETE_MOSAIC,
  ACTION_GET_ANALYTIC_SSE_URL,
  ACTION_LOAD_MOSAIC,
  ACTION_LOAD_MOSAICS,
  ACTION_SAVE_MOSAIC,
  GRIDS_MOSAICS
} from "@/store/mosaics/index.js";
import {ROUTE_MULTI_SCREEN, ROUTE_MULTI_SCREEN_MOSAIC, ROUTE_MULTI_SCREEN_NEW_MOSAIC} from "@/router/names.js";
import {requestFullscreenByElement} from "camsng-frontend-shared/lib/helpers.js";
import MeshFrameDialog from "@/components/meshCameras/MeshFrameDialog.vue";
import MultiLiveEvents from "@/components/multiScreen/MultiLiveEvents.vue";
import {DEFAULT_MULTI_SSE_TTL, DEFAULT_SHOW_EVENT_TTL, MAX_CAMERAS_FOR_MOSAIC, VENDOR_IS_UFANET} from "@/utils/consts.js";
import {loadCameraInfoForMultiMixin} from "@/components/multiScreen/mixins.js";
import {ACTION_LOAD_ARCHIVE_EVENTS_BY_CAMERAS, ACTION_LOAD_ARCHIVE_EVENTS_CHUNKS_FOR_MULTI} from "@/store/analytics/index.js";
import {ANALYTICS} from "@/store/analytics/helpers.js";
import {
  CarNumberMessageView,
  CrowdMessageView,
  FaceRecognitionMessageView,
  HelmetMessageView, MaskMessageView,
  MotionAlarmMessageView,
  PeopleMessageView,
  PerimeterMessageView,
  ThermalVisionMessageView
} from "@/components/events/mixins.js";
import {YM_GOAL_MULTI_ARCHIVE} from "@/utils/tracking.js";
import {AnalyticMessage} from "@/store/analytics/analyticMessage.js";

/**
 * Набор конфигураций для разных сеток мозаики.
 *
 * Каждая сетка - список ячеек в которых работает плеер.
 * Верстка для всех сеток определяется через CSS Grid Layout, а самой мозаике меняется только класс для текущей выбранной конфигурации сетки.
 *
 * В ряде конфигураций некоторые ячейки занимают больше места чем другие.
 * Для них определен по умолчанию выбор 1 потока с лучшим качеством, по сравнению со 2 потоком.
 * А так же требуется определить класс, чтобы уточнить что именно эта ячейка будет большой.
 */
const GRIDS_CONFIG = Object.freeze({
  [GRIDS_MOSAICS.GRID1]: {
    gridClass: "multiscreen-grid_1",
    cellCount: 1,
    bigCells: {}
  },
  [GRIDS_MOSAICS.GRID4]: {
    gridClass: "multiscreen-grid_4",
    cellCount: 4,
    bigCells: {}
  },
  [GRIDS_MOSAICS.GRID5]: {
    gridClass: "multiscreen-grid_5",
    cellCount: 5,
    bigCells: {0: "multiscreen-grid__cell_big-1"}
  },
  [GRIDS_MOSAICS.GRID6]: {
    gridClass: "multiscreen-grid_6",
    cellCount: 6,
    bigCells: {0: "multiscreen-grid__cell_big-1"}
  },
  [GRIDS_MOSAICS.GRID8]: {
    gridClass: "multiscreen-grid_8",
    cellCount: 8,
    bigCells: {0: "multiscreen-grid__cell_big-1"}
  },
  [GRIDS_MOSAICS.GRID9]: {
    gridClass: "multiscreen-grid_9",
    cellCount: 9,
    bigCells: {}
  },
  [GRIDS_MOSAICS.GRID10]: {
    gridClass: "multiscreen-grid_10",
    cellCount: 10,
    bigCells: {0: "multiscreen-grid__cell_big-1", 1: "multiscreen-grid__cell_big-2"}
  },
  [GRIDS_MOSAICS.GRID16]: {
    gridClass: "multiscreen-grid_16",
    cellCount: 16,
    bigCells: {}
  },
});

/**
 * Компонент мультиэкрана.
 *
 * Отображает пользовательские мозаики, которые состоят из группы камер, и функционал по их редактированию.
 *
 * Загрузка камер по мозаике тоже происходит в рамках текущего компонента, а ячейки организуют трансляцию.
 * Если ячейка в сетке не может воспроизвести трансляцию, то посылается сигнал в этот (главный) компонент для обновления информации
 * по камере.
 */
export default {
  name: "MultiScreen",
  mixins: [
    loadCameraInfoForMultiMixin,
  ],
  components: {
    MultiLiveEvents,
    GridCell,
    draggable,
    SmartPlayerMediator,
  },
  data() {
    return {
      isLoadedMosaic: false,
      isEditable: false,
      isCreatable: false,
      isFullscreen: false,
      gridsMosaics: GRIDS_MOSAICS,
      routes: {ROUTE_MULTI_SCREEN_MOSAIC, ROUTE_MULTI_SCREEN_NEW_MOSAIC},
      listMosaicsInfo: [],
      mosaicId: null,
      mosaicTitle: null,
      selectedGrid: null,
      /**
       * Список номеров камер (и null/undefined вместо них) в мозаике.
       * Список формируется при загрузке существующей мозаике и при ее редактировании.
       *
       * Количество элементов всегда должно быть максимальным (согласно API для сохранения), чтобы при смене конфигурации сетки,
       * всегда можно было манипулировать всеми элементами, а не добавлять на ходу.
       */
      actualCameraNumbers: Array(MAX_CAMERAS_FOR_MOSAIC),
      /**
       * Массив из {@link CameraInfo} из которого формируется сетка с плеерами.
       */
      listCamerasInfo: [],
      /**
       * Флаг для включения плеера режиме низкой задержки.
       *
       * Общий компонент мультиэкрана определяет флаг, который пробрасывается в каждую ячейку в сетке.
       * Таким образом выбранный режим устанавливается для всех плееров. Смешивать нельзя, иначе
       * пользователи увидят рассинхрон на мозаике когда рядом стоящие камеры показывают разную картинку.
       */
      lowLatencyMode: true,
      alarmCameraNumbers: [], // Массив номеров камер по которым будут работать тревожные ячейки.
      alarmSlots: [], // Список с индексами ячеек в которых должен быть функционал тревожных ячеек.
      visibleAlarmSlots: [], // Список с индексами ячеек у которых в данный момент необходимо показать тревогу.
      // Идентификаторы таймаутов с восстановлением тревожных ячеек в нормальную работу и их timestamp для отслеживания старых таймаутов.
      alarmSlotsTimeouts: {}, // {slotId: [timeoutId, timestamp]}
      sseUrl: null, // URL для SSE канала событий по камерам тревожного монитора.
      timeoutIdForReloadEvents: null, // Таймаут для обновления SSE канала.
      showMediator: false, // Флаг для показа инструментов доступа к архиву на мультиэкране.
      availableArchiveFrom: new Date(), // Доступный архив для медиатора определяется на основе данных по тарифу у выбранных камер.
      visibleArchiveAnalyticInfo: [], // Кеш архивных событий аналитики и чанков, наблюдаемых на временной шкале.
      hideHiddenMessagesCount: false, // Флаг для скрытия информации о количестве скрытых (из-за большого количества) сообщений при наведении на временную шкалу.
      // Временные границы с упреждением для фильтрации наступающих (по времени воспроизведения архива) событий.
      advancedLeftBoundaryForUpcomingEvents: null, // Левая.
      advancedRightBoundaryForUpcomingEvents: null, // Правая.
    };
  },
  computed: {
    /**
     * @return {Object} Набор настроек для отрисовки сетки выбранной конфигурации.
     */
    currentGridConfig() {
      return GRIDS_CONFIG[this.selectedGrid];
    },
    /**
     * @return {Boolean} Вернет true, если допускается создание новой мозаики.
     */
    isAvailableCreateNewMosaic() {
      return this.listMosaicsInfo.length < 100;
    },
    /**
     * @return {Boolean} Вернет true, если надо отображать перечень мозаик в виде выпадающего списка.
     */
    showMosaicsForSelect() {
      return this.listMosaicsInfo.length > 4;
    },
    /**
     * @return {Object} Список доступных мозаик для отображения в выпадающем списке.
     */
    mosaicsInfoForSelect() {
      return this.listMosaicsInfo.reduce((accumulator, currentValue, index) => {
        return {
          ...accumulator,
          [index]: {
            id: currentValue.id,
            title: currentValue.title,
            order: index,
          }
        };
      }, {});
    },
    /**
     * Вернет дефолтный ID мозаики из созданных пользователем для навигации по умолчанию.
     * Будем считать первый.
     *
     * @return {Number}
     */
    defaultMosaicId() {
      return _.min(_.map(this.listMosaicsInfo, "id"));
    },
  },
  watch: {
    /**
     * Переход между состояниями компонента.
     */
    "$route"() {
      this._setupState();
    },
    /**
     * При переключении между режимами доступа к архиву в мультиэкране следует выключить режимы работы с событиями и наоборот.
     *
     * @param {Boolean} newShowMediator
     */
    showMediator(newShowMediator) {
      if (newShowMediator) {
        this.visibleAlarmSlots = [];
        this.sseUrl = null;
        clearTimeout(this.timeoutIdForReloadEvents);
        Object.values(this.alarmSlotsTimeouts).map((alarmSlotTimeoutInfo) => {
          alarmSlotTimeoutInfo && clearTimeout(alarmSlotTimeoutInfo[0]);
        });
        this.actualizeListCamerasInfo();
        VENDOR_IS_UFANET && this.$metrika?.reachGoal(YM_GOAL_MULTI_ARCHIVE);
      } else {
        this._setupState();
      }
    }
  },
  created() {
    this.loadUserMosaics().then(this._setupState);
  },
  beforeDestroy() {
    clearTimeout(this.timeoutIdForReloadEvents);
    Object.values(this.alarmSlotsTimeouts).map((alarmSlotTimeoutInfo) => {
      alarmSlotTimeoutInfo && clearTimeout(alarmSlotTimeoutInfo[0]);
    });
  },
  methods: {
    /**
     * Общий метод для определения состояния компонента.
     *
     * Вначале обнуляются ключевые параметры состояния компонента.
     * Актуальное состояние определяется через заданный маршрут
     * (имеется базовый маршрут который перенаправит на нужных исходя из глобального состояния).
     * После его определения управление передается в другую функцию которая доведет состояние до нужного,
     * и там переназначаться все необходимые параметры.
     *
     * Важно чтобы отрисовка шаблона происходила в соответствии с происходящими изменениями состояния
     * и чтобы это произошло необходимо обеспечить передачу управления в vue,
     * в связи с этим внутренние функции должны производить изменения в рамках своих Promise.
     *
     * При заданной мозаике загружается информация.
     *
     * При наличии ранее созданных мозаик, но навигации по общему маршруту, выбирается одна из созданных мозаик
     * и по ней осуществляется редирект.
     *
     * Начальное состояние (без имеющихся мозаик, трансляций) - загрузка пустой сетки с возможностью редактирования
     * и создания мозаики.
     */
    _setupState() {
      this.isLoadedMosaic = this.isEditable = this.isCreatable = false;
      this.mosaicId = this.mosaicTitle = this.selectedGrid = null;
      this.actualCameraNumbers = Array(MAX_CAMERAS_FOR_MOSAIC);
      this.listCamerasInfo = [];
      this.alarmCameraNumbers = [];
      this.alarmSlots = [];
      this.visibleAlarmSlots = [];
      this.sseUrl = null;
      clearTimeout(this.timeoutIdForReloadEvents);
      Object.values(this.alarmSlotsTimeouts).map((alarmSlotTimeoutInfo) => {
        alarmSlotTimeoutInfo && clearTimeout(alarmSlotTimeoutInfo[0]);
      });

      if (this.$route.name === ROUTE_MULTI_SCREEN) {
        // Базовый маршрут - переход к дефолтной (из ранее созданных) мозаике, либо переход к новой.
        if (this.defaultMosaicId) {
          this.$router.replace({name: ROUTE_MULTI_SCREEN_MOSAIC, params: {mosaicId: this.defaultMosaicId}});
        } else {
          this.$router.replace({name: ROUTE_MULTI_SCREEN_NEW_MOSAIC});
        }
      } else if (this.$route.name === ROUTE_MULTI_SCREEN_MOSAIC) {
        // Приведение состояния компонента к отображению конкретной мозаики.
        this._loadingCurrentMosaic();
      } else {
        // Приведение состояния компонента к созданию новой мозаики.
        // (this.$route.name === ROUTE_MULTI_SCREEN_NEW_MOSAIC)
        // поведение по умолчанию (или в случае проблем с маршрутом).
        this._creatingNewMosaic();
      }
    },
    /**
     * Приведение состояния компонента для отображения конкретной мозаики и загрузка по ней нужных данных.
     *
     * Если обращение идет к мозаике, по которой невозможно получить данные - происходит редирект на главную страницу мозаики.
     */
    async _loadingCurrentMosaic() {
      this.mosaicId = parseInt(this.$route.params.mosaicId);

      /** @type {MosaicInfo} */
      const mosaicInfo = await this.$store.dispatch(`mosaics/${ACTION_LOAD_MOSAIC}`, this.mosaicId);
      if (!mosaicInfo) {
        await this.loadUserMosaics();
        await this.$router.push({name: ROUTE_MULTI_SCREEN});
      } else {
        this.mosaicTitle = mosaicInfo.title;
        this.selectedGrid = mosaicInfo.grid;
        // В списке получаем только камеры для сохраненной сетки, необходимо добить список до максимального количества.
        this.actualCameraNumbers = [
          ...mosaicInfo.viewCameraNumbers,
          ...Array(MAX_CAMERAS_FOR_MOSAIC - mosaicInfo.viewCameraNumbers.length)
        ];
        this.lowLatencyMode = mosaicInfo.lowLatencyMode;
        this.alarmCameraNumbers = mosaicInfo.alarmCameraNumbers;
        this.alarmSlots = mosaicInfo.alarmSlots;
        await this.actualizeListCamerasInfo();
        await this.setupEvents();
        // Для медиатора можно рассчитать начало архива на основе списка актуальных камер.
        const availableArchiveFrom = new Date(),
              maxArchiveHours = Math.max(...this.listCamerasInfo.map((possibleCameraInfo) => possibleCameraInfo?.archiveHours || 0));
        availableArchiveFrom.setHours(availableArchiveFrom.getHours() - maxArchiveHours);
        this.availableArchiveFrom = availableArchiveFrom;
      }
      // Флаг полной загрузки мозаики считается установленным когда вся информация по мозаике и ее камерам загружена.
      // Это происходит в отдельном контексте чтобы завершить работу всех остальных функций.
      this.isLoadedMosaic = true;
    },
    /**
     * Приведение состояния компонента для создания новой мозаики.
     */
    _creatingNewMosaic() {
      this.selectedGrid = GRIDS_MOSAICS.GRID4; //  Сетка по умолчанию (в случае ошибок или начальной загрузки) - сетка на 4 трансляции.
      this.isEditable = this.isCreatable = true;
      this.mosaicTitle = "Новая мозаика";
      this.isLoadedMosaic = true;
    },
    /**
     * Загрузка списка мозаик, существующих у пользователя, для отображения в виде списка и возможности переключения между ними.
     */
    async loadUserMosaics() {
      this.listMosaicsInfo = await this.$store.dispatch(`mosaics/${ACTION_LOAD_MOSAICS}`, {});
    },
    /**
     * Установка новой камеры в указанной ячейке мозаики.
     * Функция-обработчик событий, которые порождаются из ячеек сетки (в которых задается камера).
     *
     * @param {String|Null} cameraNumber
     * @param {Number} position
     */
    setNewCameraInMosaic(cameraNumber, position) {
      devLog("setNewCameraInMosaic", position);
      this.actualCameraNumbers[position] = cameraNumber;
      this.actualizeListCamerasInfo();
    },
    /**
     * Функция обрабатывает перемещение камеры через drug&drop.
     * Вызывается в момент перехода камеры из одной ячейки в другую, но не свидетельствует о том что перенос камеры завершен.
     *
     * Сам процесс такой обработки оправдан только лишь в одном сценарии - когда камеры из маленьких ячеек переносят в большие и наоборот.
     * Проблема возникла из-за того, что через Grid CSS определены объединения ячеек для больших экранов и их положение зафиксировано в стилях.
     * И когда пытаешься переместить такие ячейки, визуально они не меняют своего положения, т.к. у них CSS-класс зафиксировал их положение.
     * Поэтому, чтобы не ломать вид при перемещении, обнуляем все классы и по порядку их следования расставляем заново,
     * как определено в настройках сетки.
     */
    updateCssClasses() {
      document.querySelectorAll(".multiscreen-grid__cell").forEach((cellElement, position) => {
        cellElement.className = `multiscreen-grid__cell ${this.currentGridConfig.bigCells[position] ?? ''}`;
      });
    },
    /**
     * Изменение флага для тревожного режима работы ячейки.
     *
     * @param {Boolean} alarmMode
     * @param {Number} position
     */
    changeAlarmModeInSlot(alarmMode, position) {
      if (alarmMode) {
        if (!this.alarmSlots.includes(position)) {
          this.alarmSlots.push(position);
        }
      } else {
        this.alarmSlots.splice(this.alarmSlots.indexOf(position), 1);
      }
    },
    /**
     * Обработка события о необходимости обновления камеры.
     * Основанием для обновления камеры может служить просроченный токен трансляции, за которым происходит наблюдение
     * в непосредственном компоненте ячейки сетки.
     *
     * В отличие от метода {@link setNewCameraInMosaic} здесь происходят реактивные изменения в {@link listCamerasInfo}
     * поскольку эта функция срабатывает по результатам работы компонентов, активация которых напрямую зависит от корректной работы
     * этого списка, а изменения в нем должны реактивно отразится в дочерних компонентах - когда приедет новый экземпляр камеры,
     * чтобы вновь активировать таймер обновления.
     *
     * @param {String|Null} cameraNumber
     * @param {Number} position
     */
    async refreshCameraInMosaic(cameraNumber, position) {
      const cameraInfo = await this.loadCameraInfo(cameraNumber);
      this.listCamerasInfo.splice(position, 1, cameraInfo);
    },
    /**
     * На основе актуального списка камер метод получит по ним всю информацию, которую можно будет передать в сетку.
     */
    async actualizeListCamerasInfo() {
      this.listCamerasInfo = await Promise.all(this.actualCameraNumbers.map(this.loadCameraInfo));
    },
    /**
     * Сбор отредактированной информации и отправка ее на сервер для сохранения или изменения мозаики.
     */
    async saveChanges() {
      let mosaicId = this.mosaicId;
      try {
        const result = await this.$store.dispatch(`mosaics/${ACTION_SAVE_MOSAIC}`, {
          mosaicId: this.mosaicId,
          mosaicTitle: this.mosaicTitle,
          mosaicGrid: this.selectedGrid,
          viewCameraNumbers: this.actualCameraNumbers.slice(0, this.currentGridConfig.cellCount),
          lowLatencyMode: this.lowLatencyMode,
          alarmCameraNumbers: this.alarmCameraNumbers,
          alarmSlots: this.alarmSlots,
        });

        mosaicId = (result && result.id) || this.mosaicId;
        if (!mosaicId) {
          // Ошибка когда ожидался ID для мозаики, но в данном случае его не оказалось.
          devLog("MultiScreen - saveChanges", result);
          this.$camsdals.alert("При сохранении произошла ошибка. Попробуйте перезагрузить страницу и повторите сохранение.");
          return;
        }

        this.isEditable = this.isCreatable = false;
        await this.loadUserMosaics();
      } catch (error) {
        devLog("MultiScreen - saveChanges - error", error);
        this.$camsdals.alert("При сохранении произошла ошибка. Попробуйте создать мозаику заново.");
        return;
      }

      let notifyText = "Мозаика сохранена";
      if (this.mosaicId) {
        this._setupState();
      } else {
        notifyText = "Добавлена новая мозаика";
        await this.$router.push({name: ROUTE_MULTI_SCREEN_MOSAIC, params: {mosaicId}});
      }
      this.$notify({
        group: "main",
        text: notifyText,
        duration: 5000,
        type: "success"
      });
    },
    /**
     * Удаление текущей мозаики, актуализация списка существующих мозаик и редирект на главный маршрут.
     */
    async deleteCurrentMosaic() {
      this.$camsdals.confirm("Хотите удалить эту мозаику?", async () => {
        try {
          await this.$store.dispatch(`mosaics/${ACTION_DELETE_MOSAIC}`, {mosaicId: this.mosaicId});
          await this.loadUserMosaics();
          await this.$router.push({name: ROUTE_MULTI_SCREEN});
          this.$notify({
            group: "main",
            text: "Мозаика удалена",
            duration: 5000,
            type: "success"
          });
        } catch {
          this.$camsdals.alert("Ошибка при удалении мозаики");
        }
      });
    },
    /**
     * Откат сделанных изменений, через возврат к начальному состоянию компонента.
     * Откат при создании новой мозаики возвращает пользователя на главный маршрут (и там уже разберемся что надо показывать).
     */
    backChanges() {
      if (this.isCreatable) {
        this.$router.push({name: ROUTE_MULTI_SCREEN});
      } else {
        this._setupState();
      }
    },
    /**
     * Включить в браузере полноэкранный режим мозаики.
     *
     * Используется js- селектор для поиска компонента с мозаикой
     * и через него используется программное включение полноэкранного режима.
     */
    switchOnFullscreenMosaic() {
      this.$refs.fullscreen && requestFullscreenByElement(this.$refs.fullscreen);
    },
    /**
     * Выбор мозаики из списка и переход к ней.
     *
     * @param {Number} mosaicId
     * @return {Promise<void>}
     */
    async selectMosaic(mosaicId) {
      await this.$router.push({name: ROUTE_MULTI_SCREEN_MOSAIC, params: {mosaicId}});
    },
    /**
     * Открытие диалога выбора камер для списка камер тревожного монитора.
     */
    openSelectAlarmCamerasDialog() {
      this.$camsdals.open(
        MeshFrameDialog,
        {initialSelectedCameraNumbers: this.alarmCameraNumbers},
        {dialogTitle: "Выберите камеры для получения событий на мозаике"},
        {
          size: "vuedal-auto-width vuedal-all-height",
          name: "js-click-outside",
          onClose: (alarmCameraNumbers) => {
            if (alarmCameraNumbers) {
              this.alarmCameraNumbers = alarmCameraNumbers;
              this.$notify({
                type: "warn",
                group: "main",
                duration: 7000,
                text: `Нажмите 'Сохранить', чтобы активировать выбранные камеры как тревожные`,
              });
            }
          }
        }
      );
    },
    /**
     * Установка работы механизма получения событий.
     * Получение URL для SSE канала и периодическое (из-за особенностей сервера каждые 9 мин.) его обновление.
     */
    async setupEvents() {
      clearTimeout(this.timeoutIdForReloadEvents);
      this.sseUrl = await this.$store.dispatch(`mosaics/${ACTION_GET_ANALYTIC_SSE_URL}`, this.mosaicId);

      // Если SSE URL не был получен своевременно, то его стоит перезапросить раньше, чем нормальный срок жизни SSE.
      this.timeoutIdForReloadEvents = setTimeout(() => {
        this.setupEvents();
      }, this.sseUrl ? DEFAULT_MULTI_SSE_TTL : 60000);
    },
    /**
     * Функция тревоги для тревожного монитора. Срабатывает по получению новых событий аналитики.
     *
     * @param {AnalyticMessage} analyticMessage
     */
    async redAlert(analyticMessage) {
      devLog("[redAlert]", analyticMessage);
      // Тревогу необходимо показать в тревожной ячейке, а если их нет, то и не в чем ее показывать.
      if (_.isEmpty(this.alarmSlots)) {
        devLog("[redAlert] No slots");
        return;
      }

      // Необходимо проверить, открыта ли камера среди тревожных ячеек (на текущий момент) и если открыта то показать тревогу в ней.
      // todo надо не смотреть на настроенную тревожность ячейки а лучше среди всех камер на экране
      let targetAlarmSlot = null;
      for (const alarmSlot of this.alarmSlots) {
        const cameraInfo = this.listCamerasInfo[alarmSlot];
        if (cameraInfo && (cameraInfo.number === analyticMessage.cameraNumber)) {
          targetAlarmSlot = alarmSlot;
        }
      }

      // Если среди тревожных ячеек нет интересующей камеры, то необходимо получить номер слота (индекс ячейки), в которой будет показана тревога.
      if (targetAlarmSlot === null) {
        // Перебираются все слоты в поисках подходящего.
        let alarmSlotWithMinimalTimestamp = null,
            minimalTimestamp = Date.now();
        for (const maybeFreedomAlarmSlot of this.alarmSlots) {
          const alarmSlotTimeoutInfo = this.alarmSlotsTimeouts[maybeFreedomAlarmSlot];
          if (!alarmSlotTimeoutInfo) {
            // По умолчанию подходящий слот - первый который не занят.
            targetAlarmSlot = maybeFreedomAlarmSlot;
            break;
          } else {
            // Подходящим слотом может быть тот у которого timestampStart наименьший среди остальных.
            if (alarmSlotTimeoutInfo[1] <= minimalTimestamp) {
              minimalTimestamp = alarmSlotTimeoutInfo[1];
              alarmSlotWithMinimalTimestamp = maybeFreedomAlarmSlot;
            }
          }
        }
        if ((targetAlarmSlot === null) && alarmSlotWithMinimalTimestamp) {
          targetAlarmSlot = alarmSlotWithMinimalTimestamp;
        }
      }
      if (targetAlarmSlot === null) {
        // На всякий случай если и сейчас по каким то причинам не удалось определить слот для отображения тревоги - операция прерывается.
        devLog("[redAlert] No target slot", targetAlarmSlot, this.alarmSlots, this.alarmSlotsTimeouts);
        return;
      }

      // На заданном слоте будет произведена замена камеры на ту по которой пришло сообщение.
      await this.refreshCameraInMosaic(analyticMessage.cameraNumber, targetAlarmSlot);
      this.visibleAlarmSlots.push(targetAlarmSlot);

      // Необходимо выставить таймаут восстановления ячейки в штатный режим.
      if (this.alarmSlotsTimeouts[targetAlarmSlot]) {
        // Однако до этого у ячейки уже могла быть другая тревога с другим таймаутом восстановления.
        // Тогда его необходимо сбросить, чтоб эффект не накладывался.
        clearTimeout(this.alarmSlotsTimeouts[targetAlarmSlot][0]);
      }
      // Перед таймаутом нужно замкнуть его первоначальную камеру.
      const sourceCameraNumber = this.actualCameraNumbers[targetAlarmSlot];
      this.alarmSlotsTimeouts[targetAlarmSlot] = [
        // timeoutId по которому будет очищаться таймер
        setTimeout(async () => {
          await this.refreshCameraInMosaic(sourceCameraNumber, targetAlarmSlot);
          this.visibleAlarmSlots.splice(this.visibleAlarmSlots.indexOf(targetAlarmSlot));
          this.alarmSlotsTimeouts[targetAlarmSlot] = null;
        }, DEFAULT_SHOW_EVENT_TTL),
        // timestamp по которому определяется наиболее старый, при превышении событий над ячейками
        Date.now()
      ];
      devLog("[redAlert] Target slot", targetAlarmSlot, this.alarmSlotsTimeouts[targetAlarmSlot]);
    },
    /**
     * Функция должна вернуть актуальный набор данных для отображения на временной шкале.
     * Для доступного архива фильтруются доступные участки для рисования, уточняется их цвет и низший приоритет.
     * Для событий - они запрашиваются из API и преобразуются из объектов сообщений в простую структуру для выгрузки.
     *
     * События на шкале кешируются в компоненте для их показа при наведении мыши на шкалу.
     *
     * @param {Date} leftBoundary
     * @param {Date} rightBoundary
     * @return {Promise<Array<Array<Date, Date, String, Number>>>}
     */
    async getLineFragments(leftBoundary, rightBoundary) {
      const actualCameraNumbers = this.actualCameraNumbers.filter(_.isString),
            availableArchiveFragments = [
              [this.availableArchiveFrom, new Date(), "#bbbbbb", 0], // Приоритет 0 = Архив.
            ];
      this.visibleArchiveAnalyticInfo = []; // При обновлении данных, нужно обнулить всю предыдущую информацию о событиях.
      let archiveEventsFragments = [];

      if ((rightBoundary - leftBoundary) > 14400000) {
        // На шкале больше 4 часов.
        this.visibleArchiveAnalyticInfo = await this.$store.dispatch(`analytics/${ACTION_LOAD_ARCHIVE_EVENTS_CHUNKS_FOR_MULTI}`, {
          cameraNumbers: actualCameraNumbers,
          archiveFrom: leftBoundary,
          archiveTo: rightBoundary,
        });
        // Случай когда смотрим на live, а чанков за этот период еще не собрано (обычно за последний час нет чанков), не обрабатывается
        // из-за того что по каждому типу аналитики нужно собирать свой псевдо чанк.
        this.hideHiddenMessagesCount = false;
      } else {
        // На шкале меньше суток.
        // Кеширование событий для их показа при наведении мыши на шкалу.
        this.visibleArchiveAnalyticInfo = await this.$store.dispatch(`analytics/${ACTION_LOAD_ARCHIVE_EVENTS_BY_CAMERAS}`, {
          cameraNumbers: actualCameraNumbers,
          archiveFrom: leftBoundary,
          archiveTo: rightBoundary,
        });
        this.hideHiddenMessagesCount = true;
      }

      for (const analyticMessageOrChunk of this.visibleArchiveAnalyticInfo) {
        archiveEventsFragments.push([analyticMessageOrChunk.startDate, analyticMessageOrChunk.endDate, analyticMessageOrChunk.color, analyticMessageOrChunk.priority]);
        if (analyticMessageOrChunk instanceof AnalyticMessage) {
          // Снабжение каждого события информацией о камере.
          analyticMessageOrChunk.cameraInfo = await this.loadCameraInfo(analyticMessageOrChunk.cameraNumber);
        }
      }

      return [...availableArchiveFragments, ...archiveEventsFragments];
    },
    /**
     * Вернет примитивный компонент-обертку над списком компонентов для отображения событий.
     * Этот компонент будет показан над временной шкалой.
     * Покажется ограниченное количество событий (если их было много).
     *
     * @param {Date} eventDate
     */
    async getComponentWithEventsForMediator(eventDate) {
      const interestingAnalyticMessagesOrChunks = [];
      for (const analyticMessageOrChunk of this.visibleArchiveAnalyticInfo) {
        if ((analyticMessageOrChunk.startDate <= eventDate) && (eventDate <= analyticMessageOrChunk.endDate)) {
          interestingAnalyticMessagesOrChunks.push(analyticMessageOrChunk);
        }
      }
      const hiddenMessagesCount = this.hideHiddenMessagesCount ? 0 : interestingAnalyticMessagesOrChunks.length - 5,
            analyticMessageViewComponents = {
              [ANALYTICS.thermal_vision]: ThermalVisionMessageView,
              [ANALYTICS.car_number]: CarNumberMessageView,
              [ANALYTICS.face_recognition]: FaceRecognitionMessageView,
              [ANALYTICS.helmet]: HelmetMessageView,
              [ANALYTICS.motion_alarm]: MotionAlarmMessageView,
              [ANALYTICS.mask]: MaskMessageView,
              [ANALYTICS.crowd]: CrowdMessageView,
              [ANALYTICS.perimeter_security]: PerimeterMessageView,
              [ANALYTICS.people_counter]: PeopleMessageView,
            },
            hiddenAnalyticTitles = Object.freeze({ // Подписи к скрытым сообщениям по разным типам аналитики.
              [ANALYTICS.thermal_vision]: "Скрыто событий по тепловизору: ",
              [ANALYTICS.car_number]: "Скрыто событий по автомобильным номерам: ",
              [ANALYTICS.face_recognition]: "Скрыто событий по распознаванию лиц: ",
              [ANALYTICS.helmet]: "Скрыто событий по каскам: ",
              [ANALYTICS.motion_alarm]: "Скрыто событий по движению: ",
              [ANALYTICS.mask]: "Скрыто событий по маскам: ",
              [ANALYTICS.crowd]: "Скрыто событий по скоплению: ",
              [ANALYTICS.perimeter_security]: "Скрыто событий по периметру: ",
            });

      return {
        render(h) {
          const messagesViews = interestingAnalyticMessagesOrChunks.slice(-5).map((analyticMessageOrChunk) => {
            if (analyticMessageOrChunk instanceof AnalyticMessage) {
              return h(analyticMessageViewComponents[analyticMessageOrChunk.analyticType], {
                class: "mediator-events__analytic-message",
                props: {"analytic-message": analyticMessageOrChunk, "show-camera-info": true}
              });
            }
            return h("p", {class: "mediator-events__hidden-messages"}, `${hiddenAnalyticTitles[analyticMessageOrChunk.analyticType]}${analyticMessageOrChunk.count}`);
          });
          if (hiddenMessagesCount > 0) {
            messagesViews.unshift(h("p", {class: "mediator-events__hidden-messages"}, `Скрыто событий: ${hiddenMessagesCount}`));
          }
          return h("div", {class: "mediator-events"}, messagesViews);
        }
      };
    },
    /**
     * Покажет наступающие события по дате, подсветив нужные ячейки в сетке с камерами.
     *
     * Медиатор регулярно сообщает о своем прогрессе воспроизведения. Это время сообщается в данный метод и по этому времени выбираются
     * ближайшие события.
     * Механизм извлечения событий такой же как и для всей временной шкалы, но в отличие от нее кеширование происходит на стороне
     * vuex.
     * Почему не использовать кеш событий для временной шкалы? Потому что закешированные там события относятся только к видимому,
     * а прогресс может находится за пределами наблюдаемой области на шкале. Поэтому запрос событий для прогресса отдельный,
     * плюс имеется механизм упреждения для запросов аналогичный кешированию в компоненте временной шкалы.
     *
     * @param {Date} progressDate
     */
    async showUpcomingEvents(progressDate) {
      // Если прогресс укладывается в ранее сохраненные границы с упреждением (т.е. имеются данные по наблюдаемому участку шкалы),
      // то обновления границ не происходит.
      if ((+progressDate <= +this.advancedLeftBoundaryForUpcomingEvents) || (+this.advancedRightBoundaryForUpcomingEvents <= +progressDate)) {
        // Расчет новых границ с упреждением.
        this.advancedLeftBoundaryForUpcomingEvents = progressDate;
        this.advancedRightBoundaryForUpcomingEvents = new Date(+progressDate + 120000);
      }

      // Запрос закешированных событий по сохраненным границам с упреждением - чтобы не запрашивать каждый раз одно и то же.
      const upcomingArchiveAnalyticMessages = await this.$store.cache.dispatch(`analytics/${ACTION_LOAD_ARCHIVE_EVENTS_BY_CAMERAS}`, {
        cameraNumbers: this.actualCameraNumbers.filter(_.isString),
        archiveFrom: this.advancedLeftBoundaryForUpcomingEvents,
        archiveTo: this.advancedRightBoundaryForUpcomingEvents,
      });

      // Локальная фильтрация и поиск подходящих слотов для показа сигнала наступления события.
      this.visibleAlarmSlots = [];
      const interestingCameraNumbers = new Set();
      for (const analyticMessage of upcomingArchiveAnalyticMessages) {
        if ((analyticMessage.startDate <= progressDate) && (progressDate <= analyticMessage.endDate)) {
          interestingCameraNumbers.add(analyticMessage.cameraNumber);
        }
      }

      this.visibleAlarmSlots = this.actualCameraNumbers
        .map((cameraNumber, slotIndex) => interestingCameraNumbers.has(cameraNumber) ? slotIndex : null)
        .filter(slotIndex => slotIndex !== null);
    },
  },
};
</script>

<style lang="scss">
@import "./scss/index.scss";
</style>
