<template>
  <div class="editor-camera-title">
    <SpinnerLoading v-if="isLoading" :color="spinnerColor" class="editor-camera-title__spinner" />
    <div
      v-else
      v-bind="canEditTitle ? {'contenteditable': 'contenteditable'} : {}"
      class="editor-camera-title__title"
      @focusin="isVisibleIcon = false"
      @focusout="resetEdit"
      @keydown.enter.prevent="saveTitle"
      @keydown.esc="$event.target.blur()"
      v-html="titleWithIcon"
      title="Редактировать"
    />
  </div>
</template>

<script>
import {PERMISSIONS} from "@/utils/consts.js";
import {ACTION_EDIT_TITLE, CameraInfo} from "@/store/cameras/index.js";

/**
 * Компонент для отображения и редактирования поля `title` в камере.
 * Редактирование определяется из прав пользователя на камеру и переданных опций.
 * Используется при проспотре списка камер.
 */
export default {
  name: "EditorCameraTitle",
  props: {
    /**
     * Экземпляр {@link CameraInfo} в котором редактируется название.
     */
    cameraInfo: {
      type: CameraInfo,
      required: true
    },
    /**
     * Параметр для цвета спиннера, отличается на разных страницах отображения заголовка.
     */
    spinnerColor: {
      type: String,
      default: null,
    },
    /**
     * Флаг для включения опций редактирования. Установка в false запрещает редактирование даже при наличии прав.
     */
    isEditable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isLoading: false,
      /**
       * Признак отображения иконки редактирования при получении фокуса названия камеры.
       * При получении фокуса иконка скрывается.
       */
      isVisibleIcon: this.isEditable,
    };
  },
  computed: {
    /**
     * Есть ли права на редактирование заголовка.
     * Редактировать оный могут только владельцы камеры.
     *
     * @return {Boolean}
     */
    canEditTitle() {
      return this.isEditable && (this.cameraInfo.permission === PERMISSIONS.OWNER);
    },
    /**
     * Иконка редактирования заголовка камеры (в случае если есть разрешение на редактирование).
     *
     * @return {String}
     */
    icon() {
      if (this.canEditTitle && this.isVisibleIcon) {
        return '<svg class="editor-camera-title__image"><use xlink:href="/img/symbol-defs-v2.svg#icon-pencil"></use></svg>';
      }
      return "";
    },
    /**
     * Название камеры вместе с иконкой редактирования. Текст названия используется как элемент редактирования.
     * Название камеры берется из исходных данных по камере,
     * таким образом если редактирование было прервано, то это можно использовать как источник для возврата в исходное состояние.
     *
     * @return {String}
     */
    titleWithIcon() {
      return `${this.cameraInfo.title} ${this.icon}`;
    },
  },
  methods: {
    /**
     * Сохранение отредактированного заголовка, завязано на нажатие `enter` при редактировании.
     * Сохранение допускается при наличии прав и соблюдении ограничения длины названия.
     *
     * @param {Event} eventEnter
     */
    saveTitle(eventEnter) {
      const newTitle = eventEnter.target.textContent.trim();
      if (!this.canEditTitle) {
        return;
      }
      if (newTitle.length > 64 || newTitle.length <= 0) {
        this.$notify({
          type: "warn",
          group: "cams",
          title: "Название",
          text: "Длина названия не может превышать 64 символа и не может быть пустым",
        });
        return;
      }
      if (!newTitle) {
        eventEnter.target.innerHTML = this.cameraInfo.title;
        return;
      }

      this.isLoading = true;
      this.$store.dispatch(`cameras/${ACTION_EDIT_TITLE}`, {number: this.cameraInfo.number, title: newTitle})
        .then(() => {
          this.cameraInfo.title = newTitle;
          this.$notify({
            type: "success",
            group: "main",
            text: "Название камеры успешно изменено",
          });
        })
        .catch((error) => {
          this.$notify({
            type: "error",
            group: "main",
            text: `Ошибка: ${error}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
          eventEnter.target.blur();
        });
    },
    /**
     * Отмена процесса редактирования и возврат исходного текста в поле редактирования.
     *
     * @param {Event} event
     */
    resetEdit(event) {
      this.isVisibleIcon = true;
      event.target.innerHTML = this.titleWithIcon;
    },
  },
};
</script>
