<template>
  <div class="main__content content">
    <div class="pacs-settings-table">
      <div class="pacs-settings-table__group-setting">
        <SmartInputDate
          v-model="archiveFrom"
          caption="Дата начала"
          class="pacs-settings-table__settings-control pacs-settings-table__settings-control_small"
          size="l"
          @input="applyTableSettings()"
        />
        <SmartInputDate
          v-model="archiveTo"
          caption="Дата конца"
          class="pacs-settings-table__settings-control pacs-settings-table__settings-control_small"
          size="l"
          @input="applyTableSettings()"
        />
        <CamsButton
          class="pacs-settings-table__settings-control"
          priority="primary"
          size="l"
          type="button"
          @click="downloadTableAsCSV()"
        >
          Скачать отчет
        </CamsButton>
      </div>
    </div>


    <SpinnerLoading v-if="isLoading" class="loader_center" color="blue" />

    <template v-else>
      <p v-show="dataRows.length === 1000" class="pacs-settings-table__warn">
        Отображается максимально допустимое количество 1000 строк, остальные записи могли быть скрыты. Чтобы увидеть их, уточните фильтры.
      </p>
      <PacsTable
        :column-captions="columnCaptions"
        :column-names="columnNames"
        :data-rows="dataRows"
        :page-count="1"
        :total-count="dataRows.length"
        :view-only-mode="true"
      >
        <template #customThCells />
        <template #customTdCells="{dataRow}">
          <CamsButton
            icon-type="only"
            title="Скриншот события"
            type="button"
            @click="showFullScreenshotForEventInTable(dataRow['_analyticMessage_'])"
          >
            <svg><title>Скриншот</title>
              <use xlink:href="#icon-screen-preview" />
            </svg>
          </CamsButton>
          <CamsButton icon-type="only" title="Видео события" type="button" @click="playEventStartForEventInTable(dataRow['_analyticMessage_'])">
            <svg>
              <use xlink:href="#icon-video-preview" />
            </svg>
          </CamsButton>
          <CamsButton icon-type="only" type="button" @click="downloadEventVideoInTable(dataRow['_analyticMessage_'])">
            <svg>
              <use xlink:href="../../../../assets/img/icons.svg#cloud-archive" />
            </svg>
          </CamsButton>
        </template>
      </PacsTable>
    </template>

    <!--iframe для скачивания-->
    <iframe :src="downloadUrl" frameborder="0" height="1" width="1" />
  </div>
</template>

<script>
import PacsTable from "@/components/pacs/PacsTable.vue";
import {SPECIAL_TYPES_DATA_IN_CELLS} from "@/store/pacs/helpers.js";
import {eventTableMixin} from "@/components/pacs/analytics/mixins.js";
import {downloadCSV} from "@/utils/helpers.js";
import {ACTION_LOAD_PACS_MASK_REPORT} from "@/store/pacs/analytics/index.js";

/**
 * Компонент отображения таблицы и фильтров с информацией по событиям касок.
 */
export default {
  name: "TableMask",
  mixins: [
    eventTableMixin,
  ],
  components: {
    PacsTable
  },
  data() {
    return {
      columnNames: ["text", "cameraNumber", "date"],
      columnCaptions: {
        "text": "Событие",
        "cameraNumber": "Номер камеры",
        "date": "Дата",
      },
      archiveFrom: null,
      archiveTo: null,
      searchText: null,
    };
  },
  computed: {
    /**
     * @return {Date} Корректное представление archiveFrom в сравнении с archiveTo.
     */
    clearArchiveFrom() {
      return this.archiveFrom && this.archiveTo ? new Date(Math.min(this.archiveFrom.getTime(), this.archiveTo.getTime())) : null;
    },
    /**
     * @return {Date} Корректное представление archiveTo в сравнении с archiveFrom.
     */
    clearArchiveTo() {
      return this.archiveFrom && this.archiveTo ? new Date(Math.max(this.archiveFrom.getTime(), this.archiveTo.getTime())) : null;
    },
  },
  methods: {
    /**
     * @see eventTableMixin.methods.applyTableSettings
     */
    applyTableSettings() {
      this.stringifyQueryParams({
        f: +this.clearArchiveFrom,
        t: +this.clearArchiveTo,
        s: this.searchText
      });
    },
    /**
     * @see eventTableMixin.methods.assignQueryParams
     */
    assignQueryParams(parsedQueryParams) {
      if (_.isEmpty(parsedQueryParams)) {
        this.resetFilters();
      } else {
        this.archiveFrom = new Date(parsedQueryParams.f);
        this.archiveTo = new Date(parsedQueryParams.t);
        this.searchText = parsedQueryParams.s;
      }
    },
    /**
     * @see eventTableMixin.methods.loadPage.
     */
    async loadPage() {
      if (!this.clearArchiveFrom || !this.clearArchiveTo) {
        return;
      }

      this.isLoading = true;
      let responseData;
      try {
        responseData = await this.$store.dispatch(`pacs/analytics/${ACTION_LOAD_PACS_MASK_REPORT}`, {
          archiveFrom: this.clearArchiveFrom,
          archiveTo: this.clearArchiveTo,
          query: this.searchText,
        });
      } catch (error) {
        if (!error) {
          return; // Для прерванных ранее отправленных запросов.
        }
        this.$camsdals.alert("При загрузке данных произошла ошибка. Попробуйте изменить параметры фильтрации");
      }
      responseData = responseData ?? [];
      this.dataRows = responseData.map((message) => {
        return {
          "text": {
            value: message.text || "Неизвестно",
          },
          "cameraNumber": {
            value: message.cameraNumber,
          },
          "date": {
            type: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
            value: message.date,
          },
          "_analyticMessage_": message, // Объект сообщения для передачи в события клика в кнопках на строчках в таблице. См. в этом шаблоне.
        };
      });
      this.isLoading = false;
    },
    /**
     * Сброс фильтров в начальное состояние.
     * Фильтры по умолчанию по датам за последний час.
     */
    resetFilters() {
      const archiveTo = this.maxArchiveTo || new Date(),
            archiveFrom = new Date(archiveTo.getTime());
      archiveFrom.setHours(archiveTo.getHours() - 1);
      this.archiveFrom = archiveFrom;
      this.archiveTo = archiveTo;
      this.searchText = "";
    },
    /**
     * Выгрузка данных таблицы в виде CSV.
     */
    downloadTableAsCSV() {
      let csvText = "";

      const filename = `Отчет по маскам.csv`,
            headers = [
              this.columnCaptions.text,
              this.columnCaptions.cameraNumber,
              this.columnCaptions.date,
            ];
      csvText = `${headers.join(',')}\r\n`;
      this.dataRows.forEach((message) => {
        csvText += `${message.text.value},${message.cameraNumber.value},${this.$options.filters.localDateTime(message.date.value)}\r\n`;
      });
      downloadCSV(filename, csvText);
    },
  },
};
</script>
