<template>
  <form @keyup.enter="saveDataAndRedirect()" @submit.prevent="saveDataAndRedirect()">
    <div class="buttons-group">
      <input
        ref="fileInput"
        type="file"
        style="display:none"
        accept=".csv"
        onclick="this.value=null"
        @change="uploadCsvFile"
      >
      <CamsButton
        v-model="this.dataForm.file"
        type="button"
        @click="$refs.fileInput.click()"
      >
        Загрузить файл
      </CamsButton>

      <CamsButton
        type="button"
        @click="getFileTemplate()"
      >
        Получить шаблон
      </CamsButton>
    </div>
    <div class="buttons-group buttons-group--align-right" style="margin-top: 10px">
      <CamsButton type="button" @click="closeDialog()">
        Отменить
      </CamsButton>
      <CamsButton priority="primary" type="submit">
        Сохранить
      </CamsButton>
    </div>
    <SpinnerLoadingModal v-if="isLoading" />
  </form>
</template>

<script>
import {createEntityMixin} from "@/components/pacs/mixins.js";
import {
  ACTION_CREATE_EMPLOYEE_FROM_FILE, ACTION_GET_TEMPLATE_FILE, EMPLOYEE_STRUCT,
} from "@/store/pacs/employees/index.js";
import {actionsWithPhotosMixin} from "@/components/pacs/employess/mixins.js";
import {downloadCSV} from "@/utils/helpers.js";

/**
 * Компонент диалога загрузки сотрудников из файла.
 */
export default {
  mixins: [createEntityMixin, actionsWithPhotosMixin],
  data() {
    return {
      actionForSave: `pacs/employees/${ACTION_CREATE_EMPLOYEE_FROM_FILE}`,
      dataForm: {
        file: "",
      },
    };
  },
  methods: {
    async getFileTemplate() {
      this.templateHeaders = await this.$store.dispatch(`pacs/employees/${ACTION_GET_TEMPLATE_FILE}`);
      downloadCSV("template", this.templateHeaders.data);
    },
    uploadCsvFile(event) {
      this.dataForm.file = event.target.files[0];
    },
    async saveDataAndRedirect() {
      this.isLoading = true;
      try {
        await this.saveData();
      } catch(error) {
        console.log(error)
        this.$notify({
          type: "error",
          group: "main",
          text: `Ошибка: ${error.fields.file}`,
        });
      }
      this.isLoading = false;
    },
    getRouteRedirectAfterSave() {
      this.closeDialog(true);
      this.$notify({
        type: "success",
        group: "main",
        text: "Файл успешно загружен",
      });
    },
  },
};
</script>
