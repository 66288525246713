<template>
  <div class="main__content content">
    <div class="pacs-settings-table">
      <div class="pacs-settings-table__group-setting">
        <SmartInputText
          v-model="searchText"
          class="pacs-settings-table__settings-control pacs-settings-table__settings-control_big"
          placeholder="Поиск"
          size="l"
          @keyup.native.enter="applyTableSettings()"
        >
          <CamsButton
            position="r"
            size="l"
            type="button"
            icon-type="only"
            @click="applyTableSettings()"
          >
            <svg class="icon-search">
              <use xlink:href="#icon-search" />
            </svg>
          </CamsButton>
        </SmartInputText>

        <SmartVSelect
          v-if="initialOptionsDeviceAccessGroups"
          v-model="filterDeviceAccessGroupId"
          :initial-options="initialOptionsDeviceAccessGroups"
          :settings-remote-search="settingsSelectDeviceAccessGroup"
          class="pacs-settings-table__settings-control pacs-settings-table__settings-control_big"
          placeholder="Фильтр по группам доступа"
          size="l"
        />

        <div v-show="showGroupOperations" class="buttons-group pacs-settings-table__multi-edit">
          <CamsButton size="l" type="button" @click="openDialogEditEmployeesInDeviceAccessGroup(false)">
            Добавить в группу
          </CamsButton>

          <CamsButton size="l" type="button" @click="openDialogEditEmployeesInDeviceAccessGroup(true)">
            Убрать из группы
          </CamsButton>

          <CamsButton icon-type="fixed" size="l" type="button" @click="deleteEmployees()">
            Удалить сотрудников
            <svg viewBox="-5 -5 30 30">
              <use xlink:href="#icon-delete" />
            </svg>
          </CamsButton>
        </div>
        <div v-show="!showGroupOperations" class="buttons-group pacs-settings-table__multi-edit">
          <CamsButton priority="primary" size="l" type="button" @click="openDialogCreateEmployee()">
            Добавить
          </CamsButton>
          <CamsButton priority="primary" size="l" type="button" @click="openDialogCreateEmployeeFromFile()">
            Добавить из файла
          </CamsButton>
          <CamsButton
            priority="secondary"
            size="l"
            type="button"
            @click="downloadTableAsCSV()"
          >
            Скачать отчет
          </CamsButton>
        </div>
      </div>
    </div>

    <SpinnerLoadingModal v-if="isLoading" />
    <SpinnerLoading v-if="isLoadingTable" class="loader_center" color="blue" />

    <PacsTable
      v-else
      :column-captions="columnCaptions"
      :column-names="columnNames"
      :column-widths="columnWidths"
      :current-page="currentPage"
      :data-rows="dataRows"
      :key-field="keyField"
      :page-count="pageCount"
      :sort-info="sortInfo"
      :total-count="totalCount"
      @new-page="selectPage"
      @new-sort="changeSort"
      @new-entities-for-multi-edit="selectedEntitiesForMultiEdit = $event"
    >
      <template #customThCells />
      <template #customTdCells="{dataRow}">
        <CamsButton
          icon-type="only"
          title="Удалить"
          type="button"
          @click="deleteEmployee(dataRow['_key_'])"
        >
          <svg>
            <use xlink:href="#icon-delete" />
          </svg>
        </CamsButton>
      </template>
    </PacsTable>
  </div>
</template>

<script>
import {
  ACTION_DELETE_EMPLOYEES, ACTION_LOAD_EMPLOYEES_FOR_CSV,
  ACTION_LOAD_EMPLOYEES_FOR_MULTI_EDIT,
  ACTION_LOAD_EMPLOYEES_FOR_TABLE,
  EMPLOYEE_STRUCT
} from "@/store/pacs/employees/index.js";
import {ROUTE_PACS_CARS, ROUTE_PACS_EDIT_EMPLOYEE} from "@/router/names.js";
import PacsTable from "@/components/pacs/PacsTable.vue";
import CreateEmployeeDialog from "@/components/pacs/employess/CreateEmployeeDialog.vue";
import {
  ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_SELECT,
  ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_TABLE,
  DEVICE_ACCESS_GROUP_STRUCT
} from "@/store/pacs/deviceAccessGroups/index.js";
import {CAR_STRUCT} from "@/store/pacs/cars/index.js";
import {entityTableMixin} from "@/components/pacs/mixins.js";
import {getFilterApiValue, makeFilterApi, SPECIAL_TYPES_DATA_IN_CELLS} from "@/store/pacs/helpers.js";
import EditEmployeesInDeviceAccessGroupDialog from "@/components/pacs/employess/EditEmployeesInDeviceAccessGroupDialog.vue";
import {QUERY_KEY_TABLE_PARAMS} from "@/router/queryKeys.js";
import {TableQueryParams} from "@/components/pacs/helpers.js";
import {downloadCSV} from "@/utils/helpers.js";
import CreateFromFileEmployeeDialog from "@/components/pacs/employess/CreateFromFileEmployeeDialog.vue";

/**
 * Компонент отображения таблицы и фильтров с информацией по сотрудникам.
 */
export default {
  mixins: [
    entityTableMixin
  ],
  components: {
    PacsTable,
  },
  data() {
    return {
      entityStruct: EMPLOYEE_STRUCT,
      entityFields: [
        EMPLOYEE_STRUCT.fields.id,
        EMPLOYEE_STRUCT.fields.title,
        EMPLOYEE_STRUCT.fields.is_active,
        EMPLOYEE_STRUCT.fields.device_access_group_ids,
        EMPLOYEE_STRUCT.fields.car_count,
      ],
      actionLoadDataForMultiEdit: `pacs/employees/${ACTION_LOAD_EMPLOYEES_FOR_MULTI_EDIT}`,
      // Настройки для фиксации ширины определенных колонок.
      columnWidths: {
        [EMPLOYEE_STRUCT.fields.id.name]: "100px",
        [EMPLOYEE_STRUCT.fields.is_active.name]: "100px",
        [EMPLOYEE_STRUCT.fields.car_count.name]: "200px",
      },
      // Опции фильтров.
      // Начальное значение для выпадающего списка null, чтобы при запросе начальных опций был сформирован честный массив,
      // но до момента отрисовки компонента списка.
      initialOptionsDeviceAccessGroups: null,
      settingsSelectDeviceAccessGroup: {
        action: `pacs/deviceAccessGroups/${ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_SELECT}`,
        valueField: DEVICE_ACCESS_GROUP_STRUCT.fields.id.name,
        labelField: DEVICE_ACCESS_GROUP_STRUCT.fields.title.name,
      },
      filterDeviceAccessGroupId: null,
    };
  },
  watch: {
    /**
     * Загрузка сотрудников в определенной группе доступа.
     */
    filterDeviceAccessGroupId() {
      const filterDeviceAccessGroupId = getFilterApiValue(this.filters, EMPLOYEE_STRUCT.filters.device_access_group_id);
      if (filterDeviceAccessGroupId !== this.filterDeviceAccessGroupId) {
        this.stringifyQueryParams({
          currentPage: 1,
          filters: this.filterDeviceAccessGroupId ? [
            makeFilterApi(EMPLOYEE_STRUCT.filters.device_access_group_id, "=", this.filterDeviceAccessGroupId)
          ] : []
        });
      }
    },
    /**
     * Наблюдение за оригинальным списком фильтров чтобы актуализировать местные значения.
     */
    filters() {
      this.filterDeviceAccessGroupId = getFilterApiValue(this.filters, EMPLOYEE_STRUCT.filters.device_access_group_id);
    },
  },
  /**
   * Начальная установка фильтров после настроек таблицы из URL, которая произошла в миксине.
   */
  mounted() {
    this.filterDeviceAccessGroupId = getFilterApiValue(this.filters, EMPLOYEE_STRUCT.filters.device_access_group_id);
    this.loadInitialOptionsForFilters();
  },
  methods: {
    async loadPage() {
      this.isLoadingTable = true;
      try {
        const responseData = await this.$store.dispatch(`pacs/employees/${ACTION_LOAD_EMPLOYEES_FOR_TABLE}`, {
                page: this.currentPage,
                search: this.searchText,
                orderBy: this.sortInfoForApi,
                filters: this.filters,
              }),
              deviceAccessGroupTitles = _.chain(responseData.extra[EMPLOYEE_STRUCT.extras.device_access_group])
                .keyBy(DEVICE_ACCESS_GROUP_STRUCT.fields.id.name)
                .mapValues(DEVICE_ACCESS_GROUP_STRUCT.fields.title.name)
                .value(),
              carNumberTitles = _.chain(responseData.extra[EMPLOYEE_STRUCT.extras.car])
                .keyBy(CAR_STRUCT.fields.id.name)
                .mapValues(CAR_STRUCT.fields.number.name)
                .value();
        this.pageCount = responseData.page.all;
        this.totalCount = responseData.count;
        this.dataRows = responseData.results.map((rawEmployeeInfo) => {
          const employeeId = Number(rawEmployeeInfo[EMPLOYEE_STRUCT.key]),
                paramsRouteEmployees = new TableQueryParams({
                  filters: [makeFilterApi(CAR_STRUCT.filters.employee_id, "=", employeeId)],
                }),
                paramsRouteEditEmployee = {name: ROUTE_PACS_EDIT_EMPLOYEE, params: {employeeId: rawEmployeeInfo[EMPLOYEE_STRUCT.key]}},
                carCount = rawEmployeeInfo[EMPLOYEE_STRUCT.fields.car_count.name];
          return {
            ...this.calcDefaultDataRow(rawEmployeeInfo),

            [EMPLOYEE_STRUCT.fields.id.name]: {
              type: EMPLOYEE_STRUCT.fields.id.type,
              value: rawEmployeeInfo[EMPLOYEE_STRUCT.fields.id.name],
              params: paramsRouteEditEmployee,
            },
            [EMPLOYEE_STRUCT.fields.title.name]: {
              type: EMPLOYEE_STRUCT.fields.title.type,
              value: rawEmployeeInfo[EMPLOYEE_STRUCT.fields.title.name],
              params: paramsRouteEditEmployee,
            },
            [EMPLOYEE_STRUCT.fields.device_access_group_ids.name]: {
              type: EMPLOYEE_STRUCT.fields.device_access_group_ids.type,
              value: rawEmployeeInfo[EMPLOYEE_STRUCT.fields.device_access_group_ids.name]
                .map(deviceAccessGroupId => deviceAccessGroupTitles[deviceAccessGroupId])
                .join(", "),
            },
            [EMPLOYEE_STRUCT.fields.car_count.name]: {
              type: carCount ? SPECIAL_TYPES_DATA_IN_CELLS.ROUTE : SPECIAL_TYPES_DATA_IN_CELLS.TEXT,
              value: carCount > 1 ? `Посмотреть все (${carCount})` : rawEmployeeInfo[EMPLOYEE_STRUCT.fields.car_ids.name]
                .map(carNumberId => carNumberTitles[carNumberId])
                .join(", ") || "-",
              valueForTable:  rawEmployeeInfo[EMPLOYEE_STRUCT.fields.car_ids.name]
                .map(carNumberId => carNumberTitles[carNumberId])
                .join(", "),
              params: {
                name: ROUTE_PACS_CARS,
                query: {[QUERY_KEY_TABLE_PARAMS]: paramsRouteEmployees.stringify()}
              },
            },
          };

        });
      } finally {
        this.isLoadingTable = false;
      }
    },
    /**
     * Загрузка начальных опций для выпадающих списков фильтров.
     */
    async loadInitialOptionsForFilters() {
      let initialOptionsDeviceAccessGroups = [];
      if (this.filterDeviceAccessGroupId) {
        const responseData = await this.$store.dispatch(`pacs/deviceAccessGroups/${ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_TABLE}`, {
          filters: [makeFilterApi(DEVICE_ACCESS_GROUP_STRUCT.key, "=", this.filterDeviceAccessGroupId)],
        });
        initialOptionsDeviceAccessGroups = responseData.results;
      }
      this.initialOptionsDeviceAccessGroups = initialOptionsDeviceAccessGroups;
    },
    /**
     * Открытие диалогового окна для создания сотрудника.
     */
    openDialogCreateEmployee() {
      this.$camsdals.open(CreateEmployeeDialog, {}, {dialogTitle: "Создание сотрудника"});
    },
    openDialogCreateEmployeeFromFile() {
      this.$camsdals.open(CreateFromFileEmployeeDialog, {}, {dialogTitle: "Создание из файла"});
    },
    /**
     * Удаление сотрудника.
     *
     * @param {Number} employeeId
     */
    deleteEmployee(employeeId) {
      this.$camsdals.confirm("Хотите удалить этого сотрудника?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`pacs/employees/${ACTION_DELETE_EMPLOYEES}`, [employeeId]);
          await this.loadPage();
          this.$notify({
            group: "main",
            text: "Сотрудник удален",
            duration: 5000,
            type: "success"
          });
        } catch {
          this.$camsdals.alert("Ошибка при удалении сотрудника");
        }
        this.isLoading = false;
      });
    },
    /**
     * Удаление сотрудников.
     */
    async deleteEmployees() {
      const employeeIds = await this.getSelectedEntitiesForMultiEdit();
      employeeIds.length && this.$camsdals.confirm(`Хотите удалить выбранных сотрудников? Выбрано ${employeeIds.length} записей`, async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`pacs/employees/${ACTION_DELETE_EMPLOYEES}`, employeeIds);
          await this.loadPage();
          this.$camsdals.alert("Сотрудники удалены");
        } catch {
          this.$camsdals.alert("Ошибка при удалении сотрудников");
        }
        this.isLoading = false;
      });
    },
    /**
     * Открывает диалог для редактирования группы доступа у выбранных сотрудников.
     *
     * @param {Boolean} forDelete Флаг для переключения режима работы диалога - удалять сотрудников из группы или добавлять в нее.
     */
    async openDialogEditEmployeesInDeviceAccessGroup(forDelete) {
      const employeeIds = await this.getSelectedEntitiesForMultiEdit();
      employeeIds.length && this.$camsdals.open(
        EditEmployeesInDeviceAccessGroupDialog,
        {employeeIds, forDelete},
        {dialogTitle: forDelete ? "Удалить сотрудников из группы" : "Добавить сотрудников в группу"},
        {onClose: (success) => (success && this.loadPage())}
      );
    },
    async downloadTableAsCSV() {
      this.isLoadingTable = true;
      try {
        const responseData = await this.$store.dispatch(`pacs/employees/${ACTION_LOAD_EMPLOYEES_FOR_CSV}`, {
              }),
              deviceAccessGroupTitles = _.chain(responseData.extra[EMPLOYEE_STRUCT.extras.device_access_group])
                .keyBy(DEVICE_ACCESS_GROUP_STRUCT.fields.id.name)
                .mapValues(DEVICE_ACCESS_GROUP_STRUCT.fields.title.name)
                .value(),
              carNumberTitles = _.chain(responseData.extra[EMPLOYEE_STRUCT.extras.car])
                .keyBy(CAR_STRUCT.fields.id.name)
                .mapValues(CAR_STRUCT.fields.number.name)
                .value();
        this.dataRowsForCSV = responseData.results.map((rawEmployeeInfo) => {
          const employeeId = Number(rawEmployeeInfo[EMPLOYEE_STRUCT.key]),
                paramsRouteEmployees = new TableQueryParams({
                  filters: [makeFilterApi(CAR_STRUCT.filters.employee_id, "=", employeeId)],
                }),
                paramsRouteEditEmployee = {name: ROUTE_PACS_EDIT_EMPLOYEE, params: {employeeId: rawEmployeeInfo[EMPLOYEE_STRUCT.key]}},
                carCount = rawEmployeeInfo[EMPLOYEE_STRUCT.fields.car_count.name];
          return {
            ...this.calcDefaultDataRow(rawEmployeeInfo),
            [EMPLOYEE_STRUCT.fields.id.name]: {
              type: EMPLOYEE_STRUCT.fields.id.type,
              value: rawEmployeeInfo[EMPLOYEE_STRUCT.fields.id.name],
              params: paramsRouteEditEmployee,
            },
            [EMPLOYEE_STRUCT.fields.title.name]: {
              type: EMPLOYEE_STRUCT.fields.title.type,
              value: rawEmployeeInfo[EMPLOYEE_STRUCT.fields.title.name],
              params: paramsRouteEditEmployee,
            },
            [EMPLOYEE_STRUCT.fields.device_access_group_ids.name]: {
              type: EMPLOYEE_STRUCT.fields.device_access_group_ids.type,
              value: rawEmployeeInfo[EMPLOYEE_STRUCT.fields.device_access_group_ids.name]
                .map(deviceAccessGroupId => deviceAccessGroupTitles[deviceAccessGroupId])
                .join(", "),
            },
            [EMPLOYEE_STRUCT.fields.car_count.name]: {
              type: carCount ? SPECIAL_TYPES_DATA_IN_CELLS.ROUTE : SPECIAL_TYPES_DATA_IN_CELLS.TEXT,
              value: carCount > 1 ? `Посмотреть все (${carCount})` : rawEmployeeInfo[EMPLOYEE_STRUCT.fields.car_ids.name]
                .map(carNumberId => carNumberTitles[carNumberId])
                .join(", ") || "-",
              valueForTable:  rawEmployeeInfo[EMPLOYEE_STRUCT.fields.car_ids.name]
                .map(carNumberId => carNumberTitles[carNumberId])
                .join(", "),
              params: {
                name: ROUTE_PACS_CARS,
                query: {[QUERY_KEY_TABLE_PARAMS]: paramsRouteEmployees.stringify()}
              },
            },
          };

        });
      } finally {
        this.isLoadingTable = false;
      }
      let csvText = "";
      const filename = `Выгрузка по сотрудникам.csv`,
            headers = [
              "Номер",
              this.columnCaptions.title,
              this.columnCaptions.car_count,
              this.columnCaptions.device_access_group_ids,
            ];
      csvText = `${headers.join(',')}\r\n`;
      this.dataRowsForCSV.forEach((message, index) => {
        csvText += `"${index + 1}","${message.title.value}","${message.car_count.valueForTable}"\v,"${message.device_access_group_ids.value}",\r\n`;
      });
      csvText += `"Всего сотрудников:  ${this.dataRowsForCSV.length}"\r\n`;
      downloadCSV(filename, csvText);
    },

  }
};
</script>
