<template>
  <div>
    <label>Сортировать по:</label>
    <div v-click-outside="hideListOptions" class="dropdown-container">
      <button :class="[{ 'clicked': isOpened }, 'icon-arrow-sort']" type="button" @click="toggleListOptions">
        <span v-html="parsedOptions[newValue].label" />
        <svg :class="['icon', parsedOptions[newValue].icon]" style="margin-left: auto">
          <use :xlink:href="'#' + parsedOptions[newValue].icon" />
        </svg>
        <svg class="icon-arrow-sort">
          <use xlink:href="#icon-arrow-sort" />
        </svg>
      </button>

      <div :class="{ 'dropdown-container__list--opened': isOpened}" class="dropdown-container__list">
        <ul>
          <li
            v-for="(settingSortCameras, paramSortCameras) in parsedOptions"
            :key="paramSortCameras"
            class="dropdown-container__list-item"
            @click="selectOption(paramSortCameras)"
          >
            <span>{{ settingSortCameras.label }}</span>
            <svg :class="['icon', settingSortCameras.icon]" style="margin-left: auto">
              <use :xlink:href="'#' + settingSortCameras.icon" />
            </svg>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {PARAMS_SORT_CAMERAS} from "@/utils/consts.js";

/**
 * Настройки для возможных вариантов сортировки.
 */
const SETTINGS_SORT_CAMERAS = Object.freeze({
  [PARAMS_SORT_CAMERAS.addr_asc]: {icon: "icon-sort-increase", label: "По адресу"},
  [PARAMS_SORT_CAMERAS.addr_desc]: {icon: "icon-sort-decrease", label: "По адресу"},
  [PARAMS_SORT_CAMERAS.title_asc]: {icon: "icon-sort-increase", label: "По заголовку"},
  [PARAMS_SORT_CAMERAS.title_desc]: {icon: "icon-sort-decrease", label: "По заголовку"},
  [PARAMS_SORT_CAMERAS.create_asc]: {icon: "icon-sort-increase", label: "По дате добавления"},
  [PARAMS_SORT_CAMERAS.create_desc]: {icon: "icon-sort-decrease", label: "По дате добавления"},
  [PARAMS_SORT_CAMERAS.user]: {icon: "icon-sort-custom", label: "Пользовательская"},
});

/**
 * Компонент отображения выпадающего списка для выбора настроек сортировки списка камер в рамках страницы отображения списка камер.
 * В компонент передается модель данных через v-model, с которой будет устанавливаться связь.
 * Так же передается объект со списком доступных опций сортировок.
 * Поскольку компонент определен для использования в конкретных случаях, то текст и иконки заранее определены в данном компоненте.
 */
export default {
  name: "SelectSort",
  props: {
    /**
     * Значение, которое надо передавать через v-model.
     */
    value: {
      type: String,
      default: PARAMS_SORT_CAMERAS.addr_asc
    },
    /**
     * Массив из значений {@link PARAMS_SORT_CAMERAS} для формирования конкретного списка опций.
     */
    availableParamsSort: {
      type: Array,
      default() {
        return [];
      }
    },
  },
  data() {
    return {
      isOpened: false,
      newValue: this.value,
    };
  },
  computed: {
    /**
     * Вернет срез из исходного набора возможных опций исходя из переданного списка опций.
     *
     * @return {Object}
     */
    parsedOptions() {
      return _.pick(SETTINGS_SORT_CAMERAS, this.availableParamsSort);
    },
  },
  watch: {
    /**
     * Наблюдение за оригинальным значением для его синхронизации со внутренним значением компонента и отправкой события change.
     *
     * @param {String} newValue
     */
    value(newValue) {
      this.newValue = newValue;
      this.$emit("change", newValue);
    },
  },
  methods: {
    /**
     * Метод срабатывает при клике на опцию в списке.
     * Новое значение сохраняется в компоненте и передается по событию в родительский компонент в привязанную модель,
     * в конце список скрывается.
     *
     * @param {String} newParamSortCameras
     */
    selectOption(newParamSortCameras) {
      this.newValue = newParamSortCameras;
      this.$emit("input", newParamSortCameras);
      this.hideListOptions();
    },
    /**
     * Метод вызывается по событию клика в поле для открытия списка.
     * Переключает видимость списка.
     */
    toggleListOptions() {
      this.isOpened = !this.isOpened;
    },
    /**
     * Метод скрывает список опций.
     * Его следует передавать как аргумент в директиву, что отслеживает клики за пределами элемента, в котором она указана.
     * Это нужно чтобы список скрывался при клике за его пределами.
     */
    hideListOptions() {
      this.isOpened = false;
    },
  },
};
</script>
