<template>
  <div class="mesh-cameras">
    <div class="mesh-cameras__navigate">
      <CamerasFinder
        v-model="querySearchCameras"
        :selectable-only-mode="false"
        @input="saveState()"
        @show-all-results="goToPageAllSearchResults()"
      />
      <div class="tabs-container">
        <router-link :to="{ name: routes.ROUTE_CAMS_MY }" class="tabs-container__tab-item" @click.native="loadCamerasDefault">
          Мои камеры
        </router-link>
        <router-link :to="{ name: routes.ROUTE_CAMS_FAV }" class="tabs-container__tab-item" @click.native="resetFolder">
          Избранные
        </router-link>
        <router-link v-if="folderName" :to="{}" class="tabs-container__tab-item" :class="{ 'disabled-link': !folderName}">
          {{ folderName }}
        </router-link>
        <router-link
          v-if="queryTabSearch"
          class="tabs-container__tab-item"
          :to="{ name: routes.ROUTE_CAMS_SEARCH, query: queryParamsForSearch }"
        >
          Поиск
        </router-link>
      </div>
    </div>

    <div class="mesh-cameras__view-options">
      <SelectSort
        v-model="selectedSort"
        :available-params-sort="availableParamsSort"
        class="mesh-cameras__sort-selector"
        @input="saveStateAndLoadCameras"
      />
      <ViewTypeSwitcher
        v-model="selectedView"
        class="mesh-cameras__view-type-switcher"
        @input="saveStateAndLoadCameras"
      />
      <button :class="{ 'active': showFolders }" class="show-folder-button" @click="toggleFolders">
        <svg class="icon">
          <use xlink:href="#icon-folder-view" />
        </svg>
      </button>
    </div>

    <div class="mesh-cameras-grid-main-container">
      <transition name="fade">
        <div v-show="showFolders" class="view-folders-component">
          <CamsButton
            v-show="showFolders"
            type="button"
            class="tabs-container__tab-item-folder"
            size="l"
            priority="primary"
            @click="openSelectFolderCamerasDialog"
          >
            Создать папку
          </CamsButton>
          <Folders />
        </div>
      </transition>
      <SpinnerLoading v-if="isLoadingCommon" class="loader_center" color="blue" />
      <template v-else>
        <paginate
          v-if="pageCount > 1"
          v-model="selectedPage"
          :click-handler="saveStateAndLoadCameras"
          :hide-prev-next="true"
          :page-count="pageCount"
          :page-range="5"
          active-class="smart-pagination__page_active"
          break-view-class="smart-pagination__page_collapse"
          container-class="smart-pagination"
          next-class="smart-pagination__page smart-pagination__next-prev"
          next-link-class="smart-pagination__page-link"
          next-text="<svg class='icon icon-arrow-left'><use xlink:href='#icon-arrow-left'></use></svg>"
          page-class="smart-pagination__page"
          page-link-class="smart-pagination__page-link"
          prev-class="smart-pagination__page smart-pagination__next-prev"
          prev-link-class="smart-pagination__page-link"
          prev-text="<svg class='icon icon-arrow-right'><use xlink:href='#icon-arrow-right'></use></svg>"
        />
        <div>
          <SpinnerLoading v-if="isLoadingPage" class="loader_center" color="blue" />
          <draggable
            v-else-if="listCamerasInfo.length"
            key="table_grabbable"
            v-model="listCamerasInfo"
            :class="[isTableView ? 'mesh-cameras-grid-main_table' : 'mesh-cameras-grid-main_tiles', showFolders ? 'mesh-cameras-grid-main' : 'mesh-cameras-grid']"
            handle=".grabbable"
            @update="changeUserSort"
          >
            <component
              :is="componentView"
              v-for="cameraInfo in listCamerasInfo"
              :key="cameraInfo.number"
              :camera-info="cameraInfo"
              :grabbable-mode="availableGrabbable"
              :screenshot-sign="screenshotSign"
              class="mesh-cameras-grid-main__cell"
            />
          </draggable>
          <p v-else>
            Камер не найдено
          </p>
          <paginate
            v-if="pageCount > 1"
            v-model="selectedPage"
            :click-handler="saveStateAndLoadCameras"
            :hide-prev-next="true"
            :page-count="pageCount"
            :page-range="5"
            active-class="smart-pagination__page_active"
            break-view-class="smart-pagination__page_collapse"
            container-class="smart-pagination"
            next-class="smart-pagination__page smart-pagination__next-prev"
            next-link-class="smart-pagination__page-link"
            next-text="<svg class='icon icon-arrow-left'><use xlink:href='#icon-arrow-left'></use></svg>"
            page-class="smart-pagination__page"
            page-link-class="smart-pagination__page-link"
            prev-class="smart-pagination__page smart-pagination__next-prev"
            prev-link-class="smart-pagination__page-link"
            prev-text="<svg class='icon icon-arrow-right'><use xlink:href='#icon-arrow-right'></use></svg>"
          />
        </div>
      </template>
    </div>

    <notifications classes="customized-notification-style" group="cams" position="bottom right" />
  </div>
</template>

<script>
import {FRAMES, TABS} from "@/store/meshCameras/index.js";
import {PAGE_SIZES, PARAMS_SORT_CAMERAS, TYPES_VIEWS} from "@/utils/consts.js";
import CamerasFinder from "@/components/meshCameras/CamerasFinder.vue";
import SelectSort from "@/components/meshCameras/SelectSort.vue";
import ViewTypeSwitcher from "@/components/meshCameras/ViewTypeSwitcher.vue";
import ViewTable from "@/components/meshCameras/ViewTable.vue";
import ViewTile from "@/components/meshCameras/ViewTile.vue";
import {meshFrameMixin} from "@/components/meshCameras/mixins.js";
import {ROUTE_CAMS_FAV, ROUTE_CAMS_MY, ROUTE_CAMS_MY_FOLDER, ROUTE_CAMS_SEARCH} from "@/router/names.js";
import draggable from "vuedraggable";
import {ACTION_CHANGE_ORDER_FAV, ACTION_LOAD_CAMERAS_FAV, FIELDS_CAMERA} from "@/store/cameras/index.js";
import {
  QUERY_KEY_MESH_PAGE_FOLDER,
  QUERY_KEY_MESH_PAGE_PAGE,
  QUERY_KEY_MESH_PAGE_SEARCH,
  QUERY_KEY_MESH_PAGE_SORT,
  QUERY_KEY_MESH_PAGE_TYPE_VIEW
} from "@/router/queryKeys.js";
import {ifChangedOnlyGlobalQueryKeys} from "@/utils/helpers.js";
import MeshCreateFolderFrameDialog from "@/components/meshCameras/MeshCreateFolderFrameDialog.vue";
import Folders from "@/components/meshCameras/Folders.vue";

// Ключи для query string используемые в рамках компонента.
const LOCAL_QUERY_KEYS = [
  QUERY_KEY_MESH_PAGE_TYPE_VIEW,
  QUERY_KEY_MESH_PAGE_SEARCH,
  QUERY_KEY_MESH_PAGE_SORT,
  QUERY_KEY_MESH_PAGE_PAGE,
  QUERY_KEY_MESH_PAGE_FOLDER,
];

/**
 * Компонент для представления списка камер на странице для выбора трансляции с конкретной камеры или получения доступа к ее архиву.
 *
 * В компоненте перекрыты методы миксина для сохранения состояния в URL,
 * однако общий профит во всем этом только ради случая обновления страницы - чтобы вернуться к тому состоянию в котором страница была,
 * (но это пока состояние не сохраняется персистентно) или передать текущее состояние другому пользователю по URL.
 */
export default {
  name: "MeshFramePage",
  mixins: [meshFrameMixin],
  components: {
    Folders,
    CamerasFinder,
    SelectSort,
    ViewTypeSwitcher,
    draggable,
  },
  data() {
    return {
      currentFrame: FRAMES.PAGE,
      showFolders: false,
      folderName: "",
      folderIdsRoute: null,
      routes: {
        ROUTE_CAMS_MY,
        ROUTE_CAMS_FAV,
        ROUTE_CAMS_SEARCH,
        ROUTE_CAMS_MY_FOLDER
      },
      folderCameraNumbers: [],
    };
  },
  computed: {
    gridClasses() {
      if (this.listCamerasInfo.length) {
        return this.isTableView ? 'mesh-cameras-grid-main_table' : 'mesh-cameras-grid-main_tiles';
      } else {
        return this.showFolders ? 'mesh-cameras-grid-main' : 'mesh-cameras-grid';
      }
    },
    /**
     * @return {Component} Компонент для выбранного типа отображения.
     */
    componentView() {
      return _.get(
        {
          [TYPES_VIEWS.TABLE]: ViewTable,
          [TYPES_VIEWS.TILE]: ViewTile,
        },
        this.selectedView,
        ViewTile
      );
    },
    /**
     * @return {Boolean} true если выбрано отображение в виде таблицы.
     */
    isTableView() {
      return this.selectedView === TYPES_VIEWS.TABLE;
    },
    /**
     * @return {Boolean} Вернет true при наличии возможности применять и редактировать пользовательскую сортировку.
     */
    availableGrabbable() {
      return PARAMS_SORT_CAMERAS.user === this.selectedSort;
    },
    /**
     * @return {Object} Параметры для query части маршрута страницы поиска.
     */
    queryParamsForSearch() {
      return {[QUERY_KEY_MESH_PAGE_SEARCH]: this.queryTabSearch};
    }
  },
  watch: {
    /**
     * Перехват параметризованного URL - извлечение номера новой страницы и ее загрузка.
     * Хук beforeRouteUpdate работает только в компоненте, который непосредственно обслуживает маршрут,
     * но не его внутренние компоненты.
     *
     * Состояние восстанавливается при изменении маршрута и особое внимание к смене табов,
     * через которые регулируется работа общего спинера.
     *
     * @param {Object} to
     * @param {Object} from
     */
    "$route"(to, from) {
      if (ifChangedOnlyGlobalQueryKeys(from, to, LOCAL_QUERY_KEYS)) {
        return;
      }
      this.isLoadingCommon = this.selectedTab !== this.tabByRoute(to);
      this.recoverState();
      this.loadCameras().then(() => {
        this.isLoadingCommon = false;
      });
    },
  },
  created() {
    const showFolders = localStorage.getItem('showFolders');
    if (showFolders !== null) {
      this.showFolders = showFolders === 'true';
    }
  },
  beforeDestroy() {
    this.$root.$off('folder-load', this.loadFolders);
    this.$root.$off('load-cameras', this.loadCamerasDefault);
  },
  mounted() {
    if (!this.folderName) {
      this.$root.$on('folder-load', this.loadFolders);
    }
    this.$root.$on('load-cameras', this.loadCamerasDefault);
  },
  methods: {
    loadFolders(folder) {
      this.folderName = folder.name;
      const targetRoute = {name: ROUTE_CAMS_MY_FOLDER, params: {folderId: folder.id}};
      if (this.$route.path !== this.$router.resolve(targetRoute).href) {
        this.folderIds = [folder.id];
        this.folderIdsRoute = folder.id;
        this.$router.push(targetRoute);
        this.saveStateAndFolder();
      }
    },

    toggleFolders() {
      this.showFolders = !this.showFolders;
    },
    async openSelectFolderCamerasDialog() {
      this.$camsdals.open(
        MeshCreateFolderFrameDialog,
        {initialSelectedCameraNumbers: this.folderCameraNumbers},
        {dialogTitle: "Создание папки"},
        {
          size: "vuedal-auto-width vuedal-all-height",
          name: "js-click-outside"
        },
      );

    },

    /**
     * То же что и {@link saveState} но после сохранения запускает процесс обновления камер в компоненте.
     *
     * Обновление списка камер осуществляется после изменения и перехода по измененному URL,
     * в котором так же сохраняется актуальное состояние.
     */
    saveStateAndLoadCameras() {
      this.saveState();
      this.stringifyQueryParams();
    },
    loadCamerasDefault() {
      this.resetFolder()
      this.loadCameras();
    },
    resetFolder() {
      this.folderName = null;
      this.folderIds = null;
      this.$root.$emit('reset-active-state');
    },

    /**
     * Метод для перекрытия, в котором вызываются функции для восстановления состояния компонента из сохраненного места.
     *
     * После восстановления состояния в компоненте инициирует его сохранение.
     * Это нужно когда состояние восстанавливается из URL.
     */
    recoverState() {
      this.parseQueryParams(this.tabByRoute(this.$route), this.$route.query);
      this.saveState();
    },
    /**
     * Приведет заданные настройки в строку для подстановки ее в адресную строку.
     * Передача полученной строки в GET параметр и переход по готовому URL для применения настроек.
     */
    stringifyQueryParams() {
      const queryParams = {
        [QUERY_KEY_MESH_PAGE_TYPE_VIEW]: this.selectedView,
        [QUERY_KEY_MESH_PAGE_SEARCH]: this.queryTabSearch,
        [QUERY_KEY_MESH_PAGE_SORT]: this.selectedSort,
        [QUERY_KEY_MESH_PAGE_PAGE]: this.selectedPage,
        [QUERY_KEY_MESH_PAGE_FOLDER]: this.folderIds,
      };
      this.$router.push({name: this.$route.name, query: queryParams});
    },
    /**
     * Разбор параметров GET строки, парсинг и подстановка параметров в компонент.
     * Вызывать после осуществления навигации, для заполнения настроек переданными значениями или дефолтными.
     *
     * @param {String} newSelectedTab
     * @param {Object} params
     */
    parseQueryParams(newSelectedTab, params) {
      this.selectedTab = newSelectedTab;
      this.selectedView = params[QUERY_KEY_MESH_PAGE_TYPE_VIEW] || this.$store.state.meshCameras.frames[this.currentFrame].typeView;
      this.queryTabSearch = params[QUERY_KEY_MESH_PAGE_SEARCH] || this.$store.state.meshCameras.frames[this.currentFrame].queryTabSearch;
      this.selectedSort = params[QUERY_KEY_MESH_PAGE_SORT] || this.$store.state.meshCameras.frames[this.currentFrame].tabs[this.selectedTab].sort;
      this.selectedPage = params[QUERY_KEY_MESH_PAGE_PAGE] || this.$store.state.meshCameras.frames[this.currentFrame].tabs[this.selectedTab].page;
      this.folderIds = params[QUERY_KEY_MESH_PAGE_FOLDER] || this.$store.state.meshCameras.frames[this.currentFrame].tabs[this.selectedTab].page;
    },
    /**
     * Вернет идентификатор таба на основе данных из объекта Route.
     *
     * @param {Object} route
     * @return {String}
     */
    tabByRoute(route) {
      return _.get(
        {
          [ROUTE_CAMS_MY]: TABS.MY,
          [ROUTE_CAMS_FAV]: TABS.FAV,
          [ROUTE_CAMS_SEARCH]: TABS.SEARCH,
          [ROUTE_CAMS_MY_FOLDER]: TABS.FOLDERS,
        },
        route.name,
        TABS.MY
      );
    },
    /**
     * Обработка случая когда необходимо показать все результаты работы от компонента поиска {@link CamerasFinder}.
     * Сохраняется поисковый запрос из компонента в хранилище и тем самым регулируется содержимое таба с поиском.
     */
    goToPageAllSearchResults() {
      this.queryTabSearch = this.querySearchCameras;
      this.saveState();
      this.$router.push({name: ROUTE_CAMS_SEARCH, query: {[QUERY_KEY_MESH_PAGE_SEARCH]: this.queryTabSearch}});
    },

    /**
     * Метод вызывается по событию компонента {@link draggable} когда пользователь меняет местами порядок камер.
     * Это определяет порядок пользовательской сортировки. Она сохраняется на сервере.
     *
     * Изменение порядка возможно (сейчас) в рамках избранных камер.
     * Считается что количество избранных может иметь ограниченное количество (60),
     * при этом количество камер на странице может быть меньше. API изменения пользовательской сортировки не учитывает страницы
     * и поэтому нужно делать поправку на общий набор избранных камер.
     */
    changeUserSort() {
      const actualPageSize = this.$store.state.meshCameras.pageSize,
            sortedCamerasNumbers = this.listCamerasInfo.map(cameraInfo => cameraInfo.number);

      if (actualPageSize >= 60) {
        // Когда список камер полный и можно сохранять сортировку "как есть".
        return this.$store.dispatch(`cameras/${ACTION_CHANGE_ORDER_FAV}`, sortedCamerasNumbers);
      }

      // Когда нужно корректировать пользовательскую сортировку при не полном наборе избранных камер.
      return this.$store
        .dispatch(`cameras/${ACTION_LOAD_CAMERAS_FAV}`, {
          pageSize: PAGE_SIZES.DEFAULT,
          page: 1,
          orderBy: PARAMS_SORT_CAMERAS.user,
          fields: [FIELDS_CAMERA.number],
          query: this.queryTabSearch
        })
        .then(([listCamerasInfo]) => {
          let listAllFavCamerasNumbers = listCamerasInfo.map(cameraInfo => cameraInfo.number);
          listAllFavCamerasNumbers.splice((this.selectedPage - 1) * actualPageSize, actualPageSize, ...sortedCamerasNumbers);
          return this.$store.dispatch(`cameras/${ACTION_CHANGE_ORDER_FAV}`, listAllFavCamerasNumbers);
        });
    },
  },
};
</script>

<style lang="scss">
@import "./scss/mesh-cameras.scss";
</style>
