<template>
  <div>
    <label>Вид:</label>
    <button :class="{'active': newValue === typesViews.TILE}" title="Плитка" type="button" @click="switchViewType(typesViews.TILE)">
      <svg class="icon icon-tile-view">
        <use xlink:href="#icon-tile-view" />
      </svg>
    </button>

    <button :class="{'active': newValue === typesViews.TABLE}" title="Список" type="button" @click="switchViewType(typesViews.TABLE)">
      <svg class="icon icon-table-view">
        <use xlink:href="#icon-table-view" />
      </svg>
    </button>
  </div>
</template>

<script>
import {TYPES_VIEWS} from "@/utils/consts.js";

/**
 * Компонент для переключения варианта отображения списка камер между несоклькими вариантами из {@link TYPES_VIEWS}.
 * В компонент передается модель данных через v-model, с которой будет устанавливаться связь.
 */
export default {
  name: "ViewTypeSwitcher",
  props: {
    /**
     * Значение, которое надо передавать через v-model.
     */
    value: {
      type: String,
      default: TYPES_VIEWS.TILE
    },
  },
  data() {
    return {
      newValue: this.value,
      typesViews: TYPES_VIEWS
    };
  },
  watch: {
    /**
     * Наблюдение за оригинальным значением для его синхронизации со внутренним значением компонента и отправкой события change.
     *
     * @param {String} newValue
     */
    value(newValue) {
      this.newValue = newValue;
      this.$emit("change", newValue);
    },
  },
  methods: {
    /**
     * Метод срабатывает при клике на конкретный вариант отображения.
     * Новое значение сохраняется в компоненте и передается по событию в родительский компонент в привязанную модель.
     *
     * @param {String} newTypeView
     */
    switchViewType(newTypeView) {
      this.newValue = newTypeView;
      this.$emit("input", newTypeView);
    },
  },
};
</script>
