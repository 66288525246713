<template>
  <div class="analytic-report">
    <SpinnerLoading v-if="isLoading" class="loader_center" color="blue" />

    <template v-else-if="cameraInfo && cameraInfo.isAvailableArchive()">
      <div class="analytic-report__settings">
        <SmartInputDate
          v-model="archiveFrom"
          :config-flat-pickr="{minDate: minEventArchiveFrom, maxDate: maxEventArchiveTo}"
          caption="Дата начала"
          class="analytic-report__settings-control analytic-report__settings-control_small"
        />
        <SmartInputDate
          v-model="archiveTo"
          :config-flat-pickr="{minDate: minEventArchiveFrom, maxDate: maxEventArchiveTo}"
          caption="Дата конца"
          class="analytic-report__settings-control analytic-report__settings-control_small"
        />
        <SmartSwitch
          v-model="access"
          :one-row="false"
          caption="Доступ?"
          class="analytic-report__settings-control analytic-report__settings-control_up"
        />
        <SmartInputText
          v-model="searchText"
          caption="Поиск"
          class="analytic-report__settings-control"
        />
        <CamsButton class="analytic-report__settings-control" type="button" @click="resetFilters()">
          Сбросить
        </CamsButton>
        <CamsButton class="analytic-report__settings-control" priority="primary" type="button" @click="downloadTableAsCSV()">
          Скачать отчет
        </CamsButton>
      </div>

      <div class="analytic-report__table cams-table-wrapper">
        <SpinnerLoading v-if="isLoadingReport" class="loader_center" color="blue" />

        <table v-else-if="messages" class="cams-table">
          <tr>
            <th class="cams-table__cell cams-table__cell_fixed-width" />
            <th class="cams-table__cell">
              Имя
            </th>
            <th class="cams-table__cell">
              Доступ?
            </th>
            <th class="cams-table__cell">
              Дата
            </th>
          </tr>
          <template v-for="message in messages">
            <tr :key="message.id" :class="{'warning warning-bg': message.alarm}">
              <td class="cams-table__cell cams-table__cell_fixed-width">
                <div class="analytic-report__event-actions">
                  <CamsButton
                    icon-type="only"
                    title="Скриншот события"
                    type="button"
                    @click="showFullScreenshot(message, cameraInfo)"
                  >
                    <svg>
                      <use xlink:href="#icon-screen-preview" />
                    </svg>
                  </CamsButton>
                  <CamsButton
                    v-show="message.date >= minVideoArchiveFrom"
                    icon-type="only"
                    title="Видео события"
                    type="button"
                    @click="playEventStart(message, cameraInfo)"
                  >
                    <svg>
                      <use xlink:href="#icon-video-preview" />
                    </svg>
                  </CamsButton>
                  <CamsButton
                    v-show="message.date >= minVideoArchiveFrom"
                    icon-type="only"
                    title="Скачать видео события"
                    type="button"
                    @click="downloadEventVideo(message, cameraInfo)"
                  >
                    <svg>
                      <use xlink:href="../../../assets/img/icons.svg#cloud-archive" />
                    </svg>
                  </CamsButton>
                </div>
              </td>
              <td class="cams-table__cell">
                {{ message.text || "Неизвестный" }}
              </td>
              <td class="cams-table__cell">
                {{ message.access ? "Да" : "Нет" }}
              </td>
              <td class="cams-table__cell">
                {{ message.date | localDateTime }}
              </td>
            </tr>
          </template>
        </table>

        <template v-else>
          <p>События на данном промежутке времени отсутствуют.</p>
        </template>
      </div>
    </template>

    <template v-else>
      <p>Ошибка: невозможно получить доступ к архиву</p>
    </template>

    <!--iframe для скачивания-->
    <iframe :src="downloadUrl" frameborder="0" height="1" width="1" />
  </div>
</template>

<script>
import {analyticReportMixin} from "@/components/oneScreen/reports/mixins.js";
import {ACTION_LOAD_FACE_RECOGNITION_REPORT} from "@/store/analytics/faceRecognitions/index.js";
import {downloadCSV} from "@/utils/helpers.js";

/**
 * Отчет по лицам.
 */
export default {
  name: "FaceRecognitionReportScreen",
  mixins: [
    analyticReportMixin,
  ],
  data() {
    return {
      archiveFrom: null,
      archiveTo: null,
      access: null,
      searchText: null,
    };
  },
  computed: {
    /**
     * @return {Date} Корректное представление archiveFrom в сравнении с archiveTo.
     */
    clearArchiveFrom() {
      return this.archiveFrom && this.archiveTo ? new Date(Math.min(this.archiveFrom.getTime(), this.archiveTo.getTime())) : null;
    },
    /**
     * @return {Date} Корректное представление archiveTo в сравнении с archiveFrom.
     */
    clearArchiveTo() {
      return this.archiveFrom && this.archiveTo ? new Date(Math.max(this.archiveFrom.getTime(), this.archiveTo.getTime())) : null;
    },
  },
  watch: {
    // Отслеживание значений фильтров для автоматического обновления отчета.
    archiveFrom() {
      this.debouncedLoadReport();
    },
    archiveTo() {
      this.debouncedLoadReport();
    },
    access() {
      this.debouncedLoadReport();
    },
    searchText() {
      this.debouncedLoadReport();
    },
  },
  /**
   * Регистрируется отложенная загрузка отчета для случаев быстрого изменения фильтров.
   */
  created() {
    this.debouncedLoadReport = _.debounce(this.loadReport, 350);
  },
  methods: {
    /**
     * Сброс фильтров в начальное состояние.
     * Фильтры по умолчанию по датам за последний час.
     */
    resetFilters() {
      const archiveTo = new Date(),
            archiveFrom = new Date(archiveTo.getTime());
      archiveFrom.setHours(archiveTo.getHours() - 1);
      this.archiveFrom = archiveFrom;
      this.archiveTo = archiveTo;
      this.access = null;
      this.searchText = "";
    },
    /**
     * Загрузка отчета по заданным параметрам.
     */
    async loadReport() {
      if (!this.clearArchiveFrom || !this.clearArchiveTo) {
        return;
      }

      this.isLoadingReport = true;
      this.messages = [];
      this.messages = await this.$store.dispatch(`analytics/faceRecognitions/${ACTION_LOAD_FACE_RECOGNITION_REPORT}`, {
        cameraNumber: this.cameraInfo.number,
        archiveFrom: this.clearArchiveFrom,
        archiveTo: this.clearArchiveTo,
        access: this.access,
        query: this.searchText,
      });
      this.isLoadingReport = false;
    },
    /**
     * Выгрузка данных таблицы в виде CSV.
     */
    downloadTableAsCSV() {
      let csvText = "";

      const filename = `Отчет ${this.cameraInfo.title}.csv`,
            headers = [
              "Имя",
              "Доступ?",
              "Дата",
            ];
      csvText = `${headers.join(',')}\r\n`;
      this.messages.forEach((message) => {
        csvText += `${message.text || "Неизвестный"},${message.access ? "Да" : "Нет"},${this.$options.filters.localDateTime(message.date)}\r\n`;
      });
      downloadCSV(filename, csvText);
    },
  },
};
</script>

<style lang="scss">
@import "./analytic-report.scss";
</style>
